<template>
  <edit-survey-stage-expand-template
    :stage-title="stage.title"
    :stage-index="stageIndex"
    :stage-id="stage.id"
    :stage-count="stageCount"
    :draft="draft"
    :loading="isLoading"
    @save="saveStage"
    @delete="openDeleteStageDialog"
    @copy="copyStageCreator"
    @moveUp="$emit('moveUp', $event)"
    @moveDown="$emit('moveDown', $event)"
  >
    <template v-slot:form>
      <stage-form-template v-model="stage" :form_errors="errors" ref="stage_form"></stage-form-template>
    </template>
    <template v-slot:fields>
      <stage-fields ref="stage_fields" :draft="draft" :stage-id="stage.id"></stage-fields>
    </template>
    <template v-slot:dialogs>
      <AddField
        v-if="open_add"
        :opened="open_add"
        :field-data="new_field_data"
        :survey-id="surveyId"
        :stage-id="stage.id"
        @close="closeAddStageField"
      ></AddField>
      <apply-dialog
        v-if="open_delete"
        :opened="open_delete"
        :get-text="() => `Вы уверены, что хотите удалить данный этап ?`"
        @close="deleteStageDetail"
      ></apply-dialog>
    </template>
    <template v-slot:actions-buttons>
      <div>
        <v-btn
            :disabled="draft"
            v-if="stage.hasOwnProperty('id')"
            depressed
            @click="addStageField()"
        >
          Добавить поле
        </v-btn>
      </div>

    </template>
  </edit-survey-stage-expand-template>
</template>

<script>
import {mapActions} from "vuex";
import LoadingMixin from "@/mixins/LoadingMixin";

import ApplyDialog from "@/modules/core/components/ApplyDialog";


import EditSurveyStageExpandTemplate
  from "@/modules/templates/survey/edit/EditSurveyStageExpandTemplate";
import StageFormTemplate from "@/modules/templates/survey/StageFormTemplate";
import StageFields from "@/modules/creator/survey/components/edit/StageFields";
import AddField from "@/modules/creator/survey/components/dialogs/AddField";


export default {
  name: "SurveyExpand",
  mixins: [LoadingMixin],
  components: {ApplyDialog, EditSurveyStageExpandTemplate, StageFormTemplate, AddField, StageFields},
  props: {
    stageCount: {
      required: true,
    },
    value: {
      required: true,
    },
    draft: {
      required: true,
      default: false
    },
    stageIndex: {
      required: true,
    },
    surveyId: {
      required: true
    },
  },
  data(){
    return {
      stage: this.value,
      open_add: false,
      open_delete: false,
      new_field_data: {},
      errors: {},
      error: {
        show: false,
        text: '',
      },
    }
  },
  watch: {
    value(){
      this.stage = this.value
    },
    stage: {
      deep: true,
      handler() {
        this.$emit('input', this.stage)
      }
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      updateStage: 'creator/editStage',
      copyStage: 'creator/copyStage',
      deleteStage: 'creator/deleteStage',
    }),
    copyStageCreator(){
      this.copyStage({
        survey_id: this.surveyId,
        stage_id: this.stage.id,
        finalizer: () => {
          this.setNotLoading();
          this.$emit('update')
        },
        catcher: (error) => {
          this.setNotLoading();
          this.showError(error.survey[0])
        }
      })
    },
    showError(text){
      this.error.show = false;
      setTimeout(() => {
        this.error.show = true;
        this.error.text = text;
      }, 1)
    },
    saveFields(){
      if (this.$refs.stage_fields)
        this.$refs.stage_fields.saveFields();
    },
    validate(){
      if (this.$refs.stage_form)
        this.$refs.stage_form.validate();
    },
    loadFields(){
      this.$refs.stage_fields.loadFields()
    },
    saveStage(event=undefined, finalizer=()=>{}){
      this.errors = {}
      this.setLoading();
      this.updateStage({
        data: this.stage,
        survey_id: this.surveyId,
        finalizer: (data) => {
          this.setNotLoading();
          finalizer(data);
        },
        catcher: (error) => {
          this.setNotLoading();
          this.errors=(error)
        }
      })
      this.saveFields()
    },
    addStageField(){
      this.open_add = true
    },
    closeAddStageField(){
      this.loadFields()
      this.open_add = false
    },
    openDeleteStageDialog(){
      this.open_delete = true
    },
    closeDeleteStageDialog(){
      this.open_delete = false
    },
    deleteStageDetail(apply){
      if (apply){
        this.setLoading();
        this.deleteStage({
          survey_id: this.surveyId,
          stage_id: this.stage.id,
          finalizer: () => {
            this.setNotLoading();
            this.$emit('deleted')
            // this.loadStages();
            this.closeDeleteStageDialog();
          },
          catcher: (error) => {
            this.setNotLoading();
            this.closeDeleteStageDialog();
            this.showError(error.value_choices)
          }
        })
      } else{
        this.closeDeleteStageDialog();
      }
    },
  },
}
</script>

<style scoped>

</style>
