<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px; width: 100%"
      v-if="$route.name === names.FIELDS.LIST"
  >
    <template>
      <v-toolbar
          class="removeBtnPaddingRight rounded-t-lg"
          :extended="$vuetify.breakpoint.xsOnly ? true : false"
          flat
          elevation="0"
          color="style-color-main-gray"
      >
        <v-text-field
            single-line
            outlined
            dense
            clearable
            :messages="$vuetify.breakpoint.smAndUp ? 'Для поиска нажмите Enter или кнопку поиск' : 'Для поиска нажмите Enter'"
            :class="$vuetify.breakpoint.smAndUp ? 'grow' : ''"
            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
        </v-text-field>
        <v-select
          v-if="$vuetify.breakpoint.mdAndUp"
          class="pt-7 pl-2"
          outlined
          dense
          style="padding-bottom: 2px"
          single-line
          flat
          clearable
          :items="field_type_selector"
          v-model="pagination.filters.type"
          label="Фильтр по типу поля"
        ></v-select>
        <v-spacer/>
        <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            icon
            color="black"
            class="mr-0"
            @click="openFieldCreate"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <template v-slot:extension  v-if="$vuetify.breakpoint.smAndDown">
          <v-select
              dense
              :items="field_type_selector"
              v-model="pagination.filters.type"
              label="Фильтр по типу поля"
              outlined
              style="padding-bottom: 2px"
              single-line
              flat
              clearable
              hide-details
          ></v-select>
        </template>
        <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn
              class="dark-primary rounded-tr-lg"
              text
              @click="openFieldCreate"
          >
            Создать поле
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="fields"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :search="search.parameter"
          :items-per-page.sync="pagination.size"
          :page.sync="pagination.page"
          :server-items-length="pagination.count"
          :footer-props="pagination.footer_page"
          :loading="pagination.loading"
          class="elevation-1"
      >
        <template v-slot:item.name="{ item }">
          <a
              style="text-decoration: none"
              @click="openFieldDetail(item)"
          >{{item.name}}</a>
        </template>
        <template v-slot:item.type="{ item }">
          <span v-if="base_field_type_selector[item.type] !== undefined">{{base_field_type_selector[item.type].text}}</span>

        </template>
        <template v-slot:item.public="{ item }">
          <v-icon color="green" v-if="item.public === true">done</v-icon>
          <v-icon color="red" v-else>close</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click="openFieldEdit(item)"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>Редактировать поле</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click="openDeleteFieldDialog(item)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>Удалить поле</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
              color="primary"
              @click="initialize"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <FieldCreate
        v-if="field_create"
        :field_flag="field_create"
        :field_data="undefined"
        @close="closeField"
    ></FieldCreate>
    <FieldDetail
        v-if="field_detail"
        :field_flag="field_detail"
        :field_data="select_field"
        @close="closeFieldDetail"
    ></FieldDetail>
    <FieldCreate
        v-if="field_edit"
        :field_flag="field_edit"
        :field_data="field_data"
        @close="closeField"
    ></FieldCreate>
    <ApplyDialog
        v-if="field_delete"
        :opened="field_delete"
        :get-text="() => `Вы уверены, что хотите удалить данное поле ?`"
        @close="deleteField"
    ></ApplyDialog>
  </v-container>
  <router-view v-else></router-view>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import names from "@/modules/administrative/routers/names";
import choices from "../../../urls/choices";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import FieldCreate from "./components/FieldCreate";
import FieldDetail from "./components/FieldDetail";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {loadData} from "../../../helper/requests";
import selectors from "../../../urls/selectors";

export default {
  name: "BaseField",
  components: {ApplyDialog, FieldCreate, FieldDetail},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data(){
    return {
      page_title: "Список полей",
      search: {
        parameter: 'name',
      },
      pagination: {
        filters: {
          type: null,
        }
      },
      name: null,
      title: null,
      type: null,

      reveal: false,
      expanded: [],
      singleExpand: false,
      loading: true,
      headers: [
        {text: 'Название поля', align: 'start', sortable: false, value: 'name', width: '50%'},
        { text: 'Тип', value: 'type', sortable: false },
        { text: 'Публичный', value: 'public', sortable: false },
        { text: 'Действия', value: 'actions', sortable: false, width: '10%', align: 'end',},
      ],
      types: [],
      field_delete: false,
      field_create: false,
      field_edit: false,
      field_detail: false,
      select_field: undefined,
      field_data: {},
      fields: []
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      base_field_type_selector: 'selectors/getFieldTypeSelector'
    }),
    field_type_selector(){
      return [ ...this.base_field_type_selector]
    }
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadFields: "admin/loadFieldList",
      deleteFieldDetail: 'admin/deleteField',
      loadFieldDetail: 'admin/loadFieldDetail',
      loadFieldTypeSelector: 'selectors/loadFieldTypeSelector',
    }),
    loadData(url=undefined, first=false){
      this.loadFields({
        url: url,
        finalizer: (val, url) => {
          this.mapPagination(val);
          if(first)
            this.setCurrentLoadUrl(url);
          this.setNotLoading();
          this.fields = val.results
        }
      })
    },
    getItems (url=undefined) {
      this.loadData(url);
    },
    initialize() {
      this.loadFieldTypeSelector()
      this.setLoading();
      this.loadData(undefined, true);
    },
    openDeleteFieldDialog(item){
      this.select_field = item
      this.field_delete = true
    },
    deleteField(apply){
      if (apply) {
        this.deleteFieldDetail({
              data: this.select_field,
              finalizer: () => {
                this.reloadData()
              }
            }
        )
      }
      this.field_delete = false
      this.select_field = undefined

    },
    openFieldCreate(){
      this.field_create = true
    },
    closeField(){
      this.field_create = false
      this.field_edit = false
      this.select_field = undefined
      this.reloadData()
    },
    openFieldDetail(item){
      this.select_field = item
      this.field_detail = true
    },
    closeFieldDetail(){
      this.field_detail = false
      this.select_field = undefined
    },
    openFieldEdit(item){
      this.loadFieldDetail({
            id: item.id,
            finalizer: (val) => {
              this.field_data = val
              this.select_field = item
              this.field_edit = true
            }
          }
      )
    },
  },
  created() {
    this.initialize()
  }

}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
