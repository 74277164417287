import helper from "../helper";
// import user from "../store/modules/user";

let getSurveyUrl = helper.getSurveyUrlWithParameters

export default {
    getSurveyUrl,
    getByRecievedUrlData: (url, gets = null) => getSurveyUrl(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    CREATOR: {
        SURVEY: {
            LIST:
                (gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-list',
                    "list",
                    gets
                ),
            DETAIL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
            EDIT:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-detail',
                    "partial_update",
                    {id: id},
                    gets
                ),
            DUPLICATE:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-duplicate',
                    "duplicate",
                    {id: id},
                    gets
                ),
            DELETE:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-detail',
                    "destroy",
                    {id: id},
                    gets
                ),
            EXCEL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-get-exl',
                    "get_exl",
                    {id: id},
                    gets
                ),
        },
        KIND_PARAM: {
            GET_KIND_PARAM:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-get-kind-param',
                    "get_kind_param",
                    {id: id},
                    gets
                ),
            LIST:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|kind_param-list',
                    "list",
                    {survey_id: survey_id},
                    gets
                ),
            CREATE:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|kind_param-list',
                    "create",
                    {survey_id: survey_id},
                    gets
                ),
            DELETE:
                (survey_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|kind_param-detail',
                    "destroy",
                    {
                        survey_id: survey_id,
                        id: id,
                    },
                    gets
                ),
            DELETEALL:
                (survey_id,  gets = null) => getSurveyUrl(
                    'surveys|creator_survey|kind_param_all',
                    "delete",
                    {
                        survey_id: survey_id,
                    },
                    gets
                ),
        },
        INVITE: {
            LIST:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|invite-list',
                    "list",
                    {survey_id: survey_id},
                    gets
                ),
            CREATE:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|invite-list',
                    "create",
                    {survey_id: survey_id},
                    gets
                ),
            DETAIL:
                (survey_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|invite-detail',
                    "retrieve",
                    {
                        survey_id: survey_id,
                        id: id
                    },
                    gets
                ),
            DELETE:
                (survey_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|invite-detail',
                    "destroy",
                    {
                        survey_id: survey_id,
                        id: id
                    },
                    gets
                ),
            SEND:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-send-invite',
                    "send_invite",
                    {id: id},
                    gets
                ),
        },
        STAGE: {
            LIST:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage-list',
                    "list",
                    {survey_id: survey_id},
                    gets
                ),
            SAVE:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage-list',
                    "create",
                    {survey_id: survey_id},
                    gets
                ),
            EDIT:
                (survey_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage-detail',
                    "partial_update",
                    {
                        survey_id: survey_id,
                        id: id},
                    gets
                ),
            COPY:
                (survey_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage-copy',
                    "copy",
                    {
                        survey_id: survey_id,
                        id: id},
                    gets
                ),
            DELETE:
                (survey_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage-detail',
                    "destroy",
                    {
                        survey_id: survey_id,
                        id: id},
                    gets
                ),
        },
        STAGE_FIELDS: {
            LIST:
                (survey_id, stage_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage_field-list',
                    "list",
                    {
                        survey_id: survey_id,
                        stage_id: stage_id
                    },
                    gets
                ),
            CREATE:
                (survey_id, stage_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage_field-list',
                    "create",
                    {
                        survey_id: survey_id,
                        stage_id: stage_id
                    },
                    gets
                ),
            EDIT:
                (survey_id, stage_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage_field-detail',
                    "partial_update",
                    {
                        survey_id: survey_id,
                        stage_id: stage_id,
                        id: id
                    },
                    gets
                ),
            DELETE:
                (survey_id, stage_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|stage_field-detail',
                    "destroy",
                    {
                        survey_id: survey_id,
                        stage_id: stage_id,
                        id: id
                    },
                    gets
                ),
        },
        FILE: {
            UNLODING:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|survey-need-unloading',
                    "need_unloading",
                    {id: id},
                    gets
                ),
            LIST:
                (survey_id, gets = null) => getSurveyUrl(
                    'core|unload|request-list',
                    "list",
                    gets
                ),
            COMPLETED:
                (id, gets = null) => getSurveyUrl(
                    'core|unload|request-upload-complete',
                    "upload_complete",
                    {id: id},
                    gets
                ),
            DOWNLOAD:
                (id, gets = null) => getSurveyUrl(
                    'unload|download',
                    "download",
                    {id: id},
                    gets
                ),
        },
        FIELDS: {
            LIST:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|field-list',
                    "list",
                    gets
                ),
            SAVE:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|field-list',
                    "create",
                    gets
                ),
            EDIT:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|field-detail',
                    "partial_update",
                    {id: id},
                    gets
                ),
            DETAIL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|field-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
            DELETE:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|field-detail',
                    "destroy",
                    {id: id},
                    gets
                ),
        },
        CHOICES: {
            LIST:
                (gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choices-list',
                    "list",
                    gets
                ),
            SAVE:
                (gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choices-list',
                    "create",
                    gets
                ),
            EDIT:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choices-detail',
                    "partial_update",
                    {id: id},
                    gets
                ),
            DETAIL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choices-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
            DELETE:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choices-detail',
                    "destroy",
                    {id: id},
                    gets
                ),
        },
        CHOICE_VALUE: {
            LIST:
                (value_choices_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choice-list',
                    "list",
                    {value_choices_id: value_choices_id},
                    gets
                ),
            LIST_ALL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choices-get-value-choices-list',
                    "get",
                    gets
                ),
            ONE_CREATE:
                (value_choices_id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choice-list',
                    "create",
                    {value_choices_id: value_choices_id},
                    gets
                ),
            MANY_CREATE:
                (id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choices-many-value-choice-save',
                    "many_value_choice_save",
                    {id: id},
                    gets
                ),
            EDIT:
                (value_choices_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choice-detail',
                    "partial_update",
                    {
                        value_choices_id: value_choices_id,
                        id: id
                    },
                    gets
                ),
            DETAIL:
                (value_choices_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choice-detail',
                    "retrieve",
                    {
                        value_choices_id: value_choices_id,
                        id: id
                    },
                    gets
                ),
            DELETE:
                (value_choices_id, id, gets = null) => getSurveyUrl(
                    'surveys|creator_survey|value_choice-detail',
                    "destroy",
                    {
                        value_choices_id: value_choices_id,
                        id: id
                    },
                    gets
                ),
        },
        ATTEMPTS: {
            LIST:
                (gets = null) => getSurveyUrl(
                    'attempts|creator_attempt|attempt-list',
                    "list",
                    gets
                ),
            DETAIL:
                (id, gets = null) => getSurveyUrl(
                    'attempts|creator_attempt|attempt-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
            STAGES:
                (attempt_id, gets = null) => getSurveyUrl(
                    'attempts|creator_attempt|stage-list',
                    "list",
                    {attempt_id: attempt_id},
                    gets
                ),
            FIELDS:
                (attempt_id, stage_id, gets = null) => getSurveyUrl(
                    'attempts|creator_attempt|stage_field-list',
                    "list",
                    {
                        attempt_id: attempt_id,
                        stage_id: stage_id
                    },
                    gets
                ),
            DELETE:
                (id, gets = null) => getSurveyUrl(
                    'attempts|creator_attempt|attempt-detail',
                    "destroy",
                    {id: id},
                    gets
                ),
        },
    },
}
