<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <List v-if="$route.name === names.CHOICES.LIST"></List>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import names from "../routers/names";
import List from "./List";


export default {
  name: "ChoiceBreadcrumbs",
  components: {List},

  data() {
    return {

    }
  },
  computed: {
    names: () => names,
  },
}
</script>

<style scoped>

</style>