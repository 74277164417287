<template>
  <v-dialog persistent v-model="opened" width="700">
    <v-card>
      <v-card-title>
        <span class="text-h5" style="word-break: break-word !important;"  >Провести рассылку на опрос</span>
        <v-spacer></v-spacer>
        <v-btn
            icon
            fab
            small
            @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-h6" style="text-align: left">
        <v-textarea
            v-model="data.text"
            label="Текст приглашения на опрос"
        ></v-textarea>
        <v-alert type="info" outlined v-if="!invitation">
          Нажмите Enter, чтобы добавить новую почту
        </v-alert>
        <v-combobox
            v-if="!invitation"
            v-model="data.email_list"
            hide-selected
            label="Почты"
            multiple
            persistent-hint
            small-chips
        >
        </v-combobox>
        <span v-if="invitation">
          Рассылка будет произведена на почты, указанные в списке индивидуальных ссылок
        </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="applyDialog"
        >
          Отправить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NotifyAboutSurvey",
  props: {
    opened: Boolean,
    invitation: Boolean
  },
  data(){
    return {
      data: {
        text: undefined,
        email_list: [],
      },
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неверный формат почты.'
        },
      },
    }
  },
  methods: {
    closeDialog: function () {
      this.$emit('close', false)
    },
    applyDialog: function () {
      this.$emit('save', this.data)
    }

  }
}
</script>

<style scoped>

</style>
