<template>
    <v-row no-gutters>
      <v-text-field
          ref="text_value"
          v-model="model"
          :label="label"
          :error="error"
          clearable
          v-if="!readonly"
          :outlined="outlined"
          :error-messages="errorMessages"
          :hint="hint"
          @focus="$emit('focus', $event)"
          @input="$emit('input', $event)"
          :loading="loading"
          :rules="getRules()"
          style="width: 100%"
      >
<!--        <template v-slot:label>-->
<!--          <p style="font-size: 18px; margin-bottom: 3px">-->
<!--            {{label}}<span v-if="required" style="color: red">*</span>-->
<!--          </p>-->
<!--        </template>-->
        <template v-slot:append-outer v-if="downloadable">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click="$emit('download')"
              >
                <v-icon>file_download</v-icon>
              </v-btn>
            </template>
            <span>Загрузить документ</span>
          </v-tooltip>
        </template>
        <template v-slot:append  v-if="model">
          <v-tooltip bottom  v-if="model.file">
            <template v-slot:activator="{ on }">
              <v-icon
                  v-on="on"
                  medium
                  @click="getLink(model.file)"
              >
                cloud_download
              </v-icon>
            </template>
            <span>Скачать файл</span>
          </v-tooltip>
        </template>
      </v-text-field>
      <v-card v-else style="width: 100%" flat>
        <v-card-title class="text-left">
          <a :href="model" target="_blank"
             @focus="$emit('focus', $event)"
             @input="$emit('input', $event)">
            {{model}}
          </a>
        </v-card-title>
      </v-card>
    </v-row>
</template>

<script>

import selectors from "@/urls/selectors";

export default {
  name: "LinkField",

  props: {
    value: {
      required: true
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    label: {
      required: true
    },
    description: {
      required: false
    },
    hint: {
      required: false
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    outlined: {
      required: false,
      default: false,
      type: Boolean
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      model: this.value,
    }
  },
  watch: {
    model(){
      if (JSON.stringify(this.value) !== JSON.stringify(this.model))
        this.$emit('input', this.model)
    },
    value(){
      if (JSON.stringify(this.value) !== JSON.stringify(this.model))
        this.model = this.value
    },
  },
  methods: {
    getLink(field){
        let get = {}
        if (field.get_params.key) get['key'] = field.get_params.key
        let url = selectors.SELECTORS.FILE_ANSWER.DOWNLOAD(field.params.id, get)
        if (url) window.open(url,'_blank');
    },
    validate(show_messages=true) {
      return true
    },
    getRules(){
      let rules = [
        ...this.rules,
      ]
      if (this.required) {
        rules.push(v => (!!v) || 'Поле обязательно к заполнению')
      }
      return rules
    },
  }
}
</script>

<style scoped>

</style>
