<template>
  <v-container>
    <v-card style="min-height: 10vw">
        <v-col v-if="!completed">
          <div class="action d-flex justify-center align-center" >
            <semipolar-spinner
                :animation-duration="2000"
                :size="115"
                color="#1935ff"
            />
          </div>
          <v-row class="body-2 justify-center" >
            Ожидайте, идет сбор данных
          </v-row>
        </v-col>
      <div v-else>
        <v-card-text>
          <v-alert type="info" outlined>
            Выгрузка сформирована
          </v-alert>
          <v-btn block class="primary" @click="sentSaveEmit">
            <v-icon>system_update_alt</v-icon>
            Скачать файл
          </v-btn>
        </v-card-text>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import {SemipolarSpinner} from 'epic-spinners'

export default {
  name: "LoadFileDetail",

  props:{
    detail: Object,
    completed: Boolean,
  },
  components: {
    SemipolarSpinner
  },
  methods: {
    sentSaveEmit () {
      this.$emit('saveFile',true)
    }
  }
}
</script>

<style scoped>

</style>
