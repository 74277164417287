<template>
  <div>
    <template>
      <v-toolbar
          flat
          elevation="0"
          class="rounded-t-lg"
          color="style-color-main-gray"
      >
        <v-text-field
            single-line
            outlined
            dense
            clearable
            :messages="$vuetify.breakpoint.smAndUp ? 'Для поиска нажмите Enter или кнопку поиск' : 'Для поиска нажмите Enter'"
            :class="$vuetify.breakpoint.smAndUp ? 'grow' : ''"
            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
        </v-text-field>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="Нет опросов для прохождения"
          no-results-text="Опросы не были найдены"
          :items-per-page.sync="pagination.size"
          :page.sync="pagination.page"
          @click:row="(item, slot) => openBeforeDialog(item)"
          :footer-props="{'items-per-page-options':[10,25,50]}"
          class="elevation-1 row-pointer"
      >
        <template v-slot:item.publication_date="{ item }">
          {{disp(item.publication_date)}}
        </template>
        <template v-slot:item.end_date="{ item }">
          {{disp(item.end_date)}}
        </template>
        <template v-slot:item.have_attempt="{ item }">
          <v-icon color="green" v-if="item.have_attempt === true">done</v-icon>
          <v-icon color="grey" v-else>close</v-icon>
        </template>
        <template v-slot:item.attempt_is_ended="{ item }">
          <v-icon color="green" v-if="item.attempt_is_ended === true">done</v-icon>
          <v-icon color="grey" v-else>close</v-icon>
        </template>
      </v-data-table>
    </template>
    <BeforeSurvey
        v-if="before_dialog"
        :dialog_flag="before_dialog"
        :dialog_data="before_data"
        @close="closeBeforeDialog"
    >
    </BeforeSurvey>
  </div>
</template>

<script>
import names from "../routers/names";
import {mapActions} from "vuex";
import TitledPageMixin from "../../../mixins/TitledPageMixin";
import BeforeSurvey from "./BeforeSurvey";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {displayDate}  from "@/helper";
import {loadData} from "@/helper/requests";

export default {
  name: "SurveyList",
  mixins: [TitledPageMixin,PaginatedDataMapperMixin],
  components: {BeforeSurvey},

  data(){
    return {
      page_title: "Список опросов",
      search: {
        parameter: 'name',
      },
      reveal: false,
      expanded: [],
      singleExpand: true,
      survey_delete: false,
      select_survey: undefined,
      before_dialog: false,
      before_data: undefined,
      headers: [
        {
          text: 'Название опроса',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '25%'
        },
        { text: 'Дата начала', value: 'publication_date', sortable: false},
        { text: 'Дата окончания', value: 'end_date', sortable: false},
        { text: 'Опрос начат', value: 'have_attempt', sortable: false},
        { text: 'Опрос завершён', value: 'attempt_is_ended', sortable: false},
      ],
      items: []
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    ...mapActions({
      LoadSurveys: 'surveying/LoadSurveys'
    }),
    disp(date){
      return displayDate(date)
    },
    openBeforeDialog(item) {
      this.before_data = item
      this.before_dialog = true
    },
    closeBeforeDialog(event) {
      this.before_dialog = false
      this.before_data = undefined
      if (event) {
        this.$router.push({
          name: names.SURVEYS.SURVEYING,
          params: {
            idSurvey: event.survey,
            idAttempt: event.attempt,
            attempt: event.data
          },
            query:{
              attempt_hash:event.attempt_hash
            }
        })
      }
    },
    initialize() {
      this.loadData(undefined, true);
    },
    getItems (url=undefined) {
      loadData(url);
    },
    loadData (url=undefined, first=false) {
      this.setLoading();
      this.LoadSurveys({
        url: url,
        finalizer: (val, url) => {
          this.setNotLoading();
          this.mapPagination(val)
          if(first)
            this.setCurrentLoadUrl(url);
          this.mapResults(val, 'items')
        }
       }
      )
    },
  },
  created() {
    this.initialize()
  },
}
</script>

<style scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }
</style>
