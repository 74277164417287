<template>
  <v-dialog
      v-model="opened"
      width="700"
      persistent
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Список индивидуальных ссылок</span>
        <v-spacer></v-spacer>
        <v-btn
            icon
            fab
            small
            @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
            dense
            :headers="headers"
            :items="data"
            item-key="item"
            class="elevation-1"
            hide-default-footer
        >
          <template v-slot:item.email="{ item }">
            {{item.user.email}}
          </template>
          <template v-slot:item.fio="{ item }">
            {{item.user.fio}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    icon
                    @click="deleteLink(item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>Удалить приглашение</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "PersonalLinkList",
  props: {
    opened: Boolean,
    data: Array
  },
  data(){
    return {
      headers: [
        {
          text: 'Почта',
          align: 'start',
          sortable: false,
          value: 'email',
        },
        {
          text: 'ФИО',
          align: 'start',
          sortable: false,
          value: 'fio',
        },
        {
          text: 'Действия',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '10%'}
        ,
      ],

    }
  },
  methods: {
    ...mapActions({
      personalLinkDetail: 'admin/personalLinkDetail',
      personalLinkDelete: 'admin/personalLinkDelete'
    }),
    closeDialog() {
      this.$emit('close', false)
    },
    deleteLink(data) {
      this.personalLinkDelete({
        survey_id: data.survey,
        id: data.id,
        finalizer: (val) => {
          this.$emit('close', false)
        },
      })
    }
  },
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>