import {
  sendPostRequest, sendPutRequest, sendDeleteRequest, sendGetRequest, makeGetRequest
} from "@/helper/requests";
import surveys from "@/urls/roles/admin";
import core from "@/urls/roles/creator";

const state = {

}

const getters = {
}

const mutations = {
}

const actions = {
  loadFileList: ({commit}, {url, finalizer, catcher}) => {
    if (!url)
      url = core.CREATOR.FILE.LIST()
    sendGetRequest(
      url,
      (data) => finalizer(data, url),
      catcher
    )
  },
  compiledLoadFile: ({commit}, { id, finalizer=()=>{}, catcher=()=>{}}) => {
    const url = core.CREATOR.FILE.COMPLETED(id)
    sendGetRequest(
        url, (data) => {
          finalizer(data);
        }, (data) => {
          catcher(data);
        }
    )
  },
  saveLoadFile: ({commit}, { id,url }) => {
    // const url = core.CREATOR.FILE.DOWNLOAD(id)
    makeGetRequest(
        url
    ) .then(resp => {
      if (!resp.ok) {
        alert("Не удалось загрузить файл")
      }
      return resp.blob();
    }) .then(blob => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = 'Файл';
      a.click();
    })
  },
}


export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
