<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px;
      width: 100%"
      v-if="$route.name === names.SURVEYS.LIST"
  >
    <template>
      <v-toolbar
          flat
          elevation="0"
          color="style-color-main-gray"
          class="rounded-t-lg"
      >
        <v-text-field
            single-line
            outlined
            dense
            clearable
            :messages="$vuetify.breakpoint.smAndUp ? 'Для поиска нажмите Enter или кнопку поиск' : 'Для поиска нажмите Enter'"
            :class="$vuetify.breakpoint.smAndUp ? 'grow' : ''"
            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
        </v-text-field>
        <v-select
          v-if="$vuetify.breakpoint.mdAndUp"
          class="pt-7 pl-2"
          outlined
          dense
          style="padding-bottom: 2px"
          single-line
          flat
          clearable
          :items="anonymity_selector"
          v-model="pagination.filters.anonymity"
          label="Фильтр по анонимности"
        ></v-select>
        <v-select
          v-if="$vuetify.breakpoint.mdAndUp"
          class="pt-7 pl-2"
          outlined
          dense
          style="padding-bottom: 2px"
          single-line
          flat
          clearable
          :items="kind_selector"
          v-model="pagination.filters.kind"
          label="Фильтр по доступу"
        ></v-select>
      </v-toolbar>
      <div  v-if="$vuetify.breakpoint.smAndDown" class="style-color-main-gray">
        <v-row no-gutters  class="pb-1">
          <v-col cols="12"  >
            <v-select
              outlined
              style="padding-bottom: 2px"
              single-line
              flat
              class="px-4"
              clearable
              hide-details
              dense
              :items="anonymity_selector"
              v-model="pagination.filters.anonymity"
              label="Фильтр по анонимности"
            ></v-select>
          </v-col>

          <v-col cols="12"  class="pt-1">
            <v-select
              outlined
              class="px-4"
              single-line
              flat
              clearable
              hide-details
              dense
              :items="kind_selector"
              v-model="pagination.filters.kind"
              label="Фильтр по доступу"
            ></v-select>
          </v-col>
        </v-row>
      </div>
      <v-data-table
          :headers="headers"
          :items="items"
          no-data-text="Опросы не загружены"
          no-results-text="Опросы не были найдены"
          :items-per-page.sync="pagination.size"
          :page.sync="pagination.page"
          :footer-props="{'items-per-page-options':[10,25,50]}"
          class="elevation-1 row-pointer"
      >
        <template v-slot:item.name="{ item }">
          <router-link
              style="text-decoration: none"
              :to="{
                name: names.SURVEYS.DETAIL,
                params: {idSurvey: item.id}
              }">
            {{item.name}}
          </router-link>
        </template>
        <template v-slot:item.publication_date="{ item }">
          {{disp(item.publication_date)}}
        </template>
        <template v-slot:item.end_date="{ item }">
          {{disp(item.end_date)}}
        </template>
        <template v-slot:item.anonymity="{ item }">
          {{getSelectorText(base_anonymity_selector, item.anonymity)}}
        </template>
        <template v-slot:item.kind="{ item }">
          {{getSelectorText(kind_selector, item.kind)}}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                  v-on="on"
                  @click="openAttempSurveyDialog(item)"
                  medium
              >
                sticky_note_2
              </v-icon>
            </template>
            <span>Загрузить ответы</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
    <AttempLoadXls
        v-if="survey_load_attemp"
        :opened="survey_load_attemp"
        :survey="select_survey"
        @close="attemptSurvey"
    ></AttempLoadXls>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
import names from "../routers/names";
import TitledPageMixin from "../../../mixins/TitledPageMixin";
import AttempLoadXls from "@/modules/templates/AttempLoadXls.vue";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import {displayDate}  from "@/helper";
import {loadData, makeGetRequest} from "@/helper/requests";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SurveyList",
  mixins: [TitledPageMixin,PaginatedDataMapperMixin],
  components: {AttempLoadXls},

  data(){
    return {
      page_title: "Список опросов",
      survey_load_attemp: false,
      search: {
        parameter: 'name',
      },
      pagination: {
        filters: {
          draft: null,
          kind: null,
          anonymity: null,
        }
      },
      selectors: {
        draft: [
          {text:'Опубликовано',value: 'true'},
          {text:'Не опубликовано',value: 'false'},
        ],
      },
      headers: [
        {
          text: 'Название опроса',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '45%'
        },
        { text: 'Дата начала', value: 'publication_date', sortable: false },
        { text: 'Дата окончания', value: 'end_date', sortable: false},
        { text: 'Анонимность', value: 'anonymity', width: '16%', sortable: false},
        { text: 'Доступ', value: 'kind', width: '8%', sortable: false},
        { text: 'Действия', value: 'actions' , align: 'end', sortable: false},

      ],
      items: []
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      base_anonymity_selector: 'selectors/getAnonymitySelector',
      base_kind_selector: 'selectors/getKindSelector'
    }),
    anonymity_selector(){
      return [ ...this.base_anonymity_selector]
    },
    kind_selector(){
      return [ ...this.base_kind_selector]
    }
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadSurveyList: 'watcher/loadSurveyList',
      loadKindSelector: 'selectors/loadKindSelector',
      loadAnonymitySelector: 'selectors/loadAnonymitySelector',
      loadStatisticFile: 'watcher/loadStatisticFile'
    }),
    getSelectorText(selector, value){
      let finded = selector.find(el => el.value === value)
      return finded ? finded.text : value
    },
    loadingFile(item,get){
      this.loadStatisticFile({
        id: item.id,
        get:{},
        finalizer: (data) => {
          this.$router.push({name: names.FILE.DETAIL, params: {idFile: data.unloading}})
        },
        catcher: () => {
          alert("Ошибка");
        },
      })
    },
    disp(date){
      return displayDate(date)
    },
    loadData(url=undefined, first=false){
      this.setLoading();
      this.loadSurveyList({
        url: url,
        finalizer: (val, url) => {
          this.setNotLoading();
          this.mapPagination(val)
          if(first)
            this.setCurrentLoadUrl(url);
          this.mapResults(val, 'items')
        }
      })
    },
    getItems (url=undefined) {
      loadData(url);
    },
    openAttempSurveyDialog(item){
      this.select_survey = item
      this.survey_load_attemp = true
    },
    attemptSurvey(apply){
      if (apply) {
        this.loadingFile(this.select_survey,apply)
        this.survey_load_attemp = false
        this.select_survey = undefined
      } else {
        this.survey_load_attemp = false
        this.select_survey = undefined
      }
    },
  },
  created() {
    this.loadKindSelector();
    this.loadAnonymitySelector();
    this.loadData(undefined, true)
  }
}
</script>

<style scoped>

</style>
