<template>
  <List v-if="$route.name === names.SURVEYS.STAGE.LIST"></List>
  <router-view v-else></router-view>
</template>

<script>
import names from "../routers/names";
import List from "./List"
import TitledPageMixin from "../../../mixins/TitledPageMixin";

export default {
  name: "StageBreadcrumbs",
  components: {List},
  mixins: [TitledPageMixin],
  data() {
    return {
      page_title: "Этап",
    }
  },
  computed: {
    names: () => names
  },
}
</script>

<style scoped>

</style>