<template>
    <v-container v-if="isBaseRoute"
                 class="page-content px-sm-5"
                 style="margin: 0px;
      width: 100%">
        <WelcomePage
                v-if="!check"
                @goPortal="goPortal"
                @goGuest="goGuest"
        ></WelcomePage>
        <SurveysList v-if="check"></SurveysList>
    </v-container>
    <router-view v-else></router-view>
</template>

<script>
import SurveysList from "@/modules/survey/surveys/List";
import names from "../routers/names";
import WelcomePage from "@/modules/templates/WelcomePage";
import {mapGetters} from "vuex";

export default {
    name: "SurveysBase",
    components: {
        WelcomePage,
        SurveysList
    },
    computed: {
        isBaseRoute() {
            return this.$route.name === names.SURVEYS.LIST
        },
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated'
        })
    },
    data() {
        return {
            check: false,
        }
    },
    created() {
        if (this.isAuthenticated) {
            this.check = true
            this.$store.commit('mainpage/showBreadcrubs', true);
        }else {
            this.$store.commit('mainpage/showBreadcrubs', false);
        }
    },
    methods: {
        goGuest() {
            this.check = true
            this.$store.commit('mainpage/showBreadcrubs', true);
        },
        goPortal() {
            if (this.isAuthenticated) {
                this.check = true
            } else {
                document.location.href = process.env.VUE_APP_LOGIN_HOST + String(`?from=${document.location.href}`)
            }
        },
    }
}
</script>

<style scoped>

</style>
