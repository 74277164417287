<template>
  <edit-survey-add-field-template
    :stage-id="stageId"
    :survey-id="surveyId"
    :fields="fields"
    :opened="opened"
    :error-show.sync="error.show"
    :error-text="error.text"
    @apply="applyDialog"
    @close="closeDialog"
  >
    <template v-slot:form>
      <edit-survey-stage-field-form-template
        ref="form"
        v-model="data"
        :date_dynamic_lower="date_dynamic_lower"
        :date_dynamic_upper="date_dynamic_upper"
        :date_dynamic_value_type="date_dynamic_value_type"
        :date_static_lower="date_static_lower"
        :date_static_upper="date_static_upper"
        :field_type="field_type"
        :coherent_type="coherent_type"
        :coherent_name="coherent_name"
        :loading="loading"
        :readonly="loading"
        :fields="fields"
        :choices-list="choices_list"
        :choices-value-list="choices_values"
        :errors-object="errors"
      ></edit-survey-stage-field-form-template>
    </template>
  </edit-survey-add-field-template>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import EditSurveyAddFieldTemplate
  from "@/modules/templates/survey/edit/EditSurveyAddFieldTemplate";
import EditSurveyStageFieldFormTemplate from "@/modules/templates/survey/edit/EditSurveyStageFieldFormTemplate";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {finalizeRequest, makePostRequestFile} from "@/helper/requests";
import creator from "../../../../../urls/roles/creator";

export default {
  name: "AddField",
  mixins: [FormErrorsMixin],
  components: {EditSurveyAddFieldTemplate, EditSurveyStageFieldFormTemplate},
  props: {
    opened: Boolean,
    surveyId: String,
    stageId: Number,
  },
  data() {
    return {
      loading: false,
      data: {
        field: '',
        title: '',
        description: '',
        default_value: '',
        read_only: false,
        hidden: false,
        required: false,
        choices: undefined,
        static_choices: false,
      },
      choices_values: [],
      field_object: undefined,
      field_type: '0',
      error: {
        text: '',
        show: false,
      },
      rules: {
        required: v => (!!v) || 'Поле обязательно для заполнения'
      }
      // fields: []
    }
  },
  computed: {
    ...mapGetters({
      fields: 'selectors/getCreatorFieldsSelector',
      choices_list: 'selectors/getCreatorChoicesSelector',
      coherent_list: 'selectors/getCoherentSelector'
    }),
    coherent_name(){
      if (!this.field_object)
        return undefined
      return this.coherent_list.find(el => el.value === this.field_object.coherent_type)
    },
    date_dynamic_lower() {
      return this.field_object ? this.field_object.date_dynamic_lower : undefined
    },
    date_dynamic_upper() {
      return this.field_object ? this.field_object.date_dynamic_upper : undefined
    },
    date_dynamic_value_type() {
      return this.field_object ? this.field_object.date_dynamic_value_type : undefined
    },
    date_static_lower() {
      return this.field_object ? this.field_object.date_static_lower : undefined
    },
    date_static_upper() {
      return this.field_object ? this.field_object.date_static_upper : undefined
    },
    coherent_type() {
      return this.field_object ? this.field_object.coherent_type : undefined
    }
  },
  watch: {
    'data.field' () {
      this.field_type =  this.fields.find(el => el.id === this.data.field).type
      this.loading = true;
      this.loadFieldDetail({
        id: this.data.field,
        finalizer: (val) => {
          this.field_object = val
          this.data.title = val.title
          this.data.description = val.description
          this.data.default_value = val.default_value
          this.data.choices = val.choices
          this.data.static_choices = val.static_choices
          this.loading = false;
        }
      })
    },
    'data.choices'(nV, oV){
      if (this.data.choices && !this.data.static_choices){
        this.loadChoicesSelector()
      }
      if (nV !== oV && nV){
        this.loadChoicesValueSelector(!!oV)
      }
    },
    field_object: {
      deep: true,
      handler(nV, oV) {
        if (this.field_object.coherent_type)
          this.loadCoherentSelector()
      }
    }
  },
  methods: {
    ...mapActions({
      saveAddStageField: 'creator/addStageField',
      loadFieldsSelector: 'selectors/loadFieldsCreatorSelector',
      loadCoherentSelector: 'selectors/loadCoherentSelector',
      loadChoicesSelector: 'selectors/loadChoicesCreatorSelector',
      loadChoiceValue: 'creator/loadChoiceValueList',
      loadFieldDetail: "creator/loadFieldDetail",

    }),
    loadChoicesValueSelector(update_default=true) {
      this.loadChoiceValue(
        {
          value_choices_id: this.data.choices,
          finalizer: (data) => {
            this.choices_values = data;
            if (update_default)
              this.data.default_value = undefined;
          }
        }
      );
    },
    closeAddStageField(event){

      let file = this.data.file
      this.data.file = null
      let formData = new FormData()
      for (let el in this.data){
        if (this.data[el] === null) continue;
        else if (this.data[el] === undefined) continue;
        else formData.append(`${el}`, this.data[el])
      }

      if (typeof file === 'object' && file !== null){
        if (String(file.name).length < 130)
          formData.append('file', file)
      }

      let url = creator.CREATOR.STAGE_FIELDS.CREATE(this.surveyId, this.stageId)
      finalizeRequest(makePostRequestFile(url, formData),
          (val) => {
            this.$emit('close', this.data)
          },
          (errors) => {
            if (errors.hasOwnProperty('type')){
              if (errors.hasOwnProperty('field')){
                errors.field.push(...errors.type)
              } else {
                errors.field = errors.type
              }
            }
            this.mapErrors(errors)
          })
    },
    closeDialog: function () {
      this.$emit('close', false)
    },
    applyDialog: function () {
      if (this.$refs.form.validate()) {
        this.closeAddStageField()
      } else {
        this.error.text = 'В форме имеются ошибки'
        this.error.show = true
      }
    }
  },
  created() {
    this.loadFieldsSelector();
  }
}
</script>

<style scoped>

</style>
