<template>
  <v-container class="px-0">
    <v-row no-gutters>

      <v-radio-group
        ref="input_value"
        v-model="model"
        :error="error"
        :error-messages="errorMessages"
        :hint="hint"
        :persistent-hint="persistentHint"
        :loading="loading"
        :readonly="readonly"
        :rules="getRules()"
        style="width: 100%"
      >
        <template v-slot:append v-if="downloadable">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click="$emit('download')"
              >
                <v-icon>file_download</v-icon>
              </v-btn>
            </template>
            <span>Загрузить документ</span>
          </v-tooltip>
        </template>
        <template v-slot:label>
          <slot name="label">
            {{label}}
          </slot>
          <v-progress-linear class="px-3" :active="loading" indeterminate></v-progress-linear>
        </template>
        <template v-if="Array.isArray(items)">
          <template v-if="!multiple">
            <v-radio  v-for="(item, index) in items" :key="index" :value="item.hasOwnProperty(itemValue) ? item[itemValue]: item">
              <template v-slot:label>
                {{item.hasOwnProperty(itemText) ? item[itemText]: item}}
              </template>
            </v-radio>
          </template>
          <template v-else>
            <v-list-item v-for="(item, index) in items" :key="index" dense>
              <v-checkbox  :value="item[itemValue]" v-model="model" multiple dense hide-details class="pt-0 mt-0" :readonly="readonly">
                <template v-slot:label>
                  {{item.hasOwnProperty(itemText) ? item[itemText]: item}}
                </template>
              </v-checkbox>
            </v-list-item>
          </template>
        </template>
        <template v-else>
          Ничего не выбрано
        </template>
      </v-radio-group>
    </v-row>
    <v-row class="grey--text text--darken-1" no-gutters>
      <v-input

      >
      </v-input>

    </v-row>
  </v-container>
</template>

<script>

// import VMessages from '@vuetify/src/components/VMessages'

import moment from "moment";

export default {
  name: "SmallSelectField",
  // components: {VMessages},
  props: {
    value: {
      required: true,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    label: String,
    hint: String,
    items: Array,
    itemText: {
      type: String,
      default: 'value',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    persistentHint: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      model: this.value,
    }
  },
  watch: {
    model(){
      if (JSON.stringify(this.value) !== JSON.stringify(this.model))
        this.$emit('input', this.model)
    },
    value(){
      if (JSON.stringify(this.value) !== JSON.stringify(this.model))
        this.model = this.value
    },
    multiple(){
      this.model = undefined
    }
  },
  methods: {
    validate(show_messages=true) {
      return this.$refs['input_value'].validate(show_messages)
    },
    getRules(){
      let rules = [
        ...this.rules,
      ]
      if (this.required) {
        rules.push(v => (!!v) || 'Поле обязательно к заполнению')
      }
      return rules
    },
  }
}
</script>

<style scoped>

</style>
