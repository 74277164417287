const SURVEY = {
    BASE: 'Survey',
    MAIN: undefined,
    SURVEYS: {
        _BASE: undefined,
        LIST: undefined,
        LINK_DETAIL: undefined,
        SURVEYING: undefined,
    }
};
SURVEY.MAIN = `${SURVEY.BASE}|Main`


SURVEY.SURVEYS._BASE = `${SURVEY.BASE}|Surveys`
SURVEY.SURVEYS.LIST = `${SURVEY.SURVEYS._BASE}|List`
SURVEY.SURVEYS.LINK_DETAIL = `${SURVEY.SURVEYS._BASE}|Link`
SURVEY.SURVEYS.SURVEYING = `${SURVEY.SURVEYS._BASE}|Surveying`


export default {
    ...SURVEY
}
