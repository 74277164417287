import helper from "../../../urls/helper";
import surveying from "../../../urls/roles/surveying";

import {
    makeDeleteRequest,
    makeGetRequest,
    sendPatchRequest,
    makeParticalUpdateRequest,
    makePostRequest, sendDeleteRequest,
    sendGetRequest, sendPostRequest, finalizeRequest
} from "../../../helper/requests";
import watcher from "../../../urls/roles/watcher";
import router from "../../../router";
import names from "../../../modules/watcher/routers/names";
import creator from "../../../urls/roles/creator";
import surveys from "@/urls/roles/admin";

const state = {
    stages: [],
}

const getters = {

}

const mutations = {
    set_stages: (state, data) => {
        state.stages = data
    },
}

const actions = {
    loadSurveyList: ({commit}, {url, finalizer, catcher}) => {
        if (!url) url = watcher.WATCHER.SURVEY.LIST()
        sendGetRequest(url, (data) => finalizer(data, url), catcher)
    },
    loadSurveyDetail: ({commit, getters}, {id, finalizer, catcher}) => {
        let url = watcher.WATCHER.SURVEY.DETAIL(id)
        sendGetRequest(url, finalizer, catcher)
    },
    loadStageList: ({commit}, {survey_id, finalizer, catcher}) => {
        let url = watcher.WATCHER.STAGE.LIST(survey_id,{'size': 100})
        sendGetRequest(url,
            (data) => {
                commit('set_stages', data)
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },
    loadStatisticFile: ({commit}, {id,get, finalizer, catcher}) => {
        let url = watcher.WATCHER.FILE.UNLODING(id, get)
        sendGetRequest(
            url,
            finalizer,
            catcher
        )
    },
    loadAttemptList: ({commit}, {url, finalizer, catcher}) => {
        if (!url) url = watcher.WATCHER.ATTEMPT.LIST()
        sendGetRequest(url, (data) => finalizer(data, url), catcher)
    },
    loadAttemptDetail: ({commit}, {id, finalizer, catcher}) => {
        let url = watcher.WATCHER.ATTEMPT.DETAIL(id)
        sendGetRequest(url, finalizer, catcher)
    },
    loadAttemptStageList: ({commit}, {id, finalizer, catcher}) => {
        let url = watcher.WATCHER.ATTEMPT.STAGES(id)
        sendGetRequest(url, finalizer, catcher)
    },
    loadAttemptStageFieldList: ({commit}, {id, stage_id, finalizer, catcher}) => {
        let url = watcher.WATCHER.ATTEMPT.FIELDS(id, stage_id)
        sendGetRequest(url, finalizer, catcher)
    },

    listStageField: ({commit}, {survey_id, stage_id, finalizer, catcher}) => {
        let url = watcher.WATCHER.STAGE_FIELDS.LIST(survey_id, stage_id,{'size': 100})
        sendGetRequest(url,
            (data) => {
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
