import Vue from 'vue'
import Vuex from 'vuex'
import leftmenu from './modules/leftmenu'
import maintoolbar from './modules/maintoolbar'
import mainpage from './modules/mainpage'
import urls from './modules/urls'
import formsaving from './modules/formsaving'
import auth from './modules/auth'
import surveying from "./modules/surveying/index";
import creator from "./modules/creator";
import watcher from "./modules/watcher";
import selectors from "@/store/modules/selectors";
import coherent from "@/store/modules/coherent";
import admin from "@/store/modules/admin";

Vue.use(Vuex);

const anonimusUsername = 'AnonimusUser';

export default new Vuex.Store({
    modules: {
        leftmenu,
        maintoolbar,
        mainpage,
        urls,
        formsaving,
        auth,
        admin,
        creator,
        watcher,
        coherent,
        selectors,
        surveying,
    },
    state: {},
    getters: {},
    mutations: {},
})
