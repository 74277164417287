<template>
  <v-card :outlined="outlined" flat class="my-2"  style="word-break: inherit !important;">
    <v-card-text>
      <v-card-title class="py-0 text-left">
          <span style="word-break: break-word !important;">
            {{field.title}}<span v-if="field.required" style="color: red">*</span>
          </span>
      </v-card-title>
      <default-field
        v-show="!field.hidden"
        v-model="model"
        :ref="`field_value`"
        :type="field.type"
        :label="''"
        :hint="field.description"
        persistent-hint
        :items="field.choices"
        :date_dynamic_lower="field.date_dynamic_lower"
        :date_dynamic_upper="field.date_dynamic_upper"
        :date_dynamic_value_type="field.date_dynamic_value_type"
        :date_static_lower="field.date_static_lower"
        :date_static_upper="field.date_static_upper"
        :coherent-type="field.coherent_type"
        :document_type="field.document_type"
        :error-messages="errors ? errors : []"
        :error="errors ? errors.length > 0 : false"
        @focus="$emit('focus', $event)"
        :readonly="field.read_only || loading"
        :rules="getRules(field)"
        :loading="loading"
        @download="$emit('download', field.file)"
        :downloadable="field.file !== undefined && field.file !== null"
      >
      </default-field>
    </v-card-text>
  </v-card>
</template>

<script>

import DefaultField from "@/modules/templates/custom_fields/DefaultField";

export default {
  name: "FieldView",
  components: {
    DefaultField
  },
  props: {
    field: Object,
    disable_required: {
      default: false,
    },
    survey: Boolean,
    outlined: {
      default: true,
    },
    value: {
      required: true
    },
    errors: {
      default: undefined
    },
    loading: {
      default: false
    }
  },
  data() {
    return {
      model: this.value,
    }
  },
  watch: {
    value(){
      if (this.model !== this.value)
        this.model = this.value
    },
    model(){
      this.$emit('input', this.model)
    }
  },
  methods: {
    getRules(field){
      let rules = [];
      if (field.required && !this.disable_required)
        rules.push((value) => {
          return !!value || 'Это поле является обязательным'
        })
      if (field.regex){
        rules.push((regex_string) => {
          return (value) => new RegExp(regex_string).test(value) || 'Значение не соответствует формату'
        })
      }
      return rules
    },
    validate(){
      return this.$refs[`field_value`].validate(true)
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
