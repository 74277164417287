<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px; width: 100%"
      v-if="$route.name === names.ATTEMPTS.LIST"
  >
    <template>
      <v-toolbar
          flat
          elevation="0"
          class="rounded-t-lg"
          color="style-color-main-gray"
      >
        <v-text-field
            single-line
            outlined
            dense
            clearable
            :messages="$vuetify.breakpoint.smAndUp ? 'Для поиска нажмите Enter или кнопку поиск' : 'Для поиска нажмите Enter'"
            :class="$vuetify.breakpoint.smAndUp ? 'grow' : ''"
            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
        </v-text-field>
        <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown" >
          <v-row no-gutters color="style-color-main-gray">
            <v-col cols="6" v-if="$vuetify.breakpoint.smAndDown">
              <v-select
                  outlined
                  style="padding-bottom: 2px"
                  single-line
                  flat
                  clearable
                  hide-details
                  dense
                  :items="selectors.is_ended"
                  v-model="pagination.filters.is_ended"
                  label="Фильтр по завершённости"
              ></v-select>
            </v-col>
          </v-row>
        </template>

      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :items-per-page.sync="pagination.size"
          :page.sync="pagination.page"
          :server-items-length="pagination.count"
          :footer-props="pagination.footer_page"
          :loading="pagination.loading"
          class="elevation-1"
      >
        <template v-slot:top>
          <v-alert type="info" outlined>
            Обратите внимание! Для роли Наблюдатель доступен просмотр завершенных попыток активных опросов
          </v-alert>
        </template>
        <template v-slot:item.survey.name="{ item }">
          <a
              style="text-decoration: none"
              @click="openDetail(item)">
            {{item.survey.name}}
          </a>
        </template>
        <template v-slot:item.user.email="{ item }">
          <span v-if="item.user.email">{{item.user.email}}</span>
          <span v-else >Внешний</span>
        </template>
        <template v-slot:item.is_started="{ item }">
          <v-icon color="green" v-if="item.is_started === true">done</v-icon>
          <v-icon color="red" v-else>close</v-icon>
        </template>
        <template v-slot:item.is_ended="{ item }">
          <v-icon color="green" v-if="item.is_ended === true">done</v-icon>
          <v-icon color="red" v-else>close</v-icon>
        </template>
      </v-data-table>
    </template>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
import TitledPageMixin from "../../../mixins/TitledPageMixin";
import names from "../routers/names";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import router from "../../../router";
import {mapActions} from "vuex";
import {loadData} from "@/helper/requests";

export default {
  name: "AttemptList",
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  components: {},
  data() {
    return {
      page_title: "Список попыток",
      search: {
        parameter: 'search',
      },
      pagination: {
        filters: {
          is_ended: null,
        }
      },

      reveal: false,
      expanded: [],
      singleExpand: true,
      loading: true,
      detail_flag: false,
      detail_data: undefined,
      is_started: null,
      is_ended: null,
      selectors: {
        is_ended: [
          {text:'Завершённые',value: 'true'},
          {text:'Не завершённые',value: 'false'},
        ],
      },
      headers: [
        {
          text: 'Название опроса',
          align: 'start',
          sortable: false,
          value: 'survey.name'
        },
        { text: 'Завершён', value: 'is_ended', sortable: false},
        { text: 'Почта', value: 'user.email', width: '25%', sortable: false},
      ],
      items: [],
      attempt_delete: false,
      select_attempt: undefined,
    }
  },
  computed: {
    names: () => names
  },
  watch: {
  },
  methods: {
    ...mapActions({
      loadAttemptList: 'watcher/loadAttemptList',
    }),
    initialize() {
      this.setLoading()
      this.loadData(undefined, true)
    },
    loadData(url=undefined, first=false){
      this.setLoading();
      this.loadAttemptList({
        url: url,
        finalizer: (val, url) => {
          this.setNotLoading();
          this.mapPagination(val)
          if(first)
            this.setCurrentLoadUrl(url);
          this.mapResults(val, 'items')
        }
      })
    },
    getItems (url=undefined) {
      loadData(url);
    },
    openDeleteAttemptDialog(attempt){
      this.attempt_delete = true
      this.select_attempt = attempt
    },
    deleteAttempt(event){
      if (event){
        this.$store.dispatch(
            "admsurvey/AdmAttemptDelete",
            {
              id: this.select_attempt.id,
              finalizer: (val) => {
                this.initialize()
                this.attempt_delete = false
                this.select_attempt = undefined
              }
            }
        )
      }
        this.attempt_delete = false
        this.select_attempt = undefined
    },
    openDetail(item){
      router.push({
        name: names.ATTEMPTS.DETAIL,
        params: {idAttempt: item.id}
      })
    },
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>

</style>
