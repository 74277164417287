export default {
  props: {
    loading: {
      default: false,
    }
  },
  data(){
    return {
      isLoading: this.loading
    }
  },
  watch: {
    loading(){
      this.isLoading = this.loading
    }
  },
  computed: {
  },
  methods: {
    setLoading(){
      this.isLoading = true;
      this.$emit('update:loading', true);
    },
    setNotLoading(){
      this.isLoading = false;
      this.$emit('update:loading', false);
    },
  },
  created() {
  }

}
