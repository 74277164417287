<template>
  <v-list single-line>
    <span
      v-for="(item, i) in instances.slice().reverse()"
      :key="i"
    >
      <v-divider class="" />
      <InstanceLeftMenuAction
        v-if="item.router"
        :current-instance="current_instance"
        :item="item"
      />
    </span>
  </v-list>
</template>

<script>
import InstanceLeftMenuAction from "@/modules/menus/left_menu/InstanceLeftMenuAction";

export default {
  name: "InstanceLeftMenu",
  components: {
    InstanceLeftMenuAction,
  },
  props: {
    instances: {
      required: true,
      type: Array,
    },
    current_instance: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
