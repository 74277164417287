<template>
  <scroll-center-dialog
      v-model="opened"
      title="Добавление поля"
      @close="$emit('close')"
      @apply="$emit('apply')"
  >
      <v-expand-transition>
        <v-alert
            v-if="errorShow"
            type="warning"
            rounded="0" dense
            class="my-0 py-2" dismissible
        >
          {{errorText}}
        </v-alert>
      </v-expand-transition>
      <v-card-text>
        <slot name='form'></slot>
      </v-card-text>
  </scroll-center-dialog>
</template>

<script>

import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";

export default {
  name: "EditSurveyAddFieldTemplate",
  components: {ScrollCenterDialog},
  props: {
    opened: Boolean,
    errorShow: Boolean,
    errorText: {
      required: false
    },
    loading: Boolean
  },
  data() {
    return {
      errorShowModel: this.errorShow
    }
  },
  computed: {

  },
  watch: {
    errorShow(){
      this.errorShowModel = this.errorShow;
    },
    errorShowModel(){
      this.$emit('update:errorShow', this.errorShowModel)
    },
  },
  methods: {

  },
  created() {}
}
</script>

<style scoped>

</style>