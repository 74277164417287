import {
  sendPostRequest, sendPutRequest, sendDeleteRequest, sendGetRequest
} from "@/helper/requests";
import surveys from "@/urls/roles/admin";

const state = {

}

const getters = {
}

const mutations = {
}

const actions = {
  loadFieldList: ({commit}, {url, finalizer, catcher}) => {
    if (!url)
      url = surveys.ADMIN.FIELDS.LIST()
    sendGetRequest(
      url,
      (data) => finalizer(data, url),
      catcher
    )
  },
  loadFieldDetail: ({commit, getters}, {id, finalizer, catcher}) => {
    sendGetRequest(
      surveys.ADMIN.FIELDS.DETAIL(id),
      finalizer,
      catcher
    )
  },
  createField: ({dispatch, commit}, {data, finalizer, catcher}) => {
    sendPostRequest(
      surveys.ADMIN.FIELDS.SAVE(),
      data,
      finalizer,
      catcher
    )
  },
  updateField: ({dispatch, commit}, {data, finalizer, catcher}) => {
    sendPutRequest(
      surveys.ADMIN.FIELDS.EDIT(data.id),
      data,
      finalizer,
      catcher
    )
  },
  deleteField: ({dispatch, commit}, {data, finalizer, catcher}) => {
    sendDeleteRequest(
      surveys.ADMIN.FIELDS.DELETE(data.id),
      finalizer,
      catcher
    )
  },
}


export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
