<template>
  <v-menu
      ref="menu_flag"
      v-model="open"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-mask="'##.##.#?#?#?#'"
        ref="text_value"
        :outlined = "outlined"
        v-model="text_value"
        :label="label"
        :rules="getFormatRules()"
        :hint="description || hint"
        :persistent-hint="!!description || !!hint"
        :error="error"
        :error-messages="errorMessages"
        :loading="loading"
        :readonly="readonly"
        v-bind="openOnFocus ? attrs: {}"
        v-on="openOnFocus ? on: {}"
        @focus="$emit('focus', $event)"
      >
        <template v-slot:append-outer v-if="downloadable">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click="$emit('download')"
              >
                <v-icon>file_download</v-icon>
              </v-btn>
            </template>
            <span>Загрузить документ</span>
          </v-tooltip>
        </template>
        <template v-slot:prepend-inner v-if="!prependOuterIcon">
          <v-icon v-bind="attrs" v-on="on">{{icon}}</v-icon>
        </template>
        <template v-slot:prepend v-else>
          <v-icon v-bind="attrs" v-on="on" >{{icon}}</v-icon>
        </template>
        <template v-slot:label>
          <slot name="label">
            {{label}}
            <span v-if="required" class="red--text">*</span>:
          </slot>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text style="min-height:50px; max-width: 300px" class="body-1 wrap_text subprimary">{{label}}</v-card-text>
      <v-divider></v-divider>
      <v-date-picker
          v-model="inside_value"
          no-title
          scrollable
          :first-day-of-week="1"
          locale="ru-ru"
          @change="open = false"
          :allowed-dates="allowedDates"
          :max="getUpperBound()"
          :min="getLowerBound()"
          :readonly="readonly"
      >
        <template v-if='!closeOnContentClick'>
          <v-btn
            :icon="$vuetify.breakpoint.smAndDown"
            :text="$vuetify.breakpoint.mdAndUp"
            color="primary"
            @click="open = false"
          >
            <v-icon>highlight_off</v-icon>
            <span v-if="$vuetify.breakpoint.mdAndUp">Отмена</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :icon="$vuetify.breakpoint.smAndDown"
            :text="$vuetify.breakpoint.mdAndUp"
            color="primary"
            @click="$refs.menu_flag.save(inside_value)"
          >
            <span v-if="$vuetify.breakpoint.mdAndUp">Ок</span>
            <v-icon>check_circle</v-icon>
          </v-btn>
        </template>
      </v-date-picker>
    </v-card>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePicker",
  props: {
    'value': {
      required: true
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    label: {
      required: true
    },
    description: {
      required: false
    },
    hint: {
      required: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    outlined: {
      required: false,
      default: false,
      type: Boolean
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    allowedDates: {
      type: Function,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    openOnFocus: {
      type: Boolean,
      default: false,
    },
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'edit',
    },
    prependOuterIcon:{
      type: Boolean,
      default: false,
    },
    dateDynamicValueType: [String, Function],
    dateDynamicUpper: [Number, Function],
    dateDynamicLower: [Number, Function],
    dateStaticUpper: [String, Function],
    dateStaticLower: [String, Function],
  },
  data(){
    return {
      date_regex: /[0-9][0-9].[0-9][0-9].[0-9]{1,4}/,
      format: 'DD.MM.YYYY',
      iso_format: '',
      open: false,
      inside_value: this.value,
      text_value: this.value !== undefined ? this.dateToFormant(this.value) : '',
    }
  },
  watch: {
    value(nV, oV){
      if(nV !== oV)
        this.inside_value = this.value
    },
    inside_value(){
      this.text_value = this.inside_value ? this.dateToFormant(this.inside_value): ''
      this.$emit('input', this.inside_value)
      this.$emit('focus', this.inside_value)
    },
    text_value(val){
      let str = (String(val))
      if (str.length > 9)
        this.inside_value =  this.dateFromFormat(val)
    },
    open(){
      let value = this.dateFromFormat(this.text_value);
      if (this.open && value !== '') {
        this.inside_value = value;
      } else if (this.open) {
        this.text_value = '';
      }
    }
  },
  computed: {
    date_dynamic_value_type(){
      return this.getFormParameter(this.dateDynamicValueType)
    },
    date_dynamic_upper(){
      return this.getFormParameter(this.dateDynamicUpper)
    },
    date_dynamic_lower(){
      return this.getFormParameter(this.dateDynamicLower)
    },
    date_static_upper(){
      return this.getFormParameter(this.dateStaticUpper)
    },
    date_static_lower(){
      return this.getFormParameter(this.dateStaticLower)
    },
  },
  methods: {
    getFormParameter(parameter){
      if (typeof parameter === 'function')
        return parameter()
      return parameter
    },
    getUpperBound(shift=0) {
      if (this.date_dynamic_value_type) {
        return moment().add(this.date_dynamic_upper + shift, this.date_dynamic_value_type).toISOString();
      } else {
        return this.date_static_upper
      }
    },
    getLowerBound(shift=0) {
      if (this.date_dynamic_value_type) {
        return moment().subtract(this.date_dynamic_lower + shift, this.date_dynamic_value_type).toISOString();
      } else {
        return this.date_static_lower
      }
    },
    dateToFormant(value){
      return moment(value).format('DD.MM.YYYY')
    },
    dateFromFormat(value){
      if (value === undefined || value === null || value === '')
        return ''
      if (this.date_regex.test(value)){
        return moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
      return ''
    },
    getFormatRules(){
      let rules = [
        ...this.rules,
        v => (this.date_regex.test(v) || v === '') || 'Неправильный формат даты. Используйте формат DD.MM.YYYY',
      ]
      if (this.required) {
        rules.push(v => (!!v) || 'Поле обязательно к заполнению')
      }
      if (!!this.getUpperBound()) {
        rules.push(
          v => (!v || moment(v, 'DD.MM.YYYY').isBefore(this.getUpperBound(1))) || `Дата не может быть позже ${moment(this.getUpperBound(0)).format('DD.MM.YYYY')}`,
          v => (!v || moment(v, 'DD.MM.YYYY').isAfter(this.getLowerBound(1))) || `Дата не может быть раньше ${moment(this.getLowerBound(0)).format('DD.MM.YYYY')}`
        )
      }
      return rules
    },
    validate(show_messages=true) {
      return this.$refs['text_value'].validate(show_messages)
    },
  },
  created() {}
}
</script>

<style scoped>

</style>
