<template>
    <v-container fluid>
        <v-row align="center" justify="center" no-gutters style="height: 85vh">
            <v-col cols="12" md="8" lg="6">
                <v-card class="elevation-12 rounded-lg">
                    <v-toolbar color="primary" class="text-md-h6 text-body-2" dark>
                        <v-spacer/>
                        Для продолжения работы в системе необходимо авторизоваться
                        <v-spacer/>
                    </v-toolbar>
                    <v-card-text class="text-md-h6 text-body-1">
                        Выберите вариант авторизации
                    </v-card-text>
                    <v-card-text>
                        <v-row
                                no-gutters
                                class="pb-4"
                                align="center"
                                justify="center"
                        >
                            <v-btn   class="primary mr-sm-2 mb-4 mb-sm-0 my-1 "
                                     @click="goPortal()"
                                     :min-width="310"
                            >
                                корпоративный пользователь
                            </v-btn>

                            <v-btn    class="primary ml-sm-2 mb-4 mb-sm-0 my-1"
                                      @click="goGuest()"
                                      :min-width="310"
                            >
                                внешний пользователь
                            </v-btn>
                        </v-row>
                        <v-alert class="rounded-lg my-0" color="info" outlined>
                            <div>
                                Если у вас возникли трудности с использованием системы, вопросы или предложения, обратитесь в службу технической поддержки <a href="mailto:support@muctr.ru">support@muctr.ru</a>.
                            </div>
                        </v-alert>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "WelcomePage",
    methods: {
        goGuest(){
            this.$emit('goGuest')
        },
        goPortal(){
            this.$emit('goPortal')
        }
    }
}
</script>

<style scoped>

</style>
