import store from "@/store";


export function getInstMenu() {
    /**
     * Функция которая возвращает нижнее боковое меню инстанса
     * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
     */
    let menu = []
    // if (store.getters['urls/getAllowed']('survey', 'surveys|surveying_survey|survey-list', 'list'))
    menu.push({title: 'Опрашиваемый', icon: 'person', router: "Survey", name: 'Survey'})

    if (store.getters['urls/getAllowed']('survey', 'surveys|watch_survey|survey-list', 'list'))
        menu.push({title: 'Наблюдатель', icon: 'visibility', router: "Watcher|Main", name: 'Watcher|Main'})
    if (store.getters['urls/getAllowed']('survey', 'surveys|creator_survey|survey-list', 'list'))
        menu.push({title: 'Создатель', icon: 'drive_file_rename_outline', router: "Creator|Main", name: 'Creator|Main'})
    if (store.getters['urls/getAllowed']('survey', 'surveys|admin_survey|survey-list', 'list'))
        menu.push({title: 'Административный работник', icon: 'admin_panel_settings', router: "Administrative|Main", name: 'Administrative|Main'})

    // menu.push(
    //     {title: 'Опрашиваемый', icon: 'person', router: "Survey", name: 'Survey',},
    //     {title: 'Наблюдатель', icon: 'visibility', router: "Watcher|Main", name: 'Watcher|Main',},
    //     {title: 'Создатель', icon: 'drive_file_rename_outline', router: "Creator|Main", name: 'Creator|Main',},
    //     {title: 'Административный работник', icon: 'admin_panel_settings', router: "Administrative|Main", name: 'Administrative|Main',},
    // )
    return menu
}

export function getInstNameByRouterName(router) {
    /**
     * Функция которая возвращает нижнее боковое меню инстанса
     * @return {Array} - списко всех подмодулей инстанса, отображаемых в левом нижнем меню
     */
    return getInstMenu().find(el => {
        return router === el.router
    }).name
}
