<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px; width: 100%"
      v-if="$route.name === names.SURVEYS.CREATE"
  >
    <v-row justify="center">
      <v-col cols="12" sm="10">
        <v-card>
          <v-toolbar color="grey lighten-2" flat>
            <v-toolbar-title>
              Информация об опросе
            </v-toolbar-title>
          </v-toolbar>
          <v-progress-linear v-show="loading" indeterminate top absolute></v-progress-linear>
          <v-card-text>
            <survey-form-template v-model="survey" ref="form"
                                  :loading_data="loading"
                                  :form_errors="errors">
              <template v-slot:actions>
                <v-btn
                  class="mt-3"
                  outlined
                  color="success"
                  @click="openApply()"
                >
                  Сохранить опрос
                </v-btn>
              </template>
            </survey-form-template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ApplyDialog
        v-if="save_survey"
        :opened="save_survey"
        :disabled="loading"
        :get-text="() => `Сохранить опрос ?`"
        @close="saveSurvey"
    ></ApplyDialog>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
import names from "../routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import SurveyFormTemplate from "@/modules/templates/survey/SurveyFormTemplate";
import {mapActions} from "vuex";


export default {
  name: "CreateSurvey",
  components: {
    ApplyDialog,
    SurveyFormTemplate
  },
  mixins: [TitledPageMixin],
  data(){
    return {
      loading: false,
      page_title: "Создание опроса",
      survey: {
        id: undefined,
        name: '',
        description: '',
        kind: '',
        anonymity: '',
        publication_date: '',
        end_date: '',
      },
      save_survey: false,
      errors: {},
    }
  },
  computed: {
    names: () => names,
  },
  methods: {
    ...mapActions({
      createSurvey: 'creator/createSurvey',
    }),
    openApply(){
      if(this.$refs['form'].validate()){
        this.save_survey = true;
      }
    },
    closeApply(){
      this.save_survey = false;
    },
    setLoading(){
      this.loading = true;
    },
    setNotLoading(){
      this.loading = false;
    },
    routeToEditPage(id){
      this.$router.push({
        name: names.SURVEYS.EDIT,
        params: {idSurvey: id}
      })
    },
    saveSurvey(apply){
      if (apply) {
        this.setLoading();
        this.createSurvey({
          data: this.survey,
          finalizer: (data) => {
            this.setNotLoading();
            this.routeToEditPage(data.id);
          },
          catcher: (errors) => {
            this.errors = errors;
            this.closeApply();
            this.setNotLoading();
          }
        })
      } else {
        this.closeApply();
      }
    },
  },
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>
