<template>
  <scroll-center-dialog v-model="field_flag" @close="closeDialog" @apply="applyDialog" :title="form_title">
    <v-select
      class="pt-2"
      ref="type"
      v-model="value.type"
      :items="selectors.types"
      :loading="loading.all"
      :readonly="loading.all"
      item-text="text"
      outlined
      item-value="value"
      persistent-hint
      hint="Выберите базовый тип поля, от него зависит какую информацию потребуется заполнить"
      :error="hasError('type')"
      :error-messages="getError('type')"
      @input="deleteError('type')"
    ></v-select>
    <template v-for="(object, idx) in TYPE_FIELDS[value.type].fields">
      <component
        :is="object.component"
        v-show="object.visibility ? object.visibility() : true"
        :ref="object.model"
        :key="`field_${idx}`"
        :label="object.label"
        :hint="object.help_text"
        :outlined="true"
        :persistent-hint="object.help_text !== undefined"
        :rules="[getRequiredRule(object.required), getRegexRule(object.regex)]"
        :items="selectors[object.items]"
        :item-text="object.item_text"
        :item-value="object.item_value"
        :multiple="object.multiple"
        :loading="loading[object.loading] || loading.all"
        :readonly="loading[object.loading] || loading.all"
        :error="hasError(object.model)"
        :allErrorMessages="errors"
        :error-messages="getError(object.model)"
        :type="value.type"
        @input="deleteError(object.model)"
        v-model="value[object.model]"
        v-bind="prepareAttrs(object.attrs)"
      >
        <template v-slot:label>
          {{object.label}}<span v-if="object.required" class="red--text">*</span>:
        </template>
      </component>
    </template>
  </scroll-center-dialog>
</template>

<script>

import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {TYPES} from "@/helper/field_types";
import names from "../../../survey/routers/names";
import {mapActions} from "vuex";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";
import DatePicker from "@/modules/templates/custom_fields/DatePicker";
import TimePicker from "@/modules/templates/custom_fields/TimePicker";
import DateWithBounds from "@/modules/templates/custom_fields/DateWithBounds";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import DefaultField from "@/modules/templates/custom_fields/DefaultField";
import coherent from "@/urls/coherent";


export default {
  name: "FieldCreate",
  props: {
    field_flag: Boolean,
    field_data: {
      type: Object,
      default: () => {return {}}
    }
  },
  mixins: [FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    DateWithBounds,
    DatePicker,
    TimePicker,
    LoadingAutocomplete,
    DefaultField
  },
  data(){
    const FIELDS = {
          NAME: {
            component: 'v-text-field',
            label: 'Наименование поля в системе',
            help_text: 'Этот текст отображается в момент добавления поля в этап опроса',
            required: true,
            model: 'name',
          },
          TITLE: {
            component: 'v-text-field',
            label: 'Отображаемое наименование',
            help_text: 'Этот текст отображается пользователю во время прохождения опроса',
            required: true,
            model: 'title',
          },
          DESCRIPTION: {
            component: 'v-text-field',
            label: 'Подсказка к полю',
            help_text: 'Этот текст может содержать подсказку или пояснение по заполнению поля во время опроса и будет отображаться под полем',
            model: 'description',
          },
          REGEX: {
            component: 'v-text-field',
            label: 'Регулярное выражение',
            help_text: 'Это регулярное выражение будет применятся при проверке сохраняемого значения поля',
            model: 'regex',
          },
          DEFAULT_BASE: {
            component: 'default-field',
            label: 'Значение по умолчанию',
            help_text: 'Это значение будет вводиться в поле автоматически при попытке пройти опрос',
            model: 'default_value',
            loading: 'default',
          },
          DOCUMENT: {
            component: 'v-file-input',
            label: 'Прилагаемый документ',
            help_text: 'Этот документ будет доступен для скачивания пользователю во время прохождения опроса.',
            model: 'document',
          },
          DATE: {
            component: 'DateWithBounds',
            model: 'date',
            // label: 'Прилогаемый документ',
            // help_text: 'Этот документ будет доступен для скачивания пользователю во время прохождения опроса.',
          },
          CHOICES: {
            component: 'v-autocomplete',
            label: 'Список выбора',
            help_text: 'Из этого списка значений будет выбираться ответ при прохождении теста.',
            model: 'choices',
            items: 'choices',
            item_text: 'name',
            item_value: 'id',
            loading: 'choices',
          },
          STATIC_CHOICES: {
            component: 'v-switch',
            label: 'Неизменяемый список выбора',
            help_text: 'Параметр показывает можно ли изменить список выбора используемый в поле на другой для конкретного опроса',
            model: 'static_choices',
          },
          COHERENT: {
            component: 'v-autocomplete',
            label: 'Данные из системы MUCTR',
            help_text: 'Данные выбранного типа будут загружаться для возможности выбора из них пользователем точного значения, или будут автоматически проставлятся во время инициализации попытки прохождения опроса.',
            model: 'coherent_type',
            items: 'coherent',
          },
          TYPE_FILE: {
            component: 'v-autocomplete',
            label: 'Тип загружаемого файла',
            help_text: 'Разрешенный тип файла для отправки',
            model: 'document_type',
            items: 'document_type',
            item_text: 'text',
            item_value: 'value',
          },
        };
    // const BASE_FIELDS = [FIELDS.NAME, FIELDS.TITLE, FIELDS.DESCRIPTION, FIELDS.DOCUMENT];
    const BASE_FIELDS = [FIELDS.NAME, FIELDS.TITLE, FIELDS.DESCRIPTION];
    const SELECTOR_TYPES_FIELDS = {
      fields: [
        ...BASE_FIELDS,
        FIELDS.CHOICES,
        FIELDS.STATIC_CHOICES,
        {
          ...FIELDS.DEFAULT_BASE,
          items: 'choices_values',
          visibility: () => {
            if(this.selectors.choices_values.length === 0) {
              return false
            } else {
              return true
            }
          }
        },
      ]
    };
    let prepareDefaultValue = (default_value) => {
      if (default_value) {
        if (default_value[0] === '[')
          return JSON.parse(default_value)
        else if (default_value === 'true')
          return true
        else if (default_value === 'false')
          return false
        else
          return default_value;
      } else {
        return undefined;
      }
    };
    return {
      scroll_to_error: true,
      header_height: 86,
      loading: {
        default: false,
        choices: false,
        all: false
      },
      selectors: {
        types: [],
        coherent: [],
        choices: [],
        choices_values: [],
        document_type: [],
      },
      // choices: [],
      // select_choice: undefined,
      // choice_values: [],
      value: {
        name: this.field_data.name ? this.field_data.name : undefined,
        title: this.field_data.title ? this.field_data.title : undefined,
        description: this.field_data.description ? this.field_data.description : undefined,
        type: this.field_data.type ? this.field_data.type : undefined,
        public: this.field_data.public ? this.field_data.public : undefined,
        default_value: prepareDefaultValue(this.field_data.default_value),
        regex: this.field_data.regex ? this.field_data.regex : undefined,
        document: this.field_data.document ? this.field_data.document : undefined,
        choices: this.field_data.choices ? this.field_data.choices : undefined,
        document_type: this.field_data.document_type ? this.field_data.document_type : undefined,
        static_choices: this.field_data.static_choices ? this.field_data.static_choices : undefined,
        date: {
            date_dynamic_value_type: this.field_data.date_dynamic_value_type ? this.field_data.date_dynamic_value_type : undefined,
            date_dynamic_upper: this.field_data.date_dynamic_upper ? this.field_data.date_dynamic_upper : undefined,
            date_dynamic_lower: this.field_data.date_dynamic_lower ? this.field_data.date_dynamic_lower : undefined,
            date_static_upper: this.field_data.date_static_upper ? this.field_data.date_static_upper : undefined,
            date_static_lower: this.field_data.date_static_lower ? this.field_data.date_static_lower : undefined
          },
        coherent_type: this.field_data.coherent_type ? this.field_data.coherent_type : undefined,
      },
      // menu_flag: false,
      // data_type: false,
      TYPE_FIELDS: {
        [TYPES.NUMBER]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
          ]
        },
        [TYPES.STRING]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
            FIELDS.REGEX,
          ]
        },
        [TYPES.TEXT]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
          ]
        },
        [TYPES.DATE]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
            FIELDS.DATE,
          ]
        },
        [TYPES.BOOL]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
          ]
        },
        [TYPES.TIME]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
          ]
        },
        [TYPES.EMAIL]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
          ]
        },
        [TYPES.PHONE]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
          ]
        },
        [TYPES.MIXER]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
          ]
        },
        [TYPES.LINK]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
          ]
        },
        [TYPES.ONE_OF]: {
          ...SELECTOR_TYPES_FIELDS
        },
        [TYPES.MANY_OF]: {
          ...SELECTOR_TYPES_FIELDS,
        },
        [TYPES.LARGE_ONE_OF]: {
          ...SELECTOR_TYPES_FIELDS,
        },
        [TYPES.LARGE_MANY_OF]: {
          ...SELECTOR_TYPES_FIELDS,
        },
        [TYPES.COHERENT]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.COHERENT,
            {
              ...FIELDS.DEFAULT_BASE,
              attrs: {
                coherentType: () => {
                  return this.value.coherent_type
                }
              },
              visibility: () => {
                if (this.coherent_selected_object) {
                  return this.coherent_selected_object.list
                }
                return false
              }
            },
          ]
        },
        [TYPES.FILE]: {
          fields: [
            ...BASE_FIELDS,
            FIELDS.DEFAULT_BASE,
            FIELDS.TYPE_FILE,
          ]
        },
      },
      scrolled: false,
    }
  },
  // document_type
  watch: {
    'value.coherent_type'(){
      if (this.coherent_selected_object){
        this.value.default_value = undefined
      }
    },
    'value.type'(){
      if ([TYPES.ONE_OF, TYPES.MANY_OF, TYPES.LARGE_ONE_OF, TYPES.LARGE_MANY_OF, TYPES.FILE].indexOf(this.value.type) !== -1){
        this.value.default_value = undefined
      }
    },
    'value.choices'(){
      if (this.value.choices)
        this.loadChoicesValueSelector();
      else
        this.value.default_value = undefined
    },
  },
  computed: {
    names: () => names,
    form_title () {
      return this.field_data.id ? 'Редактирование поля' : 'Создание поля'
    },
    coherent_selected_object() {
      return this.selectors.coherent.find((el) => el.value === this.value.coherent_type)
    }
  },
  methods: {
    ...mapActions({
      loadFieldTypeSelector: 'selectors/loadFieldTypeSelector',
      loadCoherentSelector: 'selectors/loadCoherentSelector',
      loadDocumentTypeSelector: 'selectors/loadDocumentTypeSelector',
      loadChoices: 'selectors/loadChoicesAdminSelector',
      // loadChoices: 'admin/loadChoiceList',
      loadChoiceValue: 'admin/loadChoiceValueList',
      loadCoherentValues: 'coherent/loadCoherentValues',
      createField: 'admin/createField',
      updateField: 'admin/updateField'
    }),
    checkCoherentIsList(){
      return this.selectors.coherent[this.value.coherent_type].list
    },
    getRegexRule(regex_string, error_string = 'Значение не соответствует регулярному выражению'){
      if (regex_string !== undefined) {
        return (v) => {
          return (v === '' || v === undefined  || new RegExp(regex_string).test(v)) || error_string
        }
      }
      return true
    },
    getRequiredRule(required, error_string = "Поле обязательно для заполнения"){
      if (required)
        return (v) => !!v || error_string
      return true
    },
    loadChoicesSelector() {
      this.loading.choices = true;
      this.loadChoices(
        {
          finalizer: (data) => {
            this.selectors.choices = data;
            this.loading.choices = false;
          }
        }
      );
    },
    prepareAttrs(attrs){
      let new_attrs = {...attrs}
      for (let key in new_attrs) {
        if (typeof new_attrs[key] === 'function') {
          new_attrs[key] = new_attrs[key]()
        }
      }
      return new_attrs
    },
    loadChoicesValueSelector(clear_default=true) {
      this.loading.default = true;
      this.loadChoiceValue(
        {
          value_choices_id: this.value.choices,
          finalizer: (data) => {
            this.loading.default = false;
            this.selectors.choices_values = data;
            if(!clear_default)
              this.value.default_value = undefined;
          }
        }
      );
    },
    validate() {
      let result = true;
      for (let el in this.value) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.$refs.hasOwnProperty(el)){
          if (this.$refs[el].length) {
            result &= this.$refs[el][0].validate(true);
          } else {
            result &= this.$refs[el].validate(true);
          }
        }
      }
      return result
    },
    closeDialog: function () {
      this.$emit('close', false)
    },
    applyDialog: function () {
      if (this.validate()) {
        if (this.field_data !== undefined && this.field_data.id !== undefined) {
          this.updateFieldDetail();
        } else {
          this.createFieldDetail();
        }
      }
    },
    prepareData: function () {
      let data = {};
      if (this.field_data.id) {
        data['id'] = this.field_data.id
      }
      if (this.value.date){
        for (let el in this.value.date) {
          if (this.value.date[el] !== undefined)
            data[el] = this.value.date[el]
        }
      }
      for (let el in this.value) {
        if (el !== 'date') {
          if (this.value[el] !== undefined && Array.isArray(this.value[el]))
            data[el] = JSON.stringify(this.value[el]);
          else if (this.value[el] !== undefined && typeof this.value[el] == "boolean")
            data[el] = `${this.value[el]}`;
          else
            data[el] = this.value[el];
        }
      }
      return data
    },
    catchErrors(errors) {
      this.scrolled = false;
      this.loading.all = false;
      let date_fields = ['date_dynamic_value_type', 'date_dynamic_upper', 'date_dynamic_lower', 'date_static_upper', 'date_static_lower'];
      let date_errors = {}
      for (let field in date_fields) {
        // eslint-disable-next-line no-prototype-builtins
        if (errors.hasOwnProperty(field)) {
          date_errors[field] = errors[field];
          delete errors[field];
        }
      }
      errors['date'] = date_errors
      this.mapErrors(errors)
    },
    createFieldDetail: function () {
      this.loading.all = true;
      this.createField({
          data: this.prepareData(),
          finalizer: () => {
            this.loading.all = false;
            this.$emit('close')
          },
          catcher: this.catchErrors
        }
      )
    },
    updateFieldDetail: function () {
      this.loading.all = true;
      this.updateField({
        data: this.prepareData(),
        finalizer: () => {
          this.loading.all = false;
          this.$emit('close')
        },
        catcher: this.catchErrors
      })
    },
  },
  created() {
    this.loadFieldTypeSelector({
      finalizer: (data) => {
        this.selectors.types = data;
        if (!this.field_data.id)
          this.value.type = data[0].value;
      }
    });
    this.loadDocumentTypeSelector({
      finalizer: (data) => {
        this.selectors.document_type = data;
        if (!this.field_data.id)
          this.value.document_type = data[0].value;
      }
    });
    this.loadCoherentSelector({
      finalizer: (data) => {
        this.selectors.coherent = data;
      }
    });
    this.loadChoicesSelector();
    if (this.value.choices)
      this.loadChoicesValueSelector(true);
  },
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>
