<template>
    <v-dialog persistent v-model="opened" width="500">
        <v-card>
            <v-card-title style="word-break: break-word!important;">Скачать результаты опроса "{{survey.name}}"</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pt-5">
              <date-picker
                  v-model="value.start"
                  label="Начало интервала"
                  :outlined="true"
                  hint="Дата, с которой будут выгруженны результаты опроса"
                  icon="event_available"
                  close-on-content-click
                  prepend-outer-icon
                  open-on-focus
                  :error="hasError('start')"
                  :error-messages="getError('start')"
                  @input="deleteError('start')"
              ></date-picker>
              <date-picker
                  v-model="value.end"
                  label="Окончание интервала"
                  hint="Дата, до которой будут выгруженны результаты опроса"
                  icon="event_available"
                  close-on-content-click
                  :outlined="true"
                  prepend-outer-icon
                  open-on-focus
                  :error="hasError('end_date')"
                  :error-messages="getError('end_date')"
                  @input="deleteError('end_date')"
              ></date-picker>
              <v-checkbox
                  v-model="value.end_only"
                  label="Только завершенные попытки"
                  hint="Выгружать только завершенные попытки"
              ></v-checkbox>
            </v-card-text>
            <v-card-actions>
                <v-flex>
                    <v-btn
                            @click="applyDialog"
                            color="warning"
                            text
                    >
                        Подтвердить
                    </v-btn>
                    <v-btn
                            @click="closeDialog"
                            text
                    >
                        Отменить
                    </v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import FormErrorsMixin from "@/mixins/FormErrorsMixin";
    import DatePicker from "@/modules/templates/custom_fields/DatePicker";
    export default {
        name: "AttempLoadXls",
        mixins: [FormErrorsMixin],
        components: {
          DatePicker
        },
        props: {
            getText: {
                type: Function,
                default: undefined,
            },
            survey:Object,
            opened: Boolean,
        },
        data: function () {
            return {
              value:{
                end_only:true,
                start:null,
                end:null,
              }
            }
        },
        methods: {
            closeDialog: function () {
                this.$emit('close', false)
            },
            applyDialog: function () {
                this.$emit('close', this.value)
            }

        }
    }
</script>

<style scoped>

</style>
