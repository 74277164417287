<template>
  <v-menu
      ref="menu_flag"
      v-model="open"
      :close-on-content-click="false"
      :return-value.sync="inside_value"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-mask="'##:##'"
        ref="text_value"
        v-model="inside_value"
        :label="label"
        :rules="getFormatRules()"
        :hint="description || hint"
        :outlined="outlined"
        :persistent-hint="!!description || !!hint"
        :error="error"
        :error-messages="errorMessages"
        :loading="loading"
        :readonly="readonly"
        @focus="$emit('focus', $event)"
      >
        <template v-slot:append-outer v-if="downloadable">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click="$emit('download')"
              >
                <v-icon>file_download</v-icon>
              </v-btn>
            </template>
            <span>Загрузить документ</span>
          </v-tooltip>
        </template>
        <template v-slot:prepend-inner>
          <v-icon v-bind="attrs" v-on="on">watch_later</v-icon>
        </template>
        <template v-slot:label>
          <slot name="label">
            {{label}}<span v-if="required" class="red--text">*</span>:
          </slot>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text  style="min-height:50px; max-width: 300px" class="body-1 wrap_text subprimary">{{label}}</v-card-text>
      <v-divider></v-divider>
      <v-time-picker

        v-model="inside_value"
        no-title
        scrollable
        locale="ru-ru"
        format="24hr"
        :readonly="readonly"
      >
        <v-btn
          :icon="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          color="primary"
          @click="open = false"
        >
          <v-icon>highlight_off</v-icon>
          <span v-if="$vuetify.breakpoint.mdAndUp">Отмена</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :icon="$vuetify.breakpoint.smAndDown"
          :text="$vuetify.breakpoint.mdAndUp"
          color="primary"
          @click="$refs.menu_flag.save(inside_value)"
        >
          <span v-if="$vuetify.breakpoint.mdAndUp">Ок</span>
          <v-icon>check_circle</v-icon>
        </v-btn>
      </v-time-picker>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "TimePicker",
  props: {
    value: {
      required: true
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    label: {
      required: true
    },
    description: {
      required: false
    },
    hint: {
      required: false
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    outlined: {
      required: false,
      default: false,
      type: Boolean
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      date_regex: /[0-9][0-9]:[0-9][0-9]/,
      open: false,
      inside_value: this.value,
    }
  },
  watch: {
    inside_value(){
      this.$emit('input', this.inside_value)
      this.$emit('focus', this.inside_value)
    }
  },
  methods: {
    getFormatRules(){
      let rules = [
        ...this.rules,
        v => (this.date_regex.test(v) || v === '') || 'Неправильный формат времени. Используйте формат hh:mm',
      ]
      if (this.required) {
        rules.push(v => (!!v) || 'Поле обязательно к заполнению')
      }
      return rules
    },
    validate(show_messages=true) {
      return this.$refs['text_value'].validate(show_messages)
    },
  }
}
</script>

<style scoped>

</style>
