<template>
  <v-container
    class="page-content px-sm-5"
    style="margin: 0px; width: 100%"
  >
    <template>
      <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        flat
        :extended="$vuetify.breakpoint.xsOnly ? true : false"
        elevation="0"
        color="style-color-main-gray"
      >
        <v-text-field
            single-line
            outlined
            dense
            clearable
            :messages="$vuetify.breakpoint.smAndUp ? 'Для поиска нажмите Enter или кнопку поиск' : 'Для поиска нажмите Enter'"
            :class="$vuetify.breakpoint.smAndUp ? 'grow' : ''"
            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
        </v-text-field>
        <v-select
          v-if="$vuetify.breakpoint.mdAndUp"
          class="pt-7 pl-2"
          outlined
          dense
          style="padding-bottom: 2px"
          single-line
          flat
          clearable
          :items="selectors.public"
          v-model="pagination.filters.public"
          label="Фильтр по публичности"
        ></v-select>
        <v-spacer/>
        <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            icon
            class="mr-0 dark-primary"
            @click="openCreateChoiceDialog"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <template v-slot:extension  v-if="$vuetify.breakpoint.smAndDown">
          <v-select
              label="Фильтр по публичности"
              outlined
              dense
              style="padding-bottom: 2px"
              single-line
              flat
              clearable
              hide-details
              :items="selectors.public"
              v-model="pagination.filters.public"
          ></v-select>
        </template>
        <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn
            class="dark-primary rounded-tr-lg"
            text
            @click="openCreateChoiceDialog"
          >
            Создать список
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :search="search.parameter"
        :items-per-page.sync="pagination.size"
        :page.sync="pagination.page"
        :server-items-length="pagination.count"
        :footer-props="pagination.footer_page"
        :loading="pagination.loading"
        class="elevation-1"
      >
        <template v-slot:item.name="{ item }">
          <a style="text-decoration: none" @click="openDetailChoiceDialog(item)">{{item.name}}</a>
        </template>
        <template v-slot:item.public="{ item }">
          <v-icon color="green" v-if="item.public === true">done</v-icon>
          <v-icon color="red" v-else>close</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                medium
                @click="openChangeChoiceDialog(item)"
              >
                edit
              </v-icon>
            </template>
            <span>Редактировать список</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                medium
                @click="openCloneChoiceDialog(item)"
              >
                content_copy
              </v-icon>
            </template>
            <span>Копировать список</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                medium
                @click="openDeleteChoiceDialog(item)"
              >
                delete
              </v-icon>
            </template>
            <span>Удалить список</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Обновить
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <ApplyDialog
      v-if="dialogs.delete"
      :opened="dialogs.delete"
      :get-text="() => `Вы уверены, что хотите удалить список под названием '${dialogs.selected.name}'?`"
      @close="deleteChoiceExecute"
    ></ApplyDialog>
    <slot
      v-if="dialogs.form"
      name="choices-form"
      :opened="dialogs.form"
      :choice-data="dialogs.selected"
      :read-only="dialogs.read_only"
      :clone-id="dialogs.clone_id"
      :close-function="closeChoiceDialogs"
    >
    </slot>
  </v-container>
</template>

<script>
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import {generateListUidToName} from "@/helper/uidToName";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";

export default {
  name: "ChoicesListTemplate",
  mixins: [PaginatedDataMapperMixin],
  components: {ApplyDialog},
  props: {
    loadChoices: {
      default: () => () => {},
      type: Function
    },
    deleteChoice: {
      default: () => () => {},
      type: Function
    }
  },
  data() {
    return {
      page_title: "Списки выбора",
      search: {
        parameter: 'name',
      },
      pagination: {
        filters: {
          public: null,
        }
      },
      expanded: [],
      singleExpand: true,
      selectors: {
        public: [
          {text: 'Публичные', value: true},
          {text: 'Не публичные', value: false}
        ],
      },
      headers: [
        {text: 'Название списка', align: 'start', sortable: false, value: 'name', width: '75%'},
        { text: 'Публичный', value: 'public', sortable: false },
        { text: 'Действия', value: 'actions', sortable: false, align: "end" },
      ],
      items: [],
      dialogs: {
        form: false,
        delete: false,
        selected: undefined,
        read_only: false,
        errors: undefined,
        clone_id: undefined,
      },

    }
  },
  watch: {
    items () {
      this.items.forEach(el => {
        this.$set(el, 'user_fio', '');
      });
      generateListUidToName(this.items, 'uid', 'user_fio');
    },
  },
  methods: {
    loadData(url=undefined, first=false){
      this.setLoading();
      this.loadChoices({
          url: url,
          finalizer: (val, url) => {
            this.mapResults(val, 'items');
            if(first)
              this.setCurrentLoadUrl(url);
            this.setNotLoading();
          },
        }
      )
    },
    getItems (url=undefined) {
      this.loadData(url);
    },
    openDeleteChoiceDialog(item){
      this.dialogs.delete = true
      this.dialogs.selected = item
    },
    openCreateChoiceDialog(){
      this.dialogs.selected = undefined;
      this.dialogs.form = true;
      this.dialogs.read_only = false;
    },
    openChangeChoiceDialog(item){
      this.dialogs.selected = item;
      this.dialogs.form = true;
      this.dialogs.read_only = false;
    },
    openCloneChoiceDialog(item){
      this.dialogs.form = true;
      this.dialogs.clone_id = item.id;
      this.dialogs.read_only = false;
    },
    openDetailChoiceDialog(item){
      this.dialogs.selected = item;
      this.dialogs.form = true;
      this.dialogs.read_only = true;
    },
    deleteChoiceExecute(event){
      if (event){
        this.deleteChoice({
          id: this.dialogs.selected.id,
          finalizer: () => {
            this.closeChoiceDialogs(true)
          },
          catcher:(val) => {
            this.dialogs.errors = val
          },
        })
      } else {
        this.closeChoiceDialogs(false);
      }
    },
    closeChoiceDialogs(reload){
      this.dialogs.selected = undefined;
      this.dialogs.clone_id = undefined;
      this.dialogs.form = false;
      this.dialogs.delete = false;
      this.dialogs.read_only = false;
      if (reload)
        this.reloadData();
    },
  },
  created() {
    this.loadData(undefined, true);
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
