import helper from "./helper";

let getAccountsUrlWithParameters = helper.getAccountsUrlWithParameters
let getSurveyUrlWithParameters = helper.getSurveyUrlWithParameters
let getWorkerUrlWithParameters = helper.getWorkerUrlWithParameters
let getSurveyUrl = helper.getSurveyUrlWithParameters


export default {
    getSurveyUrl,
    SELECTORS: {
        FILE: {
            DOWNLOAD:
                (id, gets = null) => getSurveyUrl(
                    'stage_field_file',
                    "get",
                    {id: id},
                    gets
                ),
        },
        FILE_ANSWER: {
            DOWNLOAD:
                (id, gets = null) => getSurveyUrl(
                    'answer|file',
                    "get",
                    {id: id},
                    gets
                ),
        },
        USERS: {
            ALL: (gets = null) => getSurveyUrlWithParameters(
                "selectors|user",
                'get',
                {},
                gets
            )
        },
        WORKERS: {
            ALL: (gets = null) => getWorkerUrlWithParameters(
                "selectors|employee|worker",
                'get',
                {},
                gets
            )
        },
        ADMIN: {
            FIELDS:
              (gets = null) => getSurveyUrlWithParameters(
                'surveys|admin_survey|selectors|field',
                "get",
                {},
                gets
              ),
            CHOICES:
              (gets = null) => getSurveyUrlWithParameters(
                'surveys|admin_survey|selectors|value_choices',
                "get",
                {},
                gets
              ),
        },
        CREATOR: {
            FIELDS:
              (gets = null) => getSurveyUrlWithParameters(
                'surveys|creator_survey|selectors|field',
                "get",
                {},
                gets
              ),
            CHOICES:
              (gets = null) => getSurveyUrlWithParameters(
                'surveys|creator_survey|selectors|value_choices',
                "get",
                {},
                gets
              ),
        }
    },
}
