import helper from "../helper";
// import user from "../store/modules/user";

let getSurveyUrl = helper.getSurveyUrlWithParameters

export default {
    getSurveyUrl,
    getByRecievedUrlData: (url, gets = null) => getSurveyUrl(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    WATCHER: {
        FILE: {
            UNLODING:
                (id, gets = null) => getSurveyUrl(
                    'surveys|watch_survey|survey-need-unloading',
                    "need_unloading",
                    {id: id},
                    gets
                ),
        },
        SURVEY: {
            LIST:
                (gets = null) => getSurveyUrl(
                    'surveys|watch_survey|survey-list',
                    "list",
                    gets
                ),
            DETAIL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|watch_survey|survey-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
            EXCEL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|watch_survey|survey-get-exl',
                    "get_exl",
                    {id: id},
                    gets
                ),
        },
        STAGE: {
            LIST:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|watch_survey|stage-list',
                    "list",
                    {survey_id: survey_id},
                    gets
                ),
            DETAIL:
                (survey_id, gets = null) => getSurveyUrl(
                    'surveys|watch_survey|stage-list',
                    "retrive",
                    {survey_id: survey_id},
                    gets
                ),
        },
        STAGE_FIELDS: {
            LIST:
                (survey_id, stage_id, gets = null) => getSurveyUrl(
                    'surveys|watch_survey|stage_field-list',
                    "list",
                    {
                        survey_id: survey_id,
                        stage_id: stage_id
                    },
                    gets
                ),
        },
        ATTEMPT: {
            LIST:
                (gets = null) => getSurveyUrl(
                    'attempts|watch_attempt|attempt-list',
                    "list",
                    gets
                ),
            DETAIL:
                (id, gets = null) => getSurveyUrl(
                    'attempts|watch_attempt|attempt-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
            STAGES:
              (attempt_id, gets = null) => getSurveyUrl(
                'attempts|watch_attempt|stage-list',
                "list",
                {attempt_id: attempt_id},
                gets
              ),
            FIELDS:
              (attempt_id, stage_id, gets = null) => getSurveyUrl(
                'attempts|watch_attempt|stage_field-list',
                "list",
                {
                    attempt_id: attempt_id,
                    stage_id: stage_id
                },
                gets
              ),
        },

        STATISTIC: {
            EXCEL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|watch_survey|survey-get-exl',
                    "get_exl",
                    {id: id},
                    gets
                ),
        }
    },
}
