<template>
  <v-container class="page-content px-5" style="  width: 100%" v-if="$route.name === names.FILE.LIST">
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        flat
        color="style-color-main-gray"
    >
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="items"
        :loading="pagination.loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10,25,50,{text:'Все', value:-1}]
        }"
    >
      <template v-slot:item.user="{item}">
        <span @click="openDetail(item)" style="cursor: pointer">
          {{item.user}}
        </span>
      </template>
      <template v-slot:item.created="{item}">
        <span>{{displayDate(item.created,true)}} </span>
      </template>
      <template v-slot:item.file="{item}">
        <a v-if="item.file" :href="item.file" target="_blank">Ссылка на выгрузку</a>
      </template>
      <template v-slot:item.has_error="{item}">
        <v-icon v-if="item.has_error">done</v-icon>
        <v-icon v-else>close</v-icon>
      </template>
      <template v-slot:item.changed="{item}">
        <v-icon v-if="item.changed">done</v-icon>
        <v-icon v-else>close</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="loadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
  <router-view v-else></router-view>
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import names from '@/modules/watcher/routers/names';

import {displayDate} from "@/helper";

export default {
  name: "AdminCarsList",
  components: { },
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],
  data() {
    return {
      page_title: "Список формирующихся файлов",
      items: [],
      headers: [
        {text: 'ФИО', value: 'user', sortable: false, align: 'start', width: '30%'},
        {text: 'Поставлено в очередь', value: 'created', align: 'start', sortable: false, width: '20%'},
        {text: 'Ошибка при формировании', value:"has_error", align: 'start', sortable: false, width: '10%'},
        {text: 'Сформировано', value:"changed", align: 'start', sortable: false, width: '10%'},
        {text: 'Файл', value: 'file', sortable: false, align: 'start', width: '30%'},
      ],
    }
  },
  computed: {
    names: () => names,
  },
  watch: {
  },
  methods: {
    displayDate:displayDate,
    ...mapActions({
      loadFileList: 'admin/loadFileList',
    }),
    openDetail(item){
      this.$router.push({name: names.FILE.DETAIL, params: {idFile: item.id}})
    },
    loadData(url=undefined, first=false){
      this.setLoading();
      this.loadFileList({
        url: url,
        finalizer: (val, url) => {
          this.items = val
          this.setNotLoading();
        }
      })
    },
    getItems (url=undefined) {
      this.loadData(url);
    },
  },
  created() {
      this.getItems()
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
