import {
  sendGetRequest, sendPostRequest, sendDeleteRequest, sendPatchRequest
} from "@/helper/requests";
import surveys from "@/urls/roles/admin";

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
  // Main choice
  loadChoiceList: ({commit}, {url, finalizer, catcher}) => {
    if (!url) url = surveys.ADMIN.CHOICES.LIST()
    sendGetRequest(
      url,
      (data) => finalizer(data, url),
      catcher
    )
  },
  loadChoiceDetail: ({commit, getters}, {id, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICES.DETAIL(id)
    sendGetRequest(url, finalizer, catcher)
  },
  createChoice: ({dispatch, commit}, {data, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICES.SAVE()
    sendPostRequest(url, data, finalizer, catcher)
  },
  updateChoice: ({dispatch, commit}, {id, data, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICES.EDIT(id)
    sendPatchRequest(
      url, data, finalizer, catcher
    )
  },
  deleteChoice: ({dispatch, commit}, {id, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICES.DELETE(id)
    sendDeleteRequest(url, finalizer, catcher)
  },
  // Choice values
  loadChoiceValueList: ({commit}, {value_choices_id, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICE_VALUE.LIST(value_choices_id)
    sendGetRequest(url, finalizer, catcher)
  },
  loadChoiceValueDetail: ({commit, getters}, {value_choices_id, id, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICE_VALUE.DETAIL(value_choices_id, id)
    sendGetRequest(url, finalizer, catcher)
  },
  createChoiceValue: ({dispatch, commit}, {value_choices_id, data, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICE_VALUE.ONE_CREATE(value_choices_id)
    sendPostRequest(url, data, finalizer, catcher)
  },
  changeListOfChoiceValue: ({dispatch, commit}, {value_choices_id, data, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICE_VALUE.MANY_CREATE(value_choices_id)
    sendPostRequest(url, data, finalizer, catcher)
  },
  updateChoiceValue: ({dispatch, commit}, {value_choices_id, data, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICE_VALUE.EDIT(value_choices_id, data.id)
    sendPatchRequest(
      url, data, finalizer, catcher
    )
  },
  deleteChoiceValue: ({dispatch, commit}, {value_choices_id, id, finalizer, catcher}) => {
    let url = surveys.ADMIN.CHOICE_VALUE.DELETE(value_choices_id, id)
    sendDeleteRequest(url, finalizer, catcher)
  },
}


export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
