<template>
  <component
    :is="fieldType.component"
    ref="field_for_validate"
    :label="inside_label"
    :hint="hint"
    :v-mask="fieldType.mask"
    :outlined = "outlined"
    :persistent-hint="persistentHint"
    :rules="[...rules, getRegexRule(fieldType.regex)]"
    :items="items"
    :error="error"
    :error-messages="errorMessages"
    :document_type="document_type"
    :loading="loading"
    :readonly="readonly"
    @download="$emit('download')"
    :downloadable="downloadable"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    v-model="model"
    v-bind="fieldType.attrs"
  >
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
          {{ data.item[itemText]}}
        </div>
      </template>
    </template>
    <template v-slot:label>
      <slot name="label">
        {{ inside_label }}
      </slot>
    </template>
    <template v-slot:append v-if="downloadable && fieldType.hasntAppend ">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              icon
              @click="$emit('download')"
          >
            <v-icon>file_download</v-icon>
          </v-btn>
        </template>
        <span>Загрузить документ</span>
      </v-tooltip>
    </template>
    <template v-slot:append-outer v-if="downloadable ">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            @click="$emit('download')"
          >
            <v-icon>file_download</v-icon>
          </v-btn>
        </template>
        <span>Загрузить документ</span>
      </v-tooltip>
    </template>
  </component>
</template>

<script>

import {TYPES} from "@/helper/field_types";
import SmallSelectField from "@/modules/templates/custom_fields/SmallSelectField";
import DatePicker from "@/modules/templates/custom_fields/DatePicker";
import FileInput from "@/modules/templates/custom_fields/FileInput";
import TimePicker from "@/modules/templates/custom_fields/TimePicker";
import DateWithBounds from "@/modules/templates/custom_fields/DateWithBounds";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import Link from "@/modules/templates/custom_fields/Link";
import coherent from "@/urls/coherent";
import {mapActions, mapGetters} from "vuex";


export default {
  name: "FieldCreate",
  props: {
    type: {
      required: true,
    },
    value: {
      required: true,
    },
    label: String,
    hint: String,
    items: Array,
    document_type: {
      type: String,
      default: '',
    },
    itemText: {
      type: String,
      default: 'value',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    persistentHint: Boolean,
    coherentType: [Number, String],
    rules: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    date_dynamic_lower: Number,
    date_dynamic_upper: Number,
    date_dynamic_value_type: String,
    date_static_lower: String,
    date_static_upper: String,
    is_coherent_large_list: {
      type: Boolean,
      default: true,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [],
  components: {
    SmallSelectField,
    DateWithBounds,
    DatePicker,
    TimePicker,
    LoadingAutocomplete,
    FileInput,
    Link
  },
  data(){
    return {
      model: this.transformFromText(this.value),
      TYPE_FIELDS: {
        [TYPES.NUMBER]: {
          component: 'v-text-field',
          regex: '^[0-9]{0,20}$',
        },
        [TYPES.STRING]: {
          component: 'v-text-field'
        },
        [TYPES.FILE]: {
          component: 'file-input'
        },
        [TYPES.TEXT]: {
          component: 'v-textarea',
        },
        [TYPES.DATE]: {
          component: 'date-picker',
          attrs: {
            dateDynamicLower: () => this.date_dynamic_lower,
            dateDynamicUpper: () => this.date_dynamic_upper,
            dateDynamicValueType: () => this.date_dynamic_value_type,
            dateStaticLower: () => this.date_static_lower,
            dateStaticUpper: () => this.date_static_upper,
          },
        },
        [TYPES.BOOL]: {
          component: 'v-checkbox',
          hasntAppend: true,
        },
        [TYPES.TIME]: {
          component: 'time-picker',
        },
        [TYPES.EMAIL]: {
          component: 'v-text-field',
          regex: '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
        },
        [TYPES.PHONE]: {
          component: 'v-text-field',
          mask: '+7##########'
          // regex: '^[+0-9]{6,15}$',
        },
        [TYPES.MIXER]: {
          component: 'v-slider',
          hasntAppend: true,
          attrs: {
            max: 10,
            'thumb-label': "always",
            'thumb-size': '16',
            class: 'pt-1',
            ticks: "always"
          },
        },
        [TYPES.LINK]: {
          component: 'Link',
          regex: 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)',
        },
        [TYPES.ONE_OF]: {
          component: 'small-select-field',
          attrs: {
            itemText: this.itemText,
            itemValue: this.itemValue,
          },
        },
        [TYPES.MANY_OF]: {
          component: 'small-select-field',
          attrs: {
            itemText: this.itemText,
            itemValue: this.itemValue,
            multiple: true,
          },
        },
        [TYPES.LARGE_ONE_OF]: {
          component: 'v-autocomplete',
          attrs: {
            itemText: this.itemText,
            itemValue: this.itemValue,
          },
        },
        [TYPES.LARGE_MANY_OF]: {
          component: 'v-autocomplete',
          attrs: {
            itemText: this.itemText,
            itemValue: this.itemValue,
            multiple: true,
          },
        },
        [`${TYPES.COHERENT}-true`]: {
          component: 'LoadingAutocomplete',
          attrs: {
            itemText: 'value',
            itemValue: 'value',
            url: () => {
              return coherent.COHERENT(this.coherentType)
            },
            searchParameter: 'search',
            loadFios: false,
            multiple: true,
          }
        },
        [`${TYPES.COHERENT}-false`]: {
          component: 'v-autocomplete',
          items: () => {
            return []
          }
        }

      },
    }
  },
  watch: {
    model(){
      if (this.value !== this.transformed_model)
        this.$emit('input', this.transformed_model)
    },
    value(){
      if (this.value !== this.transformed_model)
        this.model = this.transformed_value
    },
  },
  computed: {
    transformed_value(){
      return this.transformFromText(this.value);
    },
    transformed_model(){
      return this.transformToText(this.model);
    },
    ...mapGetters({
      coherent_list: 'selectors/getCoherentSelector',
    }),
    coherent_name(){
      let coherent = this.coherent_list.find(el => el.value === this.coherentType)
      return coherent ? coherent.text : this.coherentType
    },
    inside_label(){
      if (this.type === TYPES.COHERENT){
        return  `${this.label} (${this.coherent_name})`
      }
      else
        return this.label
    },
    fieldType(){
      if(this.type === TYPES.COHERENT){
        return this.TYPE_FIELDS[`${this.type}-${this.is_coherent_large_list}`]
      }
      return this.TYPE_FIELDS[this.type]
    },
  },
  methods: {
    ...mapActions({
      loadCoherentSelector: 'selectors/loadCoherentSelector'
    }),
    transformFromText(value){
      let transformed_value = value;
      if (transformed_value !== undefined && transformed_value !== null){
        if (transformed_value[0] === '[')
          transformed_value = JSON.parse(transformed_value)
        else if (transformed_value === 'true')
          transformed_value = true
        else if (transformed_value === 'false')
          transformed_value = false
      }
      return transformed_value;
    },
    transformToText(value){
      let transformed_model = value;
      if (transformed_model !== undefined && Array.isArray(transformed_model))
        transformed_model = JSON.stringify(transformed_model);
      else if (transformed_model !== undefined && typeof transformed_model == "boolean")
        transformed_model = `${transformed_model}`;
      return transformed_model
    },
    getRegexRule(regex_string, error_string = 'Значение не соответствует регулярному выражению'){
      if (regex_string !== undefined) {
        return (v) => {
          return (v === '' || v === undefined || v === null || new RegExp(regex_string).test(v)) || error_string
        }
      }
      return (v) => true
    },
    validate(show_messages=true){
      return this.$refs['field_for_validate'].validate(show_messages)
    },
  },
  created() {
    if(this.type === TYPES.COHERENT)
      this.loadCoherentSelector();
  },
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>
