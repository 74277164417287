<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px;
      width: 100%"
      v-if="$route.name === names.SURVEYS.DETAIL"
  >
    <template>
      <v-row justify="center" no-gutters>
        <v-col cols="12" sm="10">
          <v-expansion-panels class="mystyle  rounded-t-lg" accordion focusable v-model="default_panel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row>
                  <v-col cols="2" md="1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            :to="{
                        name: names.SURVEYS.LIST,
                      }"
                        >
                          <v-icon>arrow_back</v-icon>
                        </v-btn>
                      </template>
                      <span>Назад к списку</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="10" md="6" class="pt-5 text-h6 text-center text-sm-left">
                    Информация об опросе
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" md="2" class="pl-11 text-center">
                    <v-tooltip bottom v-if="!survey_detail.draft">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            @click="notify_about_survey = true"
                        >
                          <v-icon>mail</v-icon>
                        </v-btn>
                      </template>
                      <span>Отправить приглашения на опрос</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            :to="{name: names.SURVEYS.EDIT}"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Редактировать опрос</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            @click="openDeleteSurveyDialog(survey_detail)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Удалить опрос</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat class="mx-auto text-left">
                  <v-container>
                    <v-row dense>
                      <v-col cols="12">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>description</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap" v-html="urlify(survey_detail.description)"></v-list-item-title>
                            <v-list-item-subtitle>Описание</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card
                            v-if="loading"
                            class="mx-auto"
                            outlined
                        >
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>person</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{survey_detail.creator_name}}</v-list-item-title>
                              <v-list-item-subtitle>Создатель</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>visibility</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title v-if="anonymity  ">
                                {{anonymity[survey_detail.anonymity].text}}
                              </v-list-item-title>
                              <v-list-item-subtitle>Анонимность</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item >
                            <v-list-item-icon>
                              <v-icon>list</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content >
                              <v-list-item-title v-if="kind">
                                {{kind[survey_detail.kind].text}}
                                <span v-if="kind_param">- {{kind_param}}</span>
                              </v-list-item-title>
                              <v-list-item-subtitle>Доступ</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-tooltip
                                bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="link_status = true"
                                    v-on="on"
                                    fab
                                    small
                                    depressed
                                >
                                  <v-icon>
                                    share
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Ссылка на опрос</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="kind[survey_detail.kind].value === '5'"
                                bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="personal_link = true"
                                    v-on="on"
                                    fab
                                    small
                                    depressed
                                >
                                  <v-icon>
                                    link
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Создать индивидуальную ссылку</span>
                            </v-tooltip>
                            <v-tooltip
                                v-if="kind[survey_detail.kind].value === '5'"
                                bottom
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="personal_links_show = true"
                                    v-on="on"
                                    fab
                                    small
                                    depressed
                                >
                                  <v-icon>
                                    list
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Список индивидуальных ссылок</span>
                            </v-tooltip>
                          </v-list-item>
                          <v-list-item
                              v-if="['6','7','8','9'].indexOf(kind[survey_detail.kind].value) !== -1"
                          >
                            <v-list-item-icon>
                              <v-icon>sort</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-expansion-panels>
                                <v-expansion-panel>
                                  <v-expansion-panel-header>
                                    Параметры доступа
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-list-item
                                        v-for="(kind, kindex) in kind_params"
                                        :key="`kind_${kindex}`"
                                    >
                                      <v-list-item-title>{{kind.title}}</v-list-item-title>
                                    </v-list-item>
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card
                            class="mx-auto text-left"
                            outlined
                        >
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>event</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{survey_detail.publication_date}}</v-list-item-title>
                              <v-list-item-subtitle>Начало</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>event_available</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{survey_detail.end_date}}</v-list-item-title>
                              <v-list-item-subtitle>Окончание</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{survey_detail.draft === true ? 'Черновик' : 'Опубликовано'}}
                              </v-list-item-title>
                              <v-list-item-subtitle>Статус</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
                v-for="(stage, sindex) in stages"
                :key="`stage_${sindex}`"
                @click="loadFields(stage)"
            >
              <v-expansion-panel-header>{{stage.title}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <v-card
                        class="mx-auto text-left"
                        flat
                    >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>bookmark</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span>{{ stage.title }}</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>description</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span v-if="stage.description">{{ stage.description }}</span>
                          <span v-else>Не указано</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                          v-for="(field, findex) in fields"
                          :key="`stage_${findex}`"
                      >
                        <v-list-item-content>
                          <field-detail-template
                            :field="field"
                            @download="fileDownload"
                          >
                          </field-detail-template>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </template>
    <v-dialog
        v-model="link_status"
        width="700"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Ссылка на опрос</span>
          <v-spacer></v-spacer>
          <v-btn
              icon
              fab
              small
              @click="link_status = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-h6" style="text-align: left">
          <v-row no-gutters>
            <a style="word-break: break-all !important;" :href=url_link>{{url_link}}</a>
            <v-spacer></v-spacer>
            <v-btn @click="setClipboard(url_link)" icon>
            <v-icon>content_copy</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PersonalLinkList
        v-if="personal_links_show"
        :opened="personal_links_show"
        :data="personal_links_list"
        @close="closePersonalLinkList"
    ></PersonalLinkList>
    <CreatePersonalLink
        v-if="personal_link"
        :opened="personal_link"
        @close="closeCreatePersonalLink"
    ></CreatePersonalLink>
    <NotifyAboutSurvey
        v-if="notify_about_survey && loading"
        :opened="notify_about_survey"
        :invitation="kind[survey_detail.kind].value === '5'"
        @close="notify_about_survey = false"
        @save="createNotifyAboutSurvey"
    ></NotifyAboutSurvey>
    <ApplyDialog
        v-if="survey_delete"
        :opened="survey_delete"
        :get-text="() => `Вы уверены, что хотите удалить данный опрос ?`"
        @close="deleteSurvey"
    ></ApplyDialog>
    <edit-survey-error-snack-bar
        :error-text="error.text"
        :error-show="error.show"
        @close="this.error.show = false"
    ></edit-survey-error-snack-bar>
  </v-container>
</template>

<script>
import names from "../routers/names";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import {generateUidToName} from "@/helper/uidToName";
import FieldDetailTemplate from "@/modules/templates/survey/FieldDetailTemplate";
import {mapActions, mapGetters} from "vuex";
import survey_names from "@/modules/survey/routers/names"
import selectors from "@/urls/selectors";
import CreatePersonalLink from "../../templates/survey/CreatePersonalLink";
import PersonalLinkList from "@/modules/templates/survey/PersonalLinkList";
import NotifyAboutSurvey from "../../templates/survey/NotifyAboutSurvey";
import EditSurveyErrorSnackBar from "@/modules/templates/survey/edit/EditSurveyErrorSnackBar";
import {addGetParameters} from "@/helper/requests";
import {urlify} from "@/helper";


export default {
  name: "SurveyDetail",
  components: {
    FieldDetailTemplate,
    ApplyDialog,
    CreatePersonalLink,
    PersonalLinkList,
    NotifyAboutSurvey,
    EditSurveyErrorSnackBar,
  },
  mixins: [TitledPageMixin],
  data(){
    return {
      default_panel: 0,
      page_title: "Опрос",
      survey_delete: false,
      select_survey: undefined,
      anonymity: undefined,
      kind: undefined,
      kind_params: undefined,
      loading:false,
      survey_detail: {
        anonymity: " ",
        creator: "",
        description: " ",
        draft: " ",
        end_date: " ",
        freeze: " ",
        id: " ",
        is_freeze: " ",
        kind: " ",
        name: " ",
        publication_date: " ",
        sort: " ",
        uid: " "
      },
      error: {
        show: false,
        text: '',
      },
      stages: [],
      fields: [],
      personal_links_list: [],
      url_link: " ",
      kind_param: "",
      key: null,
      link_status: false,
      personal_link: false,
      personal_links_show: false,
      notify_about_survey: false,
    }
  },
  computed: {
    names: () => names,
  },
  watch: {
    'survey_detail.creator_name' () {
      generateUidToName(this.survey_detail, 'creator', 'creator_name');
    }
  },
  methods: {
    urlify,
    ...mapActions({
      loadSurveyDetail: 'admin/loadSurveyDetail',
      loadStageList: 'admin/loadStageList',
      listStageField: 'admin/listStageField',
      deleteSurveyDetail: 'admin/deleteSurvey',
      loadAnonymitySelector: 'selectors/loadAnonymitySelector',
      loadKindSelector: 'selectors/loadKindSelector',
      personalLinkList: 'admin/personalLinkList',
      personalLinkCreate: 'admin/personalLinkCreate',
      surveySendInvite: 'admin/surveySendInvite',
      surveyListKindParam: 'admin/surveyListKindParam',
    }),
    setClipboard(text){
      navigator.clipboard.writeText(text)
          .then(() => {
            // Получилось!
          })
          .catch(err => {
            console.log('Something went wrong', err);
          });
    },
    showError(text){
      this.error.show = true;
      this.error.text = text;
      setTimeout(() => {this.error.show = false}, 2000)
    },
    initialize() {
      this.loadKindSelector({
        finalizer: (val) => {
          this.kind = val
        }
      })
      this.loadAnonymitySelector({
        finalizer: (val) => {
          this.anonymity = val
        }
      })
      this.surveyListKindParam({
        survey_id: this.$route.params.idSurvey,
        finalizer: (val) => {
          this.kind_params = val
        },
        catcher: (err) => {}
      })
      this.loadSurveyDetail({
            id: this.$route.params.idSurvey,
            finalizer: (val) => {
              if (val) {
                this.page_title = val.name
              }
              this.key = val.special_link_key
              let url = this.$router.resolve({
                name: survey_names.SURVEYS.LINK_DETAIL,
                params: {idSurvey: this.$route.params.idSurvey}
              })
              if (this.key != null){
                this.url_link = this.$router.resolve({
                  path: url.href,
                  query: {key: this.key}
                }).href
              }
              else {
                this.url_link = url.href
              }
              this.url_link = window.location.origin + this.url_link
              this.survey_detail = val
              this.$set(this.survey_detail, 'creator_name', '');
              this.loading = true
            }
          }
      )
      this.loadStageList({
            survey_id: this.$route.params.idSurvey,
            finalizer: (val) => {
              this.stages = val
            }
          }
      )
      this.loadPersonalLinkList()
    },
    openDeleteSurveyDialog(item){
      this.select_survey = item
      this.survey_delete = true
    },
    deleteSurvey(apply){
      if (apply) {
        this.deleteSurveyDetail({
          item: this.select_survey,
          finalizer: (val) => {
            this.$router.push({name: names.SURVEYS.LIST})
            this.survey_delete = false
          },
          catcher: (val) => {
            this.mapErrors(val)
            this.$router.push({name: names.SURVEYS.LIST})
            this.survey_delete = false
          }
        })
      }
      else {
        this.survey_delete = false
      }
    },
    closeCreatePersonalLink(event) {
      if (event) {
        this.personalLinkCreate({
          survey_id: this.$route.params.idSurvey,
          data: {
            user_uid: event.user_uid,
            email: event.email,
            survey: this.$route.params.idSurvey,
          },
          finalizer: (val) => {
            this.loadPersonalLinkList()
          },
          catcher: (error) => {
            this.showError(error.user[0])
          }
        })
      }
      this.personal_link = false
    },
    closePersonalLinkList(){
      this.personal_links_show = false
      this.loadPersonalLinkList()
    },
    loadFields(stage){
      this.listStageField({
            survey_id: this.$route.params.idSurvey,
            stage_id: stage.id,
            finalizer: (val) => {
              this.fields = val
            }
          }
      )
    },
    fileDownload(event){
      let get = {}
      if (event.get_params.key) get['key'] = event.get_params.key
      let url = selectors.SELECTORS.FILE.DOWNLOAD(event.params.id, get)
      if (url) window.open(url,'_blank');
    },
    loadPersonalLinkList(){
      this.personalLinkList({
        survey_id: this.$route.params.idSurvey,
        finalizer: (val) => {
          this.personal_links_list = val
        }
      })
    },
    createNotifyAboutSurvey(event){
      this.surveySendInvite({
        data: event,
        survey_id: this.$route.params.idSurvey,
        finalizer: (val) => {

        }
      })
      this.notify_about_survey = false
    },
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>
