import {ACCOUNTS_INSTANCE, SURVEY_INSTANCE} from "@/helper/consts";

import Breadcrumbs from "../Breadcrumbs";

import names from "./names";

import AttemptList from "../../watcher/attempt/List";
import AttemptDetail from "../../watcher/attempt/Detail"

import SurveyList from "../../watcher/surveys/List";
import Detail from "../surveys/Detail";
import FileList from "@/modules/watcher/file/List.vue";
import FileDetail from "@/modules/watcher/file/Detail.vue";


export default ([
    {
        path: 'watcher',
        name: names.MAIN,
        component: Breadcrumbs,
        meta: {
            breadcrumb: 'Наблюдатель',
            requiredAuth: true,
            instances: [
                SURVEY_INSTANCE,
                ACCOUNTS_INSTANCE
            ]
        },
        children: [
            {
                path: 'file',
                name: names.FILE.LIST,
                component: FileList,
                meta: {
                    breadcrumb: 'Файлы',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                    ]
                },
                children: [
                    {
                        path: ':idFile',
                        name: names.FILE.DETAIL,
                        component: FileDetail,
                        meta: {
                            breadcrumb: 'Файл',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                            ]
                        },
                    },
                ]
            },
            {
                path: 'attempt',
                name: names.ATTEMPTS.LIST,
                component: AttemptList,
                meta: {
                    breadcrumb: 'Попытки',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idAttempt',
                        name: names.ATTEMPTS.DETAIL,
                        component: AttemptDetail,
                        meta: {
                            breadcrumb: 'Просмотр попытки',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE,
                            ]
                        },
                    },
                ]
            },
            {
                path: 'survey',
                name: names.SURVEYS.LIST,
                component: SurveyList,
                meta: {
                    breadcrumb: 'Опросы',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idSurvey',
                        name: names.SURVEYS.DETAIL,
                        component: Detail,
                        meta: {
                            breadcrumb: 'Детальное представление',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    },
                ]
            },
        ]
    },

])


