import helper from "../helper";
// import user from "../store/modules/user";

let getSurveyUrl = helper.getSurveyUrlWithParameters

export default {
    getSurveyUrl,
    getByRecievedUrlData: (url, gets = null) => getSurveyUrl(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    SURVEYING: {
        SURVEY: {
            LIST:
                (gets = null) => getSurveyUrl(
                    'surveys|surveying_survey|survey-list',
                    "list",
                    gets
                ),
            DETAIL:
                (id, gets = null) => getSurveyUrl(
                    'surveys|surveying_survey|survey-detail',
                    "retrieve",
                    {id: id},
                    gets
                ),
        },
        ATTEMPT: {
            LIST:
                (gets = null) => getSurveyUrl(
                    'attempts|surveying_attempt|attempt-list',
                    "list",
                    gets
                ),
            CREATE:
                (key, gets = null) => getSurveyUrl(
                  'attempts|surveying_attempt|attempt-list',
                  "create",
                    {key: key},
                  gets
            ),
            CHECK:
                (id, gets = null) => getSurveyUrl(
                    'surveys|surveying_survey|survey-attempt-check',
                    "attempt_check",
                    {id: id},
                    gets
                ),
            END:
                (id, gets = null) => getSurveyUrl(
                    'attempts|surveying_attempt|attempt-attempt-end',
                    "attempt_end",
                    {id: id},
                    gets
                ),
            STAGE_LIST:
                (attempt_id, gets = null) => getSurveyUrl(
                    'attempts|surveying_attempt|stage-list',
                    "list",
                    {attempt_id: attempt_id},
                    gets
                ),
            FIELD_LIST:
                (attempt_id, stage_id, gets = null) => getSurveyUrl(
                    'attempts|surveying_attempt|stage_field-list',
                    "list",
                    {
                        attempt_id: attempt_id,
                        stage_id: stage_id
                    },
                    gets
                ),
            STAGE_SAVE:
                (attempt_id, stage_id, gets = null) => getSurveyUrl(
                    'attempts|surveying_attempt|stage-stage-answers-save',
                    "stage_answers_save",
                    {
                        attempt_id: attempt_id,
                        id: stage_id
                    },
                    gets
                ),
            ANSWER_LIST:
                (attempt_id, stage_id, gets = null) => getSurveyUrl(
                    'attempts|surveying_attempt|attempt_answer-list',
                    "list",
                    {
                        attempt_id: attempt_id,
                        stage_id: stage_id
                    },
                    gets
                ),
        }
    },
}
