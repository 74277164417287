<template>
  <scroll-center-dialog v-model="opened"
                        :loading="saveData"
                        @close="closeDialog" @apply="applyDialog" title="Добавление этапа">
    <stage-form-template v-model="data" :form_errors="errors" ref="stage_form"></stage-form-template>
  </scroll-center-dialog>
</template>

<script>
import StageFormTemplate from "@/modules/templates/survey/StageFormTemplate";
import {mapActions} from "vuex";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog.vue";

export default {
  name: "AddStage",
  components: {ScrollCenterDialog, StageFormTemplate},
  props: {
    opened: Boolean,
  },
  data() {
    return {
      saveData:false,
      data: {
        title: '',
        description: '',
        image: undefined,
      },
      errors: {},
      url: undefined,
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      createStage: 'creator/saveStage'
    }),
    preview_image() {
      if (this.data.image)
        this.url= URL.createObjectURL(this.data.image)
      else
        this.url = undefined
    },
    closeDialog: function () {
      this.$emit('close', false)
    },
    applyDialog: function () {
      if(this.$refs.stage_form.validate()){
        this.saveData = true
        this.createStage({
          data: this.data,
          survey_id: this.$route.params.idSurvey,
          finalizer: () => {
            this.$emit('close', true);
          },
          catcher: (errors) => {
            this.saveData = false
            this.errors = errors
          }
        })
      }
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>
