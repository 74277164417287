const state = {
    title: 'Профиль пользователя',
    disable_breadcrumbs: true,
}
const mutations = {
    changeTitle: function (state, title) {
        state.title = title;
    },
    showBreadcrubs: function (state, show) {
        state.disable_breadcrumbs = show;
    }
}
const getters = {
    getStatusBreadcrumbs: state => state.disable_breadcrumbs,
}
export default {
    namespaced: true,
    state,
    mutations,
    getters
}
