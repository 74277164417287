<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        fab
        :style="`right: ${right}px; bottom: ${bottom}px`"
        fixed
        @click="$emit('click')"
        v-bind="attrs" v-on="on"
        :color="color"
        :disabled="disableBtn"
      >
        <v-icon>{{icon}}</v-icon>
      </v-btn>
    </template>
    <slot name="tooltip">{{ tooltip }}</slot>
  </v-tooltip>
</template>

<script>
export default {
  name: "EditSurveyFabButton",
  props: {
    color: String,
    icon: String,
    tooltip: String,
    right: String,
    bottom: String,
    disableBtn:{
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
