<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px;
      width: 100%"
      v-if="$route.name === names.ATTEMPTS.DETAIL"
  >
    <AttemptView
        v-if="attempt_load"
        :attempt_data="attempt_data"
        :attempt_view="false"
        :readonly="true"
        load-attempt-stage-fields-store-function-name="admin/loadAttemptStageFieldList"
        @close="goToList"
        @download="downloadFieldFile"
    ></AttemptView>
  </v-container>
</template>

<script>
import names from "../routers/names";
import TitledPageMixin from "../../../mixins/TitledPageMixin";
import AttemptView from "../../templates/AttemptView";
import router from "../../../router";
import {mapActions} from "vuex";
import helper from "@/urls/helper";


export default {
  mixins: [TitledPageMixin],
  components: {AttemptView},
  name: "AttemptDetail",
  data() {
    return {
      page_title: "Просмотр попытки",
      attempt_data: undefined,
      attempt_load: false,
    }
  },
  computed: {
    names: () => names,
  },
  methods: {
    ...mapActions({
      loadAttemptDetail: 'admin/loadAttemptDetail',
      loadAttemptStageList: 'admin/loadAttemptStageList',
    }),
    initialize() {
      this.loadAttemptDetail({
        id: this.$route.params.idAttempt,
        finalizer: (val) => {
          this.attempt_data = val
          this.page_title = val.survey.name
          this.loadAttemptStageList({
            id: val.id,
            finalizer: (el) => {
              this.attempt_data.survey.stages = el
              this.attempt_load = true
            }
          })
        }
      });
    },
    downloadFieldFile(field){
      let get = {}
      if (field.get_params.key)
        get['key'] = field.get_params.key
      let url = helper.getSurveyUrlWithParameters(field.name,field.action,{id: field.params.id}, get)
      if (url) window.open(url,'_blank');
    },
    goToList() {
      router.push({
        name: names.ATTEMPTS.LIST,
      })
    },
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>

</style>
