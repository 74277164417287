<template>
  <scroll-center-dialog v-model="opened" @close="close" @apply="save" :title="form_title" content-height="400px" :applyable="!readOnly">
    <v-alert v-show="!readOnly" type="warning" class="mt-2 mb-2 caption py-1" outlined>
      Обратите внимание, что все изменения, не вызывающие ошибок, применяются после нажатия кнопки сохранить.
    </v-alert>
    <v-textarea
      class="pt-2"
      v-model="name"
      label="Название списка"
      hint="Введите сюда название которое будет отображаться в списках выбора"
      persistent-hint
      outlined
      :readonly="readOnly"
      rows="1"
      auto-grow
      tabindex="0"
      :error="hasError('name')"
      :error-messages="getError('name')"
      @input="deleteError('name')"
    >
    </v-textarea>
    <v-card
      flat
      class="overflow-y-auto"
    >
      <v-card-text>
        <v-row
          v-for="(choice_value, index) in values"
          :key="`choice_${index}`"
          dense
        >
          <v-col cols="12">
            <choice-value-form-row-template
              :key="`choice_field_${index}`"
              ref="variant"
              :index="index"
              v-model="choice_value.value"
              :read-only="readOnly"
              :field-errors="values_errors[index]"
              @delete="deleteChoiceValue"
            >
            </choice-value-form-row-template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <template v-slot:prepend-actions v-if="!readOnly">
      <v-tooltip top>
        <template v-slot:activator="{on}">
          <v-btn icon depressed @click="addChoiceValue" class="mr-2" v-on="on">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>Добавить дополнительное значение</span>
      </v-tooltip>
      <span>Всего значений: {{values.length}}</span>
      <v-spacer></v-spacer>
    </template>
    <v-checkbox v-model="publ"
                :error="hasError('public')"
                :error-messages="getError('public')"
                @input="deleteError('public')"
                label="Публичность списка"
    >
    </v-checkbox>
  </scroll-center-dialog>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";
import ChoiceValueFormRowTemplate from "@/modules/templates/choices/ChoiceValueFormRowTemplate";

export default {
  name: "ChoicesFormTemplate",
  components: {
    ScrollCenterDialog,
    ChoiceValueFormRowTemplate
  },
  mixins: [
    FormErrorsMixin,
  ],
  props: {
    opened: Boolean,
    public: {
      default: true
    },
    readOnly: Boolean,
    choiceId: {},
    cloneId: {},
    choiceName: {},
    loadChoiceValues: {
      default: () => () => {},
      type: Function
    },
    createChoiceList: {
      default: () => () => {},
      type: Function
    },
    updateChoiceList: {
      default: () => () => {},
      type: Function
    },
    changeListOfChoiceValue: {
      default: () => () => {},
      type: Function
    },
  },
  data() {
    return {
      name: this.choiceName,
      publ:this.public,
      base_values: [],
      values: [
        {value: ""},{value: ""},{value: ""}
      ],
      values_errors: [

      ]
    }
  },
  watch: {
    choiceId(){
      if (this.choiceId !== undefined)
        this.loadChoices(this.choiceId)
    },
    choiceName(){
      this.name = this.choiceName;
    },
    choicePublic(){
      this.publ = this.public;
    },
  },
  computed: {
    form_title () {
      return this.choiceId === undefined ? "Создание списка" : this.readOnly ? 'Просмотр списка' : "Редактирование списка"
    },
  },
  methods: {
    getCatcher(){
      return (val) => {
        this.mapErrors(val)
        if (val.hasOwnProperty('choices')) {
          this.values_errors = val.choices;
        }
      }
    },
    getFinalizer(success=false){
      return () => {
        this.clear();
        this.$emit('close', success)
      }
    },
    loadChoices(id){
      this.loadChoiceValues({
        value_choices_id: id,
        finalizer: (val) => {
          this.values = val.map(el => {return {...el}})
          this.base_values = val
        },
        catcher: this.catcher
      })
    },
    addChoiceValue(){
      this.values.push({value: ''})
      this.$nextTick(() => {
        this.$refs.variant[this.$refs.variant.length - 1].$el.scrollIntoView();
      })
    },
    deleteChoiceValue(index){
      this.values.splice(index, 1)
      if (this.values_errors.length > index)
        this.values_errors.splice(index, 1)
    },
    clear(){
      this.name = '';
      this.values_errors = [];
      this.base_values = [];
      this.values = [
        {value: ""},{value: ""},{value: ""}
      ];
    },
    close(){
      this.clear();
      this.$emit('close', false)
    },
    save(){
      if (this.choiceId !== undefined){
        this.update()
      } else {
        this.create()
      }
    },
    create(){
      this.createChoiceList({
        data: {
          name: this.name,
          public: this.publ,
          choices: [...this.values.filter(el => el.value.trim() !== '').map(el => el.value)]
        },
        finalizer: this.getFinalizer(true),
        catcher: this.getCatcher()
      })
    },
    update(){
      if ((this.name !== this.choiceName) || (this.public !== this.publ)){
        this.updateChoiceList({
          id: this.choiceId,
          data: {
            name: this.name,
            public: this.publ,
          },
          finalizer: () => {}
        })
      }
      let created = [...this.values.filter(el => el.id === undefined && el.value.trim() !== '')];
      let deleted = [...this.base_values.filter(el => this.values.find(fel => el.id === fel.id) === undefined).map(el => el.id)];
      let updated = [...this.values.filter(el => created.indexOf(el) === -1 && deleted.indexOf(el) === -1)];
      updated = updated.filter(el => this.base_values.find(fel => fel.id === el.id && fel.value !== el.value) !== undefined);
      this.changeListOfChoiceValue({
        value_choices_id: this.choiceId,
        data: {
          created: created,
          deleted: deleted,
          updated: updated
        },
        finalizer: this.getFinalizer(true),
        catcher: (val) => {
          this.values_errors = [];
          for (let el of this.values) {
            let pushErrorsByObject = (list, errors, checker='id') => {
              let find_index = list.indexOf(el)
              if (find_index !== -1){
                this.values_errors.push(errors[find_index])
                return true
              }
              else
                return false
            }
            let pushed = pushErrorsByObject(created, val.created);
            pushed = pushed ? pushed : pushErrorsByObject(updated, val.updated);
            pushed = pushed ? pushed : pushErrorsByObject(deleted, val.deleted);
            if (!pushed) this.values_errors.push(undefined)
          }
        }
      })
    },
  },
  created() {
    if (this.choiceId !== undefined) {
      this.loadChoices(this.choiceId);
    } else if (this.cloneId !== undefined) {
      this.loadChoices(this.cloneId);
    }
  }
}
</script>

<style scoped>

</style>
