<template>
  <choices-form-template
    :opened="opened"
    :read-only="readOnly"
    :choice-id="choiceData !== undefined ? choiceData.id: undefined"
    :choice-name="choiceData !== undefined ? choiceData.name: undefined"
    :clone-id="cloneId"
    :public="choiceData !== undefined ? choiceData.public: false"
    :create-choice-list="createChoiceList"
    :load-choice-values="loadChoiceValues"
    :update-choice-list="updateChoice"
    :change-list-of-choice-value="changeListOfChoiceValue"
    @close="close"
  ></choices-form-template>
</template>

<script>

import ChoicesFormTemplate from "@/modules/templates/choices/ChoicesFormTemplate";
import {mapActions} from "vuex";


export default {
  name: "ChoiceCreate",
  components: {
    ChoicesFormTemplate
  },
  props: {
    opened: Boolean,
    readOnly: Boolean,
    choiceData: {
      default: undefined,
      type: Object,
    },
    cloneId: {
      default: undefined,
      type: Number,
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    ...mapActions({
      loadChoiceValues: 'creator/loadChoiceValueList',
      changeListOfChoiceValue: 'creator/changeListOfChoiceValue',
      createChoiceList: 'creator/createChoice',
      updateChoice: 'creator/updateChoice',
      updateChoiceValue: 'creator/updateChoiceValue',
    }),
    close(event){
      this.$emit('close', event)
    },
  },

}
</script>

<style scoped>

</style>
