<template>
    <v-row no-gutters>
      <v-file-input
          ref="input_value"
          v-model="model"
          :label="label"
          :accept="getAccept()"
          :error="error"
          clearable
          :disabled="readonly"
          :outlined="outlined"
          :error-messages="errorMessages"
          :hint="hint"
          :persistent-hint="persistentHint"
          @focus="$emit('focus', $event)"
          @input="$emit('input', $event)"
          :loading="loading"
          :rules="getRules()"
          style="width: 100%"
      >
        <template v-slot:label>
          <p style="font-size: 18px; margin-bottom: 3px">
            {{label}}<span v-if="required" style="color: red">*</span>
          </p>
        </template>
        <template v-slot:append-outer v-if="downloadable">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click="$emit('download')"
              >
                <v-icon>file_download</v-icon>
              </v-btn>
            </template>
            <span>Загрузить документ</span>
          </v-tooltip>
        </template>
        <template v-slot:append  v-if="model">
          <v-tooltip bottom  v-if="model.file">
            <template v-slot:activator="{ on }">
              <v-icon
                  v-on="on"
                  medium
                  @click="getLink(model.file)"
              >
                cloud_download
              </v-icon>
            </template>
            <span>Скачать файл</span>
          </v-tooltip>
        </template>
      </v-file-input>
    </v-row>
</template>

<script>

import selectors from "@/urls/selectors";

export default {
  name: "FileInput",

  props: {
    value: {
      required: true,
    },
    document_type: {
      type: String,
      default: '',
    },
    label: String,
    hint: String,
    persistentHint: Boolean,
    downloadable: {
      type: Boolean,
      default: false,
    },
    outlined:{
      default: true,
      type: Boolean,
      required: false
    },
    rules: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      model: this.value,
    }
  },
  watch: {
    model(){
      // if (JSON.stringify(this.value) !== JSON.stringify(this.model))
        this.$emit('input', this.model)
    },
    value(){
      // if (JSON.stringify(this.value) !== JSON.stringify(this.model))
        this.model = this.value
    },
  },
  methods: {
    getAccept(){
      if (this.document_type === "excel")
      return 'application/vnd.ms-excel,' +
          ' application/msexcel' +
          ', application/x-msexcel'+
          ', application/x-ms-excel,' +
          ' application/xls,' +
          ' application/x-xls,'+
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (this.document_type === "word")
        return 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      if (this.document_type === "image")
        return 'image/png, image/jpeg'
      if (this.document_type === "application/pdf")
        return 'application/pdf'
      if (this.document_type === "archive")
        return 'application/x-bzip, ' +
            'application/x-bzip2,' +
            'application/zip,' +
            'application/x-7z-compressed,' +
            'application/vnd.rar,' +
            'application/rar,' +
            'application/x-rar-compressed,'+
            'application/octet-stream'
      return ''
    },
    getLink(field){
        let get = {}
        if (field.get_params.key) get['key'] = field.get_params.key
        let url = selectors.SELECTORS.FILE_ANSWER.DOWNLOAD(field.params.id, get)
        if (url) window.open(url,'_blank');
    },
    validate(show_messages=true) {
      return this.$refs['input_value'].validate(show_messages)
    },
    getRules(){
      let rules = [
        ...this.rules,
      ]
      if (this.required) {
        rules.push(v => (!!v) || 'Поле обязательно к заполнению')
      }
      return rules
    },
  }
}
</script>

<style scoped>

</style>
