const WATCHER = {
    BASE: 'Watcher',
    MAIN: undefined,
    ATTEMPTS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    SURVEYS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    FILE: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    STATISTICS: {
        _BASE: undefined,
        MAIN: undefined,
    }
};

WATCHER.MAIN = `${WATCHER.BASE}|Main`

WATCHER.ATTEMPTS._BASE = `${WATCHER.BASE}|Attempt`
WATCHER.ATTEMPTS.LIST = `${WATCHER.ATTEMPTS._BASE}|List`
WATCHER.ATTEMPTS.DETAIL = `${WATCHER.ATTEMPTS._BASE}|Detail`

WATCHER.STATISTICS._BASE = `${WATCHER.BASE}|Statistics`
WATCHER.STATISTICS.MAIN = `${WATCHER.STATISTICS._BASE}|Main`

WATCHER.SURVEYS._BASE = `${WATCHER.BASE}|Surveys`
WATCHER.SURVEYS.LIST = `${WATCHER.SURVEYS._BASE}|List`
WATCHER.SURVEYS.DETAIL = `${WATCHER.SURVEYS._BASE}|Detail`

WATCHER.FILE._BASE = `${WATCHER.BASE}|File`
WATCHER.FILE.LIST = `${WATCHER.FILE._BASE}|List`
WATCHER.FILE.DETAIL = `${WATCHER.FILE._BASE}|Detail`

export default {
    ...WATCHER
}
