<template>
  <v-container class="page-content px-sm-5" style="margin: 0px; width: 100%">
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        flat
        elevation="0"
        color="style-color-main-gray"
    >
      <v-text-field
          single-line
          outlined
          dense
          clearable
          :messages="$vuetify.breakpoint.smAndUp ? 'Для поиска нажмите Enter или кнопку поиск' : 'Для поиска нажмите Enter'"
          :class="$vuetify.breakpoint.smAndUp ? 'grow' : ''"
          v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
      </v-text-field>
      <v-spacer/>
      <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          icon
          class="mr-0 dark-primary"
          :to="{name: names.SURVEYS.CREATE}"
      >
        <v-icon>add</v-icon>
      </v-btn>
      <v-btn
          icon
          class="mr-0 dark-primary"
          @click="show = !show"
      >
        <v-icon>{{ show ? 'expand_less' : 'expand_more' }}</v-icon>
      </v-btn>
      <v-toolbar-items class="pa-2" v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
            class="dark-primary rounded-tr-lg"
            depressed

            text
            :to="{name: names.SURVEYS.CREATE}"
        >
          Создать опрос
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-toolbar color="#DADADA" v-if="show" elevation="0" :height="extensionHeight">
      <v-row no-gutters >
        <v-col cols="12" md="3" class="px-0">
          <v-select
              class="pt-1 pr-1 pl-0  "
              outlined
              dense
              style="padding-bottom: 2px"
              single-line
              flat
              clearable
              hide-details
              :items="selectors.by_date"
              v-model="pagination.filters.by_date"
              label="Фильтр по дате"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
              class="pt-1 pr-1"
              outlined
              dense
              style="padding-bottom: 2px"
              single-line
              flat
              clearable
              hide-details
              :items="anonymity_selector"
              v-model="pagination.filters.anonymity"
              label="Фильтр по анонимности"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
              class="pt-1 pr-1"
              outlined
              dense
              style="padding-bottom: 2px"
              single-line
              flat
              clearable
              hide-details
              :items="kind_selector"
              v-model="pagination.filters.kind"
              label="Фильтр по доступу"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
              class="pt-1 pr-1"
              outlined
              dense
              style="padding-bottom: 2px"
              single-line
              hide-details
              flat
              clearable
              :items="selectors.draft"
              v-model="pagination.filters.draft"
              label="Фильтр по опубликованности"
          ></v-select>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-data-table
        :loading="pagination.loading"
        :headers="headers"
        :items="items"
        no-data-text="Опросы не загружены"
        no-results-text="Опросы не были найдены"
        :items-per-page.sync="pagination.size"
        :page.sync="pagination.page"
        :server-items-length="pagination.count"
        :footer-props="{'items-per-page-options':[10,25,50]}"
        class="elevation-1"
    >
      <template v-slot:item.name="{ item }">
        <router-link
            style="text-decoration: none"
            :to="{
                    name: names.SURVEYS.DETAIL,
                    params: {idSurvey: item.id}
                  }">
          {{item.name}}
        </router-link>
      </template>
      <template v-slot:item.publication_date="{ item }">{{item.publication_date ? displayDate(item.publication_date) : 'Не указано'}}</template>
      <template v-slot:item.end_date="{ item }">{{item.end_date ? displayDate(item.end_date) : 'Не указано'}}</template>
      <template v-slot:item.anonymity="{ item }">
        {{getSelectorText(base_anonymity_selector, item.anonymity)}}
      </template>
      <template v-slot:item.kind="{ item }">
        {{getSelectorText(kind_selector, item.kind)}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                icon
                :to="{
                  name: names.SURVEYS.EDIT,
                  params: {idSurvey: item.id}
                }"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <span>Редактировать опрос</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!item.draft">
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                medium
                @click="openDuplicateSurveyDialog(item)"
            >
              content_copy
            </v-icon>
          </template>
          <span>Копировать опрос</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                medium
                @click="openDeleteSurveyDialog(item)"
            >
              delete
            </v-icon>
          </template>
          <span>Удалить опрос</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!item.draft">
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                @click="openAttempSurveyDialog(item)"
                medium
            >
              sticky_note_2
            </v-icon>
          </template>
          <span>Загрузить ответы</span>
        </v-tooltip>
        <v-tooltip bottom  >
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                @click="openPreview(item)"
                medium
            >
              preview
            </v-icon>
          </template>
          <span>Предпросмотр опроса</span>
        </v-tooltip>
      </template>
      <template v-slot:item.draft="{ item }">
        <v-icon :color="getColorDraft(item)" v-if="item.draft === false">done</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <AttempLoadXls
        v-if="survey_load_attemp"
        :opened="survey_load_attemp"
        :survey="select_survey"
        @close="attemptSurvey"
    ></AttempLoadXls>
    <ApplyDialog
        v-if="survey_delete"
        :opened="survey_delete"
        :get-text="() => `Вы уверены, что хотите удалить данный опрос ?`"
        @close="deleteSurvey"
    ></ApplyDialog>
    <ApplyDialog
        v-if="survey_duplicate"
        :opened="survey_duplicate"
        :get-text="() => `Копировать данный опрос ?`"
        @close="duplicateSurveyDetail"
    ></ApplyDialog>
    <MsgDialog
        v-if="close_info_flag"
        :opened="close_info_flag"
        :error="true"
        :title="'Невозможно удалить опубликованный опрос.'"
        :text-btn="'Закрыть'"
        @close="close_info_flag = false"
    >
    </MsgDialog>
  </v-container>
</template>

<script>

import {mapActions, mapGetters, } from "vuex";
import {generateListUidToName} from "@/helper/uidToName";
import {loadData, makeGetRequest} from "@/helper/requests";

import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import MsgDialog from "../../core/components/MsgDialog";

import names from "../routers/names";
import creator from "../../../urls/roles/creator";
import {displayDate} from "@/helper";
import AttempLoadXls from "@/modules/templates/AttempLoadXls.vue";


export default {
  name: "SurveyList",
  components: {ApplyDialog, MsgDialog, AttempLoadXls},
  mixins: [TitledPageMixin, PaginatedDataMapperMixin],

  data(){
    return {
      page_title: "Список опросов",
      show: false,
      close_info_flag: false,
      search: {
        parameter: 'name',
      },
      pagination: {
        filters: {
          draft: null,
          kind: null,
          anonymity: null,
          by_date: null,
        }
      },
      selectors: {
        draft: [
          {text:'Опубликовано',value: 'false'},
          {text:'Не опубликовано',value: 'true'},
        ],
        by_date: [
            {text:'Текущие',value: 'true'},
            {text:'Завершённые',value: 'false'},
        ],
      },
      reveal: false,
      expanded: [],
      singleExpand: true,
      headers: [
        {
          text: 'Название опроса',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '20%'
        },
        { text: 'Дата начала', value: 'publication_date', sortable: false, },
        { text: 'Дата окончания', value: 'end_date', sortable: false},
        // { text: 'Создатель', value: 'creator_name', width: '25%'},
        { text: 'Анонимность', value: 'anonymity', width: '16%', sortable: false},
        { text: 'Доступ', value: 'kind', width: '8%', sortable: false},
        { text: 'Опубликовано', value: 'draft', width: '14%', sortable: false},
        { text: 'Действия', value: 'actions', sortable: false, width: '15%'},
        // { text: 'Подробнее', value: 'data-table-expand', width: '5%'},
      ],
      survey_delete: false,
      survey_duplicate: false,
      select_survey: undefined,
      survey_load_attemp: false,
      items: [],
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      base_anonymity_selector: 'selectors/getAnonymitySelector',
      base_kind_selector: 'selectors/getKindSelector'
    }),
    anonymity_selector(){
      return [ ...this.base_anonymity_selector]
    },
    kind_selector(){
      return [ ...this.base_kind_selector]
    },
    extensionHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 210
      } else {
        return 0
      }
    }
  },
  watch: {
    items () {
      this.items.forEach(el => {
        this.$set(el, 'creator_name', '');
      });
      generateListUidToName(this.items, 'creator', 'creator_name');
    }
  },
  methods: {
    ...mapActions({
      loadSurveyList: 'creator/loadSurveyList',
      deleteSurveyDetail: 'creator/deleteSurveyDetail',
      duplicateSurvey: 'creator/duplicateSurvey',
      loadKindSelector: 'selectors/loadKindSelector',
      loadAnonymitySelector: 'selectors/loadAnonymitySelector',
      loadStatisticFile: 'creator/loadStatisticFile',
    }),
    displayDate: displayDate,
    openAttempSurveyDialog(item){
      this.select_survey = item
      this.survey_load_attemp = true
    },
    attemptSurvey(apply){
      if (apply) {
        this.loadingFile(this.select_survey,apply)
        this.survey_load_attemp = false
        this.select_survey = undefined
      } else {
        this.survey_load_attemp = false
        this.select_survey = undefined
      }
    },
    getColorDraft(item){
      if (new Date() > new Date(item.end_date + 'T20:59:00Z'))
        return 'orange'
      return 'green'
    },
    getSelectorText(selector, value){
      let finded = selector.find(el => el.value === value)
      return finded ? finded.text : value
    },
    initialize() {
      this.loadKindSelector();
      this.loadAnonymitySelector();
      this.loadData(undefined, true);
    },
    loadData(url=undefined, first=false){
      this.setLoading();
      this.loadSurveyList({
        url: url,
        finalizer: (val, url) => {
          this.setNotLoading();
          this.mapPagination(val)
          if(first)
            this.setCurrentLoadUrl(url);
          this.mapResults(val, 'items')
        }
      })
    },
    getItems (url=undefined) {
      loadData(url);
    },
    openPreview(item){
      this.$router.push({name: names.SURVEYS.PREVIEW, params: {idSurvey: item.id}})
    },
    loadingFile(item,get){
      this.loadStatisticFile({
        id: item.id,
        get:get,
        finalizer: (data) => {
          this.$router.push({name: names.FILE.DETAIL, params: {idFile: data.unloading}})
        },
        catcher: () => {
          alert("Ошибка");
        },
      })
    },
    openDuplicateSurveyDialog(item){
      this.select_survey = item
      this.survey_duplicate = true
    },
    closeDuplicateSurveyDialog(){
      this.select_survey = undefined
      this.survey_duplicate = false
    },
    routeToEditPage(id){
      this.$router.push({
        name: names.SURVEYS.EDIT,
        params: {idSurvey: id}
      })
    },
    duplicateSurveyDetail(apply){
      if (apply) {
        this.duplicateSurvey({
          id: this.select_survey.id,
          finalizer: (data) => {
            this.closeDuplicateSurveyDialog();
            this.routeToEditPage(data.survey)
          }
        })
      } else {
        this.closeDuplicateSurveyDialog();
      }
    },
    openDeleteSurveyDialog(item){
      this.select_survey = item
      this.survey_delete = true
    },
    deleteSurvey(apply){
      if (apply) {
        this.deleteSurveyDetail({
          item: this.select_survey,
          finalizer: () => {
            this.initialize()
          },
          catcher: (val) => {
            if (val.value_choices) {
              this.close_info_flag = true
            }
          },
        })
      }
      this.survey_delete = false
    },
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
