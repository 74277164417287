import {ACCOUNTS_INSTANCE, SURVEY_INSTANCE,ORGANIZATION_INSTANCE} from "@/helper/consts";


import Breadcrumbs from "@/modules/administrative/Breadcrumbs";

import SurveyBreadcrumbs from "../survey/SurveyBreadcrumbs";
import SurveyDetail from "../survey/Detail";
import SurveyCreate from "../survey/Create";
import SurveyEdit from "../survey/Edit";

import UserBreadcrumbs from "../user/UserBreadcrumbs";

import ChoiceBreadcrumbs from "../choice/ChoiceBreadcrumbs";

import StageBreadcrumbs from "../stage/StageBreadcrumbs";
import StageList from "../stage/List";

import FiledList from "../field/List";
import FieldDetail from "../field/Detail";

import AttemptList from "../attempt/List";
import AttemptDetail from "../attempt/Detail"

import FileList from "../file/List";
import FileDetail from "../file/Detail"

import names from "@/modules/administrative/routers/names";


export default ([
    {
        path: 'administrative',
        name: names.MAIN,
        component: Breadcrumbs,
        meta: {
            breadcrumb: 'Административный работник',
            requiredAuth: true,
            instances: [
                SURVEY_INSTANCE,
                ACCOUNTS_INSTANCE
            ]
        },
        children: [
            {
                path: 'survey',
                name: names.SURVEYS.LIST,
                component: SurveyBreadcrumbs,
                meta: {
                    breadcrumb: 'Опросы',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: 'create',
                        name: names.SURVEYS.CREATE,
                        component: SurveyCreate,
                        meta: {
                            breadcrumb: 'Создание',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    },
                    {
                        path: ':idSurvey/edit',
                        name: names.SURVEYS.EDIT,
                        component: SurveyEdit,
                        meta: {
                            breadcrumb: 'Редактирование',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    },
                    {
                        path: ':idSurvey',
                        name: names.SURVEYS.DETAIL,
                        component: SurveyDetail,
                        meta: {
                            breadcrumb: 'Детальное представление',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                        children: [
                            {
                                path: 'stage/list',
                                name: names.SURVEYS.STAGE.LIST,
                                component: SurveyBreadcrumbs,
                                meta: {
                                    breadcrumb: 'Этапы',
                                    requiredAuth: true,
                                    instances: [
                                        SURVEY_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                            },
                            {
                                path: 'stage/:idStage',
                                name: names.SURVEYS.STAGE.DETAIL,
                                component: undefined,
                                meta: {
                                    breadcrumb: 'Детальное представление',
                                    requiredAuth: true,
                                    instances: [
                                        SURVEY_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                                children: [
                                    {
                                        path: 'field/list',
                                        name: names.SURVEYS.FIELD.LIST,
                                        component: undefined,
                                        meta: {
                                            breadcrumb: 'Поля',
                                            requiredAuth: true,
                                            instances: [
                                                SURVEY_INSTANCE,
                                                ACCOUNTS_INSTANCE
                                            ]
                                        },
                                    },
                                    {
                                        path: 'field/:idField',
                                        name: names.SURVEYS.FIELD.DETAIL,
                                        component: undefined,
                                        meta: {
                                            breadcrumb: 'Детальное представление',
                                            requiredAuth: true,
                                            instances: [
                                                SURVEY_INSTANCE,
                                                ACCOUNTS_INSTANCE
                                            ]
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ]
            },
            {
                path: 'user',
                name: names.USERS.LIST,
                component: UserBreadcrumbs,
                meta: {
                    breadcrumb: 'Пользователи',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE,
                        ORGANIZATION_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idUser',
                        name: names.USERS.DETAIL,
                        component: SurveyDetail,
                        meta: {
                            breadcrumb: 'Детальное представление',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE,
                                ORGANIZATION_INSTANCE
                            ]
                        },
                    },
                ]
            },
            {
                path: 'field',
                name: names.FIELDS.LIST,
                component: FiledList,
                meta: {
                    breadcrumb: 'Шаблонные поля',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: 'create',
                        name: names.FIELDS.CREATE,
                        component: SurveyCreate,
                        meta: {
                            breadcrumb: 'Создание',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    },
                    {
                        path: ':idField',
                        name: names.FIELDS.DETAIL,
                        component: FieldDetail,
                        meta: {
                            breadcrumb: 'Детальное представление',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                        children: [
                            {
                                path: 'edit',
                                name: names.FIELDS.EDIT,
                                component: SurveyEdit,
                                meta: {
                                    breadcrumb: 'Редактирование',
                                    requiredAuth: true,
                                    instances: [
                                        SURVEY_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                            },
                        ],
                    },
                ]
            },
            {
                path: 'choice',
                name: names.CHOICES.LIST,
                component: ChoiceBreadcrumbs,
                meta: {
                    breadcrumb: 'Списки выбора',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE,
                        ORGANIZATION_INSTANCE
                    ]
                },
            },
            {
                path: 'attempt',
                name: names.ATTEMPTS.LIST,
                component: AttemptList,
                meta: {
                    breadcrumb: 'Попытки',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idAttempt',
                        name: names.ATTEMPTS.DETAIL,
                        component: AttemptDetail,
                        meta: {
                            breadcrumb: 'Просмотр попытки',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE,
                                ORGANIZATION_INSTANCE
                            ]
                        },
                    },
                ]
            },
            {
                path: 'file',
                name: names.FILE.LIST,
                component: FileList,
                meta: {
                    breadcrumb: 'Файлы',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                    ]
                },
                children: [
                    {
                        path: ':idFile',
                        name: names.FILE.DETAIL,
                        component: FileDetail,
                        meta: {
                            breadcrumb: 'Файл',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                            ]
                        },
                    },
                ]
            },
        ]
    },

])


