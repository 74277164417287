<template>
  <v-card outlined>
    <v-card-text>
      <edit-survey-stage-field-form-template
        ref="form"
        v-model="field"
        :disable_permission="true"
        :date_dynamic_lower="field.date_dynamic_lower"
        :date_dynamic_upper="field.date_dynamic_upper"
        :date_dynamic_value_type="field.date_dynamic_value_type"
        :date_static_lower="field.date_static_lower"
        :date_static_upper="field.date_static_upper"
        :field_type="field.type"
        :coherent_type="field.coherent_type"
        :loading="false"
        :readonly="false"
        :fields="[]"
        :choices-list="choices_list"
        :choices-value-list="choices_values"
        :errors-object="errors"
        @download="downloadFieldFile"
      ></edit-survey-stage-field-form-template>
    </v-card-text>
  </v-card>
</template>

<script>

import EditSurveyStageFieldFormTemplate from "@/modules/templates/survey/edit/EditSurveyStageFieldFormTemplate";
import {mapActions, mapGetters} from "vuex";
import selectors from "@/urls/selectors";
import helper from "@/urls/helper";

export default {
  name: "EditField",
  components: {
    EditSurveyStageFieldFormTemplate,
  },
  props: {
    value: {
      required: true
    },
    errors: {
      required: false,
      default: undefined,
    }
  },
  data(){
    return {
      field: this.value,
      choices_values: [],
    }
  },
  computed: {
    ...mapGetters({
      choices_list: 'selectors/getAdminChoicesSelector',
      // coherent_list: 'selectors/getCoherentSelector',
    }),
  },
  watch: {
    'value.choices'(nV, oV){
      if (this.field.choices && !this.field.static_choices){
        this.loadChoicesAdminSelector()
      }
      if (nV !== oV && nV){
        this.loadChoicesValueSelector(!!oV)
      }
    },
    field: {
      deep: true,
      handler() {
        this.$emit('input', this.field)
      }
    }
  },

  methods: {
    ...mapActions({
      loadChoicesAdminSelector: 'selectors/loadChoicesAdminSelector',
      loadChoiceValue: 'admin/loadChoiceValueList',
    }),
    downloadFieldFile(field){
      let get = {}
      if (field.get_params.key)
        get['key'] = field.get_params.key
      let url = helper.getSurveyUrlWithParameters(field.name,field.action,{id: field.params.id}, get)
      if (url) window.open(url,'_blank');
    },
    loadChoicesValueSelector(update_default=true) {
      this.loadChoiceValue(
        {
          value_choices_id: this.field.choices,
          finalizer: (data) => {
            this.choices_values = data;
            if (update_default)
              this.field.default_value = undefined;
          }
        }
      );
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  created() {
    if (this.field.id) {
      if(this.field.choices) {
        this.loadChoicesAdminSelector()
        this.loadChoicesValueSelector(false)
      }
    }
  }
}
</script>

<style scoped>

</style>
