import { render, staticRenderFns } from "./WelcomePage.vue?vue&type=template&id=2b22eb48&scoped=true"
import script from "./WelcomePage.vue?vue&type=script&lang=js"
export * from "./WelcomePage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b22eb48",
  null
  
)

export default component.exports