import helper from "./helper";
// import user from "../store/modules/user";

let getSurveyUrl = helper.getSurveyUrlWithParameters

export default {
    getSurveyUrl,
    getByRecievedUrlData: (url, gets = null) => getSurveyUrl(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    COHERENT: (coherent_number, gets = null) => getSurveyUrl(
      'coherent',
      "get",
      {
        coherent_number
      },
      gets
    ),

}
