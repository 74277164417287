<template>
  <v-container class="pa-0 ">
    <v-row justify="center" no-gutters>
      <v-col cols="12" sm="10">

    <v-stepper v-model="select_stage" flat>
      <v-card
          outlined
          class="rounded-t-lg"
      >
      <v-stepper-header>
        <v-stepper-step
            v-for="(stage, sindex) in attempt_data.survey.stages"
            :key="`stage-${sindex}`"
            :complete="select_stage > sindex+1"
            :step="`${sindex+1}`"
            editable
            @click="loadFields(stage.id)"
        >
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
            v-for="(stage, sindex) in attempt_data.survey.stages"
            :key="`stage-${sindex}`"
            :step="`${sindex+1}`"
        >
          <v-card
              color="#f0f0f0"
              flat
              class="rounded-t-lg"
          >
            <v-card-title style="word-break: inherit !important;">
              {{stage.title}}
            </v-card-title>
            <v-card-subtitle align="left">
              {{stage.description}}
            </v-card-subtitle>
          </v-card>
          <template
              v-for="field in fields"
          >
            <FieldView
                v-if="field.answer"
                v-model="field.answer.value"
                :ref="`stage_${stage.id}_field_${field.id}`"
                :key="`stage_${stage.id}_field_${field.id}_key`"
                :field="field"
                @download="$emit('download', field.file)"
                :survey="false"
            ></FieldView>
          </template>
          <v-btn
              v-if="sindex !== 0"
              outlined
              @click="prevStep(sindex+1)"
          >
            Назад
          </v-btn>
          <v-btn
              v-if="sindex !== attempt_data.survey.stages.length-1"
              outlined
              @click="nextStep(sindex+1)"
          >
            Вперёд
          </v-btn>
          <v-btn
              text
              @click="goToList"
          >
            Выйти
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
      </v-card>
    </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FieldView from "./FieldView";

export default {
  name: "AttemptView",
  components: {FieldView},
  props: {
    readonly:{
      type: Boolean,
      default: false,
    },

    attempt_view: Boolean,
    attempt_data: Object,
    // user_type: String,
    loadAttemptStageFieldsStoreFunctionName: String
  },
  data() {
    return {
      select_stage: 1,
      stage_data: [],
      fields: [],
      attempt_types: {
        "admin": "admin/loadAttemptStageFieldList",
        "creator": "creator/loadAttemptStageFieldList",
        "watcher": "watcher/loadAttemptStageFieldList",
      }
    }
  },
  methods: {
    transformFromText(value){
      let transformed_value = value;
      if (transformed_value !== undefined && transformed_value !== null){
        if (transformed_value[0] === '[')
          transformed_value = JSON.parse(transformed_value)
        else if (transformed_value === 'true')
          transformed_value = true
        else if (transformed_value === 'false')
          transformed_value = false
      }
      return transformed_value;
    },
    transformToText(value){
      let transformed_model = value;
      if (transformed_model !== undefined && Array.isArray(transformed_model))
        transformed_model = JSON.stringify(transformed_model);
      else if (transformed_model !== undefined && typeof transformed_model == "boolean")
        transformed_model = `${transformed_model}`;
      return transformed_model
    },
    loadFields(stage_id){
      this.$store.dispatch(
          this.loadAttemptStageFieldsStoreFunctionName,
          {
            id: this.attempt_data.id,
            stage_id: stage_id,
            finalizer: (fields) => {
              fields.forEach((field) => {
                if(field.choices){
                  if(field.answer.value){
                    if (field.answer.value[0] === '[')
                      field.choices = JSON.parse(field.answer.value)
                    else {
                      field.choices = [field.answer.value]

                    }
                  }
                }
                if(this.readonly)
                  field.read_only=true
              })
              for (let field of fields) {
                if (typeof field.choices === 'number') {
                  field.choices = []
                }
              }
              this.fields = fields
            }
          }
      )
    },
    nextStep (n) {
      if (n === this.attempt_data.survey.stages.length) {
        this.select_stage = 1
        this.loadFields(this.attempt_data.survey.stages[this.select_stage-1].id)
      }
      else {
        this.select_stage = n + 1
        this.loadFields(this.attempt_data.survey.stages[n].id)
      }
    },
    prevStep (n) {
      if (n <= 1) {
        this.select_stage = this.attempt_data.survey.stages.length
        this.loadFields(this.attempt_data.survey.stages[this.select_stage-1].id)
      }
      else {
        this.select_stage = n - 1
        this.loadFields(this.attempt_data.survey.stages[n-2].id)
      }
    },
    goToList() {
      this.$emit('close', false)
    },
  },
  created() {
    this.loadFields(this.attempt_data.survey.stages[0].id)
  }
}
</script>

<style scoped>

</style>
