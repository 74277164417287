<template>
    <v-autocomplete
        ref="loading_auto"
        :clearable="!readonly"
        :placeholder="placeholder"
        :error="error"
        :error-messages="errorMessages"
        :label="label"
        :item-text="itemText"
        :item-value="itemValue"
        :value="value"
        outlined
        :readonly="state.readonly || readonly"
        @input="emitInput($event)"
        :loading="state.loading || loading"
        :items="list"
        :search-input.sync="search"
        :hint="hint"
        :persistent-hint="persistentHint"
        @keydown.enter.prevent="searchStudents(false)"
        @update:search-input="searchStudents()"
        @click:clear="list=[]"
    >

<!--    :menu-props="{ maxWidth: 500 }" "-->
    <template v-slot:no-data>
      <slot name="no-data">
        <div>
          Для поиска начните набирать текст, <br/> и либо остановитесь на 1 секунду, либо нажмите Enter
        </div>
      </slot>
    </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
            {{ data.item[itemText]}}
          </div>
        </template>
      </template>
      <template v-slot:label>
      <slot name="label">
        {{ label }}
      </slot>
    </template>
      <template v-slot:append-outer v-if="downloadable">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                icon
                @click="$emit('download')"
            >
              <v-icon>file_download</v-icon>
            </v-btn>
          </template>
          <span>Загрузить документ</span>
        </v-tooltip>
      </template>
  </v-autocomplete>
</template>

<script>
import {addGetParameters, sendGetRequest} from "@/helper/requests";
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "LoadedAutocomplete",
  props: {
    error: {
      default: false,
    },
    errorMessages: {
      default: '',
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    label: {
      default: '',
    },
    hint: {
      required: false,
      hint: '',
    },
    persistentHint: {
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    itemText: {
      default: 'text',
    },
    itemValue: {
      default: 'value',
    },
    placeholder: {
      default: 'Начните вводить текст',
    },
    url: {
      required: true,
      type: [String, Function]
    },
    searchParameter: {
      required: true,
      type: String,
    },
    postLoader: {
      required: false,
      type: Function
    },
    loadFios: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data: function () {
    let list = [];
    if (this.itemText === this.itemValue)
      list = [{
        [this.itemText]: this.value
      }]
    else
      list = [{
        [this.itemValue]: this.value,
        [this.itemText]: this.value
      }]
    return {
      state: {
        loading: false,
        readonly: false,
      },
      list: !!this.value ? list : [],
      search: '',
      timeout: null,
      selected: !!this.value ? this.value: null,
    }
  },
  methods: {
    validate() {
      return true
    },
    emitInput(event){
      this.selected = event;
      this.$emit('input', event)
    },
    searchStudents(timeout=true){
      if(this.list.findIndex(el => el.value === this.selected) !== -1)
        return;
      if(this.timeout !== null)
        clearTimeout(this.timeout);
      if(this.list.length === 1 && this.list[0].value === this.value)
        return;
      if(!(this.list.length === 1 && this.list[0].text.includes(this.search))) {
        if (this.search) {
          if (timeout) {
            this.timeout = setTimeout(
              () => {
                this.loadStudentWhenSearch();
              },
              1000
            )
          }else{
            this.loadStudentWhenSearch();
          }
        }
        if (this.list.length > 0) {
          this.list = []
        }
      }
    },
    setLoading(){
      this.state.loading = true;
      this.state.readonly = true;
    },
    setNotLoading(){
      this.state.loading = false;
      this.state.readonly = false;
    },
    loadStudentWhenSearch(){
      this.setLoading();
      let parameters = {size: 25}
      parameters[`${this.searchParameter}`] = this.search
      let url = addGetParameters(
        typeof this.url === 'function' ? this.url() : this.url,
        parameters
      )
      sendGetRequest(url,
        data => {
          if(this.postLoader){
            this.postLoader(
              data,
              (vals) => {
                this.list = vals
                return this.list
              },
              () => {
                this.setNotLoading();
              }
            );
          }else{
            let vals = data;
            if(data.hasOwnProperty('results'))
              vals = data.results
            if (this.loadFios) {
              vals.forEach(el => {
                this.$set(el, 'fio', '')
                let text = el.text.split(', ');
                this.$set(el, 'user', text[0])
                if (text.length > 1)
                  this.$set(el, 'text', text[1])
                else {
                  this.$set(el, 'text', '')
                }
              })
            }
            this.$nextTick(() => {
              this.list = vals;
            });
            if (this.loadFios) {
              generateListUidToName(
                this.list,
                "user",
                "fio",
                () => {
                  this.list.forEach(el => {
                    if (el.text !== '')
                      this.$set(el, 'text', `${el.fio}, ${el.text}`)
                    else
                      this.$set(el, 'text', `${el.fio}`)
                  })
                  this.setNotLoading();
                }
              )
            } else {
              this.setNotLoading();
            }
          }
        }
      );
    },
  },
}
</script>

<style scoped>

</style>
