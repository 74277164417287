<template>
    <v-dialog persistent v-model="opened" width="500">
        <v-card>
            <v-card-title :class="titleColor" class="title" style="word-break: inherit !important;">
                <v-flex text-xs-center>
                  <span v-html="title"></span>
                </v-flex>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <slot></slot>
            </v-card-text>
            <v-card-actions>
                <v-flex>
                    <v-btn
                            @click="closeDialog"
                            color="info"
                            text
                    >
                        {{textBtn}}
                    </v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "MsgDialog",
        props: {
            opened: Boolean,
            warning: Boolean || false,
            error: Boolean || false,
            success: Boolean || false,
            title: String,
            textBtn:{
              type: String,
              default: 'OK'
            },
        },
        computed: {
            _error: function () {
                return this.error;
            },
            _warning: function () {
                if (!this.error)
                    return this.warning;
                else
                    return false
            },
            _success: function () {
                if (!this._warning)
                    return this.success;
                else
                    return false
            },
            _info: function () {
                return !this._success;
            },
            titleColor: function () {
                return {
                    'warning': this._warning,
                    'error': this._error,
                    'success': this._success,
                    'info': this._info,
                }
            }
        },
        methods: {
            closeDialog: function () {
                this.$emit('close')
            },
        }
    }
</script>

<style scoped>

</style>
