const ADMINISTRATIVE = {
    BASE: 'Administrative',
    MAIN: undefined,
    SURVEYS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        CREATE: undefined,
        EDIT: undefined,
        STAGE: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        FIELD: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
            CREATE: undefined,
            EDIT: undefined,
        },
    },
    FILE: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    USERS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    FIELDS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    CHOICES: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    ATTEMPTS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
};

ADMINISTRATIVE.MAIN = `${ADMINISTRATIVE.BASE}|Main`

ADMINISTRATIVE.SURVEYS._BASE = `${ADMINISTRATIVE.BASE}|Survey`
ADMINISTRATIVE.SURVEYS.LIST = `${ADMINISTRATIVE.SURVEYS._BASE}|List`
ADMINISTRATIVE.SURVEYS.DETAIL = `${ADMINISTRATIVE.SURVEYS._BASE}|Detail`
ADMINISTRATIVE.SURVEYS.CREATE = `${ADMINISTRATIVE.SURVEYS._BASE}|Create`
ADMINISTRATIVE.SURVEYS.EDIT = `${ADMINISTRATIVE.SURVEYS._BASE}|Edit`
ADMINISTRATIVE.SURVEYS.STAGE._BASE = `${ADMINISTRATIVE.SURVEYS._BASE}|Stage`
ADMINISTRATIVE.SURVEYS.STAGE.LIST = `${ADMINISTRATIVE.SURVEYS.STAGE._BASE}|List`
ADMINISTRATIVE.SURVEYS.STAGE.DETAIL = `${ADMINISTRATIVE.SURVEYS.STAGE._BASE}|Detail`
ADMINISTRATIVE.SURVEYS.FIELD._BASE = `${ADMINISTRATIVE.SURVEYS._BASE}|Field`
ADMINISTRATIVE.SURVEYS.FIELD.LIST = `${ADMINISTRATIVE.SURVEYS.FIELD._BASE}|List`
ADMINISTRATIVE.SURVEYS.FIELD.DETAIL = `${ADMINISTRATIVE.SURVEYS.FIELD._BASE}|Detail`

ADMINISTRATIVE.USERS._BASE = `${ADMINISTRATIVE.BASE}|User`
ADMINISTRATIVE.USERS.LIST = `${ADMINISTRATIVE.USERS._BASE}|List`
ADMINISTRATIVE.USERS.DETAIL = `${ADMINISTRATIVE.USERS._BASE}|Detail`

ADMINISTRATIVE.FILE._BASE = `${ADMINISTRATIVE.BASE}|File`
ADMINISTRATIVE.FILE.LIST = `${ADMINISTRATIVE.FILE._BASE}|List`
ADMINISTRATIVE.FILE.DETAIL = `${ADMINISTRATIVE.FILE._BASE}|Detail`

ADMINISTRATIVE.FIELDS._BASE = `${ADMINISTRATIVE.BASE}|Field`
ADMINISTRATIVE.FIELDS.LIST = `${ADMINISTRATIVE.FIELDS._BASE}|List`
ADMINISTRATIVE.FIELDS.DETAIL = `${ADMINISTRATIVE.FIELDS._BASE}|Detail`
ADMINISTRATIVE.FIELDS.CREATE = `${ADMINISTRATIVE.FIELDS._BASE}|Create`
ADMINISTRATIVE.FIELDS.EDIT = `${ADMINISTRATIVE.FIELDS._BASE}|Edit`

ADMINISTRATIVE.CHOICES._BASE = `${ADMINISTRATIVE.BASE}|Choice`
ADMINISTRATIVE.CHOICES.LIST = `${ADMINISTRATIVE.CHOICES._BASE}|List`
ADMINISTRATIVE.CHOICES.DETAIL = `${ADMINISTRATIVE.CHOICES._BASE}|Detail`

ADMINISTRATIVE.ATTEMPTS._BASE = `${ADMINISTRATIVE.BASE}|Attempt`
ADMINISTRATIVE.ATTEMPTS.LIST = `${ADMINISTRATIVE.ATTEMPTS._BASE}|List`
ADMINISTRATIVE.ATTEMPTS.DETAIL = `${ADMINISTRATIVE.ATTEMPTS._BASE}|Detail`




export default {
    ...ADMINISTRATIVE
}
