<template>
  <scroll-center-dialog v-model="opened" @close="close" @apply="save" :title="form_title" :scrolled="false">
    <loading-autocomplete
        v-if="this.edit === false"
        placeholder="Введите ФИО и выберете из предложенных вариантов"
        label="Работник"
        v-model="user.user"
        search-parameter="fio"
        :url="worker_selector"
        :post-loader="postLoader"
        :error="hasError('user')"
        :errorMessages="getError('user')"
        class="pt-4"
    >
      <template v-slot:no-data>
        <div>
          Для поиска начните набирать ФИО работника, <br/> и либо остановитесь на 1 секунду, либо нажмите Enter
        </div>
      </template>
    </loading-autocomplete>
    <v-checkbox
        v-model="user.is_admin"
        label="Администратор"
        :error="hasError('is_admin')"
        :error-messages="getError('is_admin')"
    ></v-checkbox>
    <v-checkbox
        v-model="user.is_creator"
        :error="hasError('is_creator')"
        :error-messages="getError('is_creator')"
        label="Создатель"
    ></v-checkbox>
    <v-checkbox
        v-model="user.is_watch"
        :error="hasError('is_watch')"
        :error-messages="getError('is_watch')"
        label="Наблюдатель"
    ></v-checkbox>
  </scroll-center-dialog>
</template>

<script>
import {generateListUidToName} from "@/helper/uidToName";
import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import {mapActions} from "vuex";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";

export default {
  name: "EditDialog",
  props: {
    opened: Boolean,
    edit: Boolean,
    userData: Object,
  },
  mixins:[FormErrorsMixin],
  components: {
    ScrollCenterDialog,
    LoadingAutocomplete
  },
  data() {
    return {
      user_default: {
        user: '',
        is_admin: false,
        is_creator: false,
        is_watch: false,
      },
      user: undefined
    }
  },
  computed: {
    form_title () {
      return this.edit === false ? 'Добавить пользователя' : this.user.fio
    },
    worker_selector(){
      return selectors.SELECTORS.WORKERS.ALL()
    }
  },
  methods: {
    ...mapActions({
      createUserRole: 'admin/createUserRole',
      changeUserRole: 'admin/changeUserRole',
    }),
    postLoader(data, list, end) {
      data.results.forEach(el => {
        this.$set(el, 'fio', '');
      })
      let vals = list(data.results);
      generateListUidToName(
          vals,
          "value",
          "fio",
          ()=>{
            vals.forEach(el => {
              this.$set(el, 'text', `${el.fio}, ${el.text}`)
            })
            end();
          }
      )
    },
    loadUserData(){
      if(this.userData === undefined){
        this.user = this.user_default
      }
      else{
        this.user = JSON.parse(JSON.stringify(this.userData))
      }
    },
    close(){
      this.$emit('close', false)
    },
    save(){
      // this.$emit('close', this.user)
      if (this.edit) {
        this.changeUserRole({
          data: this.user,
          finalizer: (val) => {
            this.$emit('close', this.user)
          },
          catcher:(val) => {
            this.mapErrors(val)
          },
        })
      }else {
        this.createUserRole({
          data: this.user,
          finalizer: (val) => {
            this.$emit('close', this.user)
          },
          catcher:(val) => {
            this.mapErrors(val)
          },
        })
      }
    },
  },
  created() {
    this.loadUserData()
  }
}
</script>

<style scoped>

</style>
