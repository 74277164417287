<template>
  <v-dialog
      v-model="field_flag"
      width="600px"
      persistent
  >
    <v-card class="pl-3 pr-3 text-left">
      <v-card-title>
        <span class="text-h5">Данные поля</span>
        <v-spacer></v-spacer>
        <v-btn
            icon
            fab
            small
            @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>bookmark</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{field.title}}
                <v-list-item-subtitle>Название</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>description</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                {{field.description ? field.description : "Не задано"}}
                <v-list-item-subtitle>Описание</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-icon>
                <v-icon class="pr-2">title</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="base_field_type_selector[field.type] !== undefined">{{base_field_type_selector[field.type].text}}</span>
                </v-list-item-title>
                <v-list-item-subtitle>Тип поля</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>people</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{field.public ? "Публичный" : "Не публичный"}}</v-list-item-title>
                <v-list-item-subtitle>Публичность</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>edit_attributes</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{field.default_value ? field.default_value : "Не задано"}}</v-list-item-title>
            <v-list-item-subtitle>Значение по умолчанию</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FieldDetail",
  components: {},
  props: {
    field_flag: Boolean,
    field_data: Object,
  },
  data(){
    return {
      field: {choices: {}},
      open_view: false,
      types: {}
    }
  },
  computed: {
    ...mapGetters({
      base_field_type_selector: 'selectors/getFieldTypeSelector'
    }),
  },
  methods: {
    ...mapActions({
      loadFieldTypes: 'selectors/loadFieldTypeSelector',
      loadFieldDetail: 'admin/loadFieldDetail',
      loadFieldTypeSelector: 'selectors/loadFieldTypeSelector',
      loadChoicesValue: 'admin/loadChoiceValueList'
    }),
    closeDialog: function () {
      this.$emit('close', false)
    },

  },
  created() {
    this.loadFieldDetail({
      id: this.field_data.id,
      finalizer: (val) => {
        this.field = val
        if (val.choices) {
          this.loadChoicesValue({
            value_choices_id: val.choices,
            finalizer: (el) => {
              this.field.choices = el
              this.open_view = true
            },
            catcher:(val) => {
              this.mapErrors(val)
            }
          })
        }
      }
    })
  },
}
</script>

<style scoped>

</style>
