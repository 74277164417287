<template>
  <v-container
    class="page-content"
    style="margin: 0px; width: 100%"
  >
    <v-progress-linear :active="loading" indeterminate fixed bottom style="z-index: 2"></v-progress-linear>
    <edit-survey-error-snack-bar
      :error-text="errorText"
      :error-show="errorShow"
      @close="$emit('update:errorShow', false)"
    ></edit-survey-error-snack-bar>
    <edit-survey-fab-button
      icon="menu_book"
      :color="survey.draft ? 'blue' : 'orange'"
      :tooltip="survey.draft ? 'Черновик' : 'Опубликовано'"
      right="10" bottom="150"
      @click="$emit('publish')"
    ></edit-survey-fab-button>
    <edit-survey-fab-button
      icon="add"
      tooltip="Добавить этап"
      right="10" bottom="80"
      :disable-btn="disableBtn"
      @click="$emit('addStage')"
    ></edit-survey-fab-button>
    <edit-survey-fab-button
      icon="save"
      color="teal"
      tooltip="Сохранить изменения"
      right="10" bottom="10"
      :disable-btn="disableBtn"
      @click="$emit('save')"
    ></edit-survey-fab-button>

    <v-row justify="center">
      <v-col cols="12" sm="10">
        <v-expansion-panels
            class="mystyle"
            accordion
            focusable
            v-model="default_panel"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="2" md="1">
                  <v-btn
                    icon
                    :to="surveyLink"
                  >
                    <v-icon>arrow_back</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="10" md="6" class="pt-6  text-h6 " >
                  Информация об опросе
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-3 pb-3">
              <survey-form-template
                  v-model="inside_survey"
                  :loading_data="loading"
                  @checkKindParam="checkKindParam"
                  @deleteKindParam="deleteKindParam"
                  @deleteAllKindParam="deleteAllKindParam"
              ></survey-form-template>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <slot name="stages"></slot>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <slot name="dialogs"></slot>
  </v-container>
</template>

<script>
import SurveyFormTemplate from "@/modules/templates/survey/SurveyFormTemplate";
import EditSurveyFabButton from "@/modules/templates/survey/edit/EditSurveyFabButton";
import EditSurveyErrorSnackBar from "@/modules/templates/survey/edit/EditSurveyErrorSnackBar";

export default {
  name: "EditSurveyTemplate",
  components: {
    EditSurveyErrorSnackBar,
    EditSurveyFabButton,
    SurveyFormTemplate
  },
  props: {
    errorText: String,
    errorShow: Boolean,
    survey: Object,
    surveyLink: Object,
    loading: Boolean,
    disableBtn:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      default_panel: 0,
      inside_survey: this.survey
    }
  },
  watch: {
    survey(){
      this.inside_survey = this.survey;
    },
    inside_survey(){
      this.$emit('update:survey', this.inside_survey)
    },
  },
  methods: {
    checkKindParam(event){
      this.$emit('checkKindParam', event)
    },
    deleteKindParam(event){
      this.$emit('deleteKindParam', event)
    },
    deleteAllKindParam(event){
      this.$emit('deleteAllKindParam', event)
    }
  }
}
</script>

<style scoped>

</style>
