<template>
  <v-textarea
    prepend-icon="arrow_right"
    class="pt-1"
    auto-grow
    outlined
    rows="1"
    v-model="model"
    :label="`Вариант ответа ${index+1}`"
    :hide-details="!hasError('value')"
    :readonly="readOnly"
    :error="hasError('value')"
    :error-messages="getError('value')"
    @input="deleteError('value')"
  >
    <template v-slot:append-outer>
      <v-btn color="primary" icon x-small fab @click="emitDeleteRow" v-if="!readOnly">
        <v-icon >close</v-icon>
      </v-btn>
    </template>
    <template v-slot:prepend>
      <v-icon class="mt-1">arrow_right</v-icon>
    </template>
  </v-textarea>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  name: "ChoiceValueFormRowTemplate",
  mixins: [FormErrorsMixin],
  props: {
    value: {
      required: true,
      type: String,
    },
    index: {
      required: true,
      type: Number
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    fieldErrors: {
      default: undefined
    }
  },
  data(){
    return {
      model: this.value,
    }
  },
  watch: {
    value(nV, oV){
      if (nV !== oV && this.model !== this.value) {
        this.model = this.value;
      }
    },
    model(nV, oV){
      if (nV !== oV && this.model !== this.value) {
        this.$emit('input', this.model);
      }
    },
    fieldErrors(){
      if (this.fieldErrors !== undefined){
        let _errors = this.fieldErrors;
        if (_errors.hasOwnProperty('id'))
          if (_errors.field)
            _errors.field.push(..._errors.id)
          else
            _errors.field = _errors.id
        this.mapErrors(this.fieldErrors)
      }
    }
  },
  methods: {
    emitDeleteRow(){
      this.$emit('delete', this.index)
    },
    // clearErrors() {
    //   this.errors.fields = {}
    //   this.errors.non = []
    // }
  },
  created() {
    // this.clearErrors();
  }
}
</script>

<style scoped>

</style>
