import {ACCOUNTS_INSTANCE, SURVEY_INSTANCE} from "@/helper/consts";

import Breadcrumbs from "../Breadcrumbs";

import names from "./names";
import SurveyBreadcrumbs from "../../creator/survey/SurveyBreadcrumbs";
import SurveyCreate from "../../creator/survey/Create";
import SurveyEdit from "../../creator/survey/Edit";
import SurveyDetail from "../../creator/survey/Detail";
import Preview from "../../creator/survey/Preview";
import ChoiceBreadcrumbs from "../../creator/choice/ChoiceBreadcrumbs";
import AttemptList from "../../creator/attempt/List";
import AttemptDetail from "../../creator/attempt/Detail";
import FileList from "@/modules/creator/file/List.vue";
import FileDetail from "@/modules/creator/file/Detail.vue";


export default ([
    {
        path: 'creator',
        name: names.MAIN,
        component: Breadcrumbs,
        meta: {
            breadcrumb: 'Создатель',
            requiredAuth: true,
            instances: [
                SURVEY_INSTANCE,
                ACCOUNTS_INSTANCE
            ]
        },
        children: [
            {
                path: 'file',
                name: names.FILE.LIST,
                component: FileList,
                meta: {
                    breadcrumb: 'Файлы',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                    ]
                },
                children: [
                    {
                        path: ':idFile',
                        name: names.FILE.DETAIL,
                        component: FileDetail,
                        meta: {
                            breadcrumb: 'Файл',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                            ]
                        },
                    },
                ]
            },
            {
                path: 'survey',
                name: names.SURVEYS.LIST,
                component: SurveyBreadcrumbs,
                meta: {
                    breadcrumb: 'Опросы',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: 'create',
                        name: names.SURVEYS.CREATE,
                        component: SurveyCreate,
                        meta: {
                            breadcrumb: 'Создание',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    },
                    {
                        path: ':idSurvey/edit',
                        name: names.SURVEYS.EDIT,
                        component: SurveyEdit,
                        meta: {
                            breadcrumb: 'Редактирование',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    },
                    {
                        path: ':idSurvey',
                        name: names.SURVEYS.DETAIL,
                        component: SurveyDetail,
                        meta: {
                            breadcrumb: 'Детальное представление',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                        children: [
                            {
                                path: 'preview',
                                name: names.SURVEYS.PREVIEW,
                                component: Preview,
                                meta: {
                                    breadcrumb: 'Предпросмотр',
                                    requiredAuth: true,
                                    instances: [
                                        SURVEY_INSTANCE,
                                        ACCOUNTS_INSTANCE
                                    ]
                                },
                            },
                        ]
                    },
                ]
            },
            {
                path: 'choice',
                name: names.CHOICES.LIST,
                component: ChoiceBreadcrumbs,
                meta: {
                    breadcrumb: 'Списки выбора',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE,
                    ]
                },
            },
            {
                path: 'attempt',
                name: names.ATTEMPTS.LIST,
                component: AttemptList,
                meta: {
                    breadcrumb: 'Попытки',
                    requiredAuth: true,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idAttempt',
                        name: names.ATTEMPTS.DETAIL,
                        component: AttemptDetail,
                        meta: {
                            breadcrumb: 'Просмотр попытки',
                            requiredAuth: true,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE,
                            ]
                        },
                    },
                ]
            },
        ]
    },

])


