import helper from "../../../urls/helper";
import surveying from "../../../urls/roles/surveying";

import {
    makeDeleteRequest,
    makeGetRequest,
    sendPatchRequest,
    makeParticalUpdateRequest,
    makePostRequest, sendDeleteRequest,
    sendGetRequest, sendPostRequest, finalizeRequest, makePostRequestFile
} from "../../../helper/requests";
import creator from "../../../urls/roles/creator";
import router from "../../../router";
import names from "../../../modules/creator/routers/names";
import surveys from "@/urls/roles/admin";

const state = {
    stages: [],
    fields: [],
}

const getters = {

}

const mutations = {
    set_stages: (state, data) => {
        state.stages = data
    },
}

const actions = {
    loadSurveyList: ({commit}, {url, finalizer, catcher}) => {
        if (!url) url = creator.CREATOR.SURVEY.LIST()
        sendGetRequest(url, (data) => finalizer(data, url), catcher)
    },
    createSurvey: ({commit}, {data, finalizer, catcher}) => {
        let url = creator.CREATOR.SURVEY.LIST()
        sendPostRequest(
            url, data, finalizer, catcher,
        )

    },
    duplicateSurvey: ({commit}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.SURVEY.DUPLICATE(id)
        sendPostRequest(url, null, finalizer, catcher)
    },
    updateSurvey: ({commit}, {data, id, finalizer, catcher}) => {
        let url = creator.CREATOR.SURVEY.EDIT(id)
        sendPatchRequest(
            url, data, finalizer, catcher
        )
    },
    loadSurveyDetail: ({commit, getters}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.SURVEY.DETAIL(id)
        sendGetRequest(url, finalizer, catcher)
    },
    deleteSurveyDetail: ({commit}, {item, finalizer, catcher}) => {
        let url = creator.CREATOR.SURVEY.DELETE(item.id)
        sendDeleteRequest(url, finalizer, catcher)
    },
    loadStatisticFile: ({commit}, {id,get, finalizer, catcher}) => {
        let url = creator.CREATOR.FILE.UNLODING(id, get)
        sendGetRequest(
            url,
            finalizer,
            catcher
        )
    },
    surveyGetKindParam: ({commit}, {survey_id, search, finalizer, catcher}) => {
        let get = {}
        if (search) get['search'] = search
        let url = creator.CREATOR.KIND_PARAM.GET_KIND_PARAM(survey_id, get)
        sendGetRequest(
            url,
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },
    surveyListKindParam: ({commit}, {survey_id, finalizer, catcher}) => {
        let url = creator.CREATOR.KIND_PARAM.LIST(survey_id)
        sendGetRequest(
            url,
            finalizer,
            catcher
        )
    },
    surveyCreateKindParam: ({commit}, {survey_id, data, finalizer, catcher}) => {
        let url = creator.CREATOR.KIND_PARAM.CREATE(survey_id)
        sendPostRequest(
            url,
            data,
            finalizer,
            catcher
        )
    },
    surveyDeleteKindParam: ({commit}, {survey_id, id, finalizer, catcher}) => {
        let url = creator.CREATOR.KIND_PARAM.DELETE(survey_id, id)
        sendDeleteRequest(
            url,
            finalizer,
            catcher
        )
    },
    surveyDeleteAllKindParam: ({commit}, {survey_id, finalizer, catcher}) => {
        let url = creator.CREATOR.KIND_PARAM.DELETEALL(survey_id)
        sendDeleteRequest(
            url,
            finalizer,
            catcher
        )
    },
    // Invite
    personalLinkList: ({commit}, {survey_id, finalizer, cather}) => {
        let url = creator.CREATOR.INVITE.LIST(survey_id)
        sendGetRequest(
            url,
            finalizer,
            cather
        )
    },
    personalLinkCreate: ({commit}, {survey_id, data, finalizer, cather}) => {
        let url = creator.CREATOR.INVITE.CREATE(survey_id)
        sendPostRequest(
            url,
            data,
            finalizer,
            cather
        )
    },
    personalLinkDetail: ({commit}, {survey_id, id, finalizer, cather}) => {
        let url = creator.CREATOR.INVITE.DETAIL(survey_id, id)
        sendGetRequest(
            url,
            finalizer,
            cather
        )
    },
    personalLinkDelete: ({commit}, {survey_id, id, finalizer, cather}) => {
        let url = creator.CREATOR.INVITE.DELETE(survey_id, id)
        sendDeleteRequest(
            url,
            finalizer,
            cather
        )
    },
    surveySendInvite: ({commit}, {data, survey_id, finalizer, cather}) => {
        let url = creator.CREATOR.INVITE.SEND(survey_id)
        sendPostRequest(
            url,
            data,
            finalizer,
            cather
        )
    },

    // Stages
    loadStageList: ({commit}, {survey_id, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE.LIST(survey_id, {'size': 100})
        sendGetRequest(url,
            (data) => {
                commit('set_stages', data)
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },
    editStage: ({commit}, {data, survey_id, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE.EDIT(survey_id, data.id)
        sendPatchRequest(url, data,
            (data) => {
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },
    saveStage: ({commit}, {data, survey_id, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE.SAVE(survey_id)
        sendPostRequest(url, data,
            (data) => {
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },
    copyStage: ({commit}, {survey_id, stage_id, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE.COPY(survey_id, stage_id,)
        sendGetRequest(url,
            (data) => {
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },
    deleteStage: ({commit}, {survey_id, stage_id, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE.DELETE(survey_id, stage_id)
        sendDeleteRequest(url,
            (data) => {
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },

    // Stage fields
    listStageField: ({commit}, {survey_id, stage_id, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE_FIELDS.LIST(survey_id, stage_id, {'size': 100})
            sendGetRequest(url,
            (data) => {
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },
    addStageField: ({commit}, {survey_id, stage_id, data, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE_FIELDS.CREATE(survey_id, stage_id)
        if(data.file !== undefined && data.file !== null && data.file.get_params === undefined) {
            let file = data.file
            let formData = new FormData()
            for (let el in data){
                if (data[el] !== null && el !== 'file' )
                    formData.append(`${el}`, data[el])
            }
            if (typeof file === 'object' && file !== null)
                if (String(file.name).length < 130 )
                    formData.append('file', file)

            finalizeRequest(
                makePostRequestFile(
                    url,
                    formData,
                    'POST'
                ),
                finalizer,
                catcher
            )
        } else {
            let send = {...data}
            if(data.file !== undefined && data.file !== null){
                delete send.file;
            }
            sendPostRequest(
                url, send,
                (data) => {
                    if (finalizer) finalizer(data)
                },
                (error_data) => {
                    if (catcher) catcher(error_data)
                }
            )
        }

    },
    editStageField: ({state, commit}, {data, survey_id, stage_id, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE_FIELDS.EDIT(survey_id, stage_id, data.id)

        if(data.file !== undefined && data.file !== null && data.file.get_params === undefined) {
            let file = data.file
            let formData = new FormData()
            for (let el in data){
                if (data[el] !== null && el !== 'file' )
                    formData.append(`${el}`, data[el])
            }
            if (typeof file === 'object' && file !== null)
                if (String(file.name).length < 130 )
                    formData.append('file', file)

            finalizeRequest(
                makePostRequestFile(
                    url,
                    formData,
                    'PATCH'
                ),
                finalizer,
                catcher
            )
        } else {
            let send = {...data}
            if(data.file !== undefined && data.file !== null){
                delete send.file;
            }
            sendPatchRequest(
                url, send,
                    (data) => {
                        if (finalizer) finalizer(data)
                    },
                    (error_data) => {
                        if (catcher) catcher(error_data)
                    }
            )
        }
    },
    deleteStageField: ({dispatch, commit}, {survey_id, stage_id, id, finalizer, catcher}) => {
        let url = creator.CREATOR.STAGE_FIELDS.DELETE(survey_id, stage_id, id)
        sendDeleteRequest(url,
            (data) => {
                if (finalizer) finalizer(data)
            },
            (error_data) => {
                if (catcher) catcher(error_data)
            }
        )
    },

    // Fields
    loadFieldDetail: ({commit}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.FIELDS.DETAIL(id)
        sendGetRequest(url, finalizer, catcher)
    },

    // Attempts
    loadAttemptList: ({commit}, {url, finalizer, catcher}) => {
        if (!url)
            url = creator.CREATOR.ATTEMPTS.LIST();
        sendGetRequest(url, (data) => finalizer(data, url), catcher)
    },
    loadAttemptDetail: ({commit}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.ATTEMPTS.DETAIL(id)
        sendGetRequest(url, finalizer, catcher)
    },
    loadAttemptStageList: ({commit}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.ATTEMPTS.STAGES(id)
        sendGetRequest(url, finalizer, catcher)
    },
    loadAttemptStageFieldList: ({commit}, {id, stage_id, finalizer, catcher}) => {
        let url = creator.CREATOR.ATTEMPTS.FIELDS(id, stage_id)
        sendGetRequest(url, finalizer, catcher)
    },
    deleteAttemptDetail: ({commit}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.ATTEMPTS.DETAIL(id)
        sendDeleteRequest(url, finalizer, catcher)
    },

    // Choices
    loadChoiceList: ({commit}, {url, finalizer, catcher}) => {
        if (!url) url = creator.CREATOR.CHOICES.LIST()
        sendGetRequest(url, (data) => finalizer(data, url), catcher)
    },
    loadChoiceDetail: ({commit, getters}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICES.DETAIL(id)
        sendGetRequest(url, finalizer, catcher)
    },
    createChoice: ({dispatch, commit}, {data, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICES.SAVE()
        sendPostRequest(url, data, finalizer, catcher)
    },
    updateChoice: ({dispatch, commit}, {id, data, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICES.EDIT(id)
        sendPatchRequest(url, data, finalizer, catcher);
    },
    deleteChoice: ({dispatch, commit}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICES.DELETE(id)
        sendDeleteRequest(url, finalizer, catcher)
    },

    // Choice values
    loadChoiceValueList: ({commit}, {value_choices_id, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICE_VALUE.LIST(value_choices_id)
        sendGetRequest(url, finalizer, catcher)
    },
    loadChoiceListAll: ({commit}, {id, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICE_VALUE.LIST_ALL(id)
        sendGetRequest(url, finalizer, catcher)
    },
    loadChoiceValueDetail: ({commit, getters}, {value_choices_id, id, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICE_VALUE.DETAIL(value_choices_id, id)
        sendGetRequest(url, finalizer, catcher)
    },
    createChoiceValueOne: ({dispatch, commit}, {value_choices_id, data, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICE_VALUE.ONE_CREATE(value_choices_id)
        sendPostRequest(url, data, finalizer, catcher)
    },
    changeListOfChoiceValue: ({dispatch, commit}, {value_choices_id, data, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICE_VALUE.MANY_CREATE(value_choices_id)
        sendPostRequest(url, data, finalizer, catcher)
    },
    updateChoiceValue: ({dispatch, commit}, {value_choices_id, data, finalizer}) => {
        let url = creator.CREATOR.CHOICE_VALUE.EDIT(value_choices_id, data.id)
        makeParticalUpdateRequest(url, data)
            .then(resp => {
                return resp.json()
            })
            .then(json => {
                finalizer(json)
            })
    },
    deleteChoiceValue: ({dispatch, commit}, {value_choices_id, id, finalizer, catcher}) => {
        let url = creator.CREATOR.CHOICE_VALUE.DELETE(value_choices_id, id)
        sendDeleteRequest(url, finalizer, catcher)
    },
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
