<template>
  <v-form
    ref="stage_form"
  >
    <v-textarea
      auto-grow
      rows="1"
      outlined
      class="pt-2"
      v-model="stage.title"
      hint="Текст, который отображается пользователю в списке этапов опроса"
      persistent-hint
      :rules="[rules.required]"
      :error="hasError('title')"
      :error-messages="getError('title')"
      @input="deleteError('title')"
    >
      <template v-slot:label>
        <slot name="label">
          Название этапа<span class="red--text">*</span>:
        </slot>
      </template>
    </v-textarea>
    <v-textarea
      auto-grow
      rows="1"
      outlined
      v-model="stage.description"
      label="Краткое описание"
      hint="Текст, который отображается под заголовком этапа, перед полями"
      persistent-hint
      :error="hasError('description')"
      :error-messages="getError('description')"
      @input="deleteError('description')"
    ></v-textarea>
  </v-form>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";

export default {
  name: "StageFormTemplate",
  mixins: [FormErrorsMixin],
  props: {
    value: {
      required: true,
    },
    form_errors: {
      type: Object,
      default: undefined,
      required: false,
    }
  },
  data(){
    return {
      stage: this.value,
      rules: {
        required: v => (v || '').length >= 1 || `Это обязательное поле`,
      },
    }
  },
  watch: {
    form_errors: {
      deep: true,
      handler(){
        this.mapErrors(this.form_errors)
      }
    },
    value: {
      deep: true,
      handler(){
        this.survey = this.value;
      }
    },
    stage: {
      deep: true,
      handler() {
        this.$emit('input', this.survey)
      }
    }
  },
  methods: {
    validate(){
      return this.$refs.stage_form.validate()
    }
  }
}
</script>


<style scoped>

</style>
