import Vue from 'vue'
import Router from 'vue-router'
import PageNotFound from "@/modules/core/pages/PageNotFound";
import baseRouter from "@/modules/survey/routers/routers";
import {authRequiredHook, instanceRequiredHook} from "@/router/hooks";
import LoadPortal from "@/modules/core/pages/LoadPortal.vue";

Vue.use(Router)

const baseRoutes = [
    {
        path: "*",
        component: PageNotFound
    },
    {
      path: '/exit',
      name: 'LoadPortal',
      component: LoadPortal,
      meta: {
        breadcrumb: 'Выход',
      }
    },
];

var allRoutes = [];

allRoutes = allRoutes.concat(
    baseRoutes,
    baseRouter,
    // educationRouter,
);

const router = new Router(
    {
        base: '',
        mode: 'history',
        routes: allRoutes,
    }
);
router.beforeEach(authRequiredHook);
router.beforeEach(instanceRequiredHook);
// router.beforeEach(toAuthRedirectHook);

export default router;
