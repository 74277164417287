<template>
  <v-dialog persistent v-model="opened" width="700">
    <v-card>
      <v-card-title>
        <span class="text-h5">Индивидуальная ссылка на опрос</span>
        <v-spacer></v-spacer>
        <v-btn
            icon
            fab
            small
            @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-h6" style="text-align: left">
        Выберите зарегистрированного пользователя
        <loading-autocomplete
            placeholder="Введите ФИО и выберете из предложенных вариантов"
            label="Пользователь"
            v-model="data.user_uid"
            search-parameter="fio"
            :url="user_selector"
            :post-loader="postLoader"
            :error="hasError('user')"
            :errorMessages="getError('user')"
        >
          <template v-slot:no-data>
            <div>
              Для поиска начните набирать ФИО пользователя, <br/> и либо остановитесь на 1 секунду, либо нажмите Enter
            </div>
          </template>
        </loading-autocomplete>
        Или введите его почту
        <v-text-field
            v-model="data.email"
            label="Почта"
            :rules="[rules.email]"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="applyDialog"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import selectors from "@/urls/selectors";
import LoadingAutocomplete from "@/modules/core/components/LoadingAutocomplete";
import FormErrorsMixin from "@/mixins/FormErrorsMixin"
import {generateListUidToName} from "@/helper/uidToName";

export default {
  name: "CreatePersonalLink",
  props: {
    opened: Boolean,
  },
  mixins:[FormErrorsMixin],
  components: {
    LoadingAutocomplete
  },
  data(){
    return {
      data: {
        user_uid: undefined,
        email: undefined,
      },
      loading: {
        default: false,
        type: Boolean,
      },
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Неверный формат почты.'
        },
      },
    }
  },
  computed: {
    user_selector(){
      return selectors.SELECTORS.USERS.ALL()
    },
  },
  methods: {
    postLoader(data, list, end) {
      data.results.forEach(el => {
        this.$set(el, 'fio', '');
      })
      let vals = list(data.results);
      generateListUidToName(
          vals,
          "value",
          "fio",
          ()=>{
            end();
          }
      )
    },
    closeDialog: function () {
      this.$emit('close', false)
    },
    applyDialog: function () {
      this.$emit('close', this.data)
    }

  }
}
</script>

<style scoped>

</style>
