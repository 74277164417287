<template>
  <v-container style="height: 100%">
    <v-card
        class="mt-4"
        flat
    >
      <v-data-iterator
          :items=name_list
          hide-default-footer
          no-data-text="Нет данных"
      >
        <v-row class="align-center">
          <v-col
              cols="12"
              sm="6"
              lg="6"
              v-for="(item, index) in name_list"
              :key="`card_${index}`"
          >
            <router-link style="text-decoration: none" :to="{name: item.router.name}">
            <v-card
                min-height="150"
                elevation="3"
                class="ma-3 d-flex"
                color="style-color-blue-light"
                rounded
            >
              <v-container class="my-auto">
                <v-row class="my-auto">
                  <v-col class="my-auto title">
                    <span style="font-size: 26px">{{item.title }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-data-iterator>
    </v-card>
  </v-container>
</template>

<script>
import names from "./routers/names";
import router from "../../router";

export default {
  name: "SurveyMain",
  props: ['name_list'],
  data() {
    return {}
  },
  computed: {
    names: () => names,
  },
  created() {
    router.push({
      name: names.SURVEYS.LIST,
    })
  }
}
</script>

<style scoped>

</style>
