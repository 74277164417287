<template>
  <v-card outlined class="text--primary">
    <v-container class="grey--text text--darken-1">
      <v-row no-gutters class="my-1">
        <v-col class="d-flex">
          <v-icon class="pr-2">title</v-icon>
          <span class="d-inline-flexs pt-05">{{ type_name }}</span>
        </v-col>
        <v-col class="d-flex align-center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon v-if="field.read_only" class="pr-2">check_box</v-icon>
          <v-icon v-else class="pr-2">check_box_outline_blank</v-icon>
          <span class="d-inline-flexs pt-05">Только чтение</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-1">
        <v-col class="d-flex">
          <v-icon class="pr-2">bookmark</v-icon>
          <span class="d-inline-flexs pt-05">{{ field.title }}</span>
        </v-col>
        <v-col class="d-flex align-center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon v-if="field.hidden" class="pr-2">check_box</v-icon>
          <v-icon v-else class="pr-2">check_box_outline_blank</v-icon>
          <span class="d-inline-flexs pt-05">Скрытое</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-1">
        <v-col class="d-flex align-center">
          <v-icon class="pr-2">description</v-icon>
          <span v-if="field.description"  class="d-inline-flexs pt-05">{{field.description}}</span>
          <span v-else  class="d-inline-flexs pt-05">Не указано</span>
        </v-col>
        <v-col class="d-flex align-center" v-if="$vuetify.breakpoint.mdAndUp">
          <v-icon v-if="field.required" class="pr-2">check_box</v-icon>
          <v-icon v-else class="pr-2">check_box_outline_blank</v-icon>
          <span class="d-inline-flexs pt-05 ">Обязательное</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="pr-2" v-if="$vuetify.breakpoint.smAndDown">
        <v-col cols="12" class="d-flex">
          <v-icon v-if="field.read_only" class="pr-2">check_box</v-icon>
          <v-icon v-else class="pr-2">check_box_outline_blank</v-icon>
          <span class="d-inline-flexs pt-05">Только чтение</span>
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-icon v-if="field.hidden" class="pr-2">check_box</v-icon>
          <v-icon v-else class="pr-2">check_box_outline_blank</v-icon>
          <span class="d-inline-flexs pt-05">Скрытое</span>
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-icon v-if="field.required" class="pr-2">check_box</v-icon>
          <v-icon v-else class="pr-2">check_box_outline_blank</v-icon>
          <span class="d-inline-flexs pt-05">Обязательное</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-1">
        <v-col v-if="field.choices" class="d-flex" cols="12" md="6">
          <v-icon class="pr-2">reorder</v-icon>
          <span v-if="field.choices" class="d-inline-flexs pt-05">{{field.choices_name}}</span>
          <span v-else class="d-inline-flexs pt-05">Не указано</span>
        </v-col>
        <v-col v-if="field.coherent_type" class="d-flex" cols="12" md="6">
          <v-icon class="pr-2">share</v-icon>
          <span v-if="field.coherent_type" class="d-inline-flexs pt-05">{{coherent_name}}</span>
          <span v-else class="d-inline-flexs pt-05">Не указано</span>
        </v-col>
<!--      </v-row>-->
<!--      <v-row no-gutters>-->
        <v-col class="d-flex" cols="12" md="6">
          <v-icon class="pr-2">edit_attributes</v-icon>
          <span v-if="field.default_value" class="d-inline-flexs pt-05">{{field.default_value}}</span>
          <span v-else class="d-inline-flexs pt-05">Не указано</span>
        </v-col>
        <v-col class="d-flex" cols="12" md="6" v-if="field.regex">
          <v-icon class="pr-2">format_quote</v-icon>
          <span class="d-inline-flexs pt-05">{{field.regex}}</span>
        </v-col>
        <v-col class="d-flex" cols="12" md="6" v-if="field.date_static_upper || field.date_dynamic_value_type">
          <template v-if="field.date_static_lower || field.date_static_upper">
            <v-icon class="pr-2">settings</v-icon>
            <span class="d-inline-flexs pt-05 pr-2">Дата ограничена:</span>
            <span class="d-inline-flexs pt-05" v-if="field.date_static_lower">{{field.date_static_lower}}</span>
            <v-icon class="pr-2" v-if="field.date_static_lower">chevron_left</v-icon>
            <span class="d-inline-flexs pt-05">Значение</span>
            <v-icon class="pr-2" v-if="field.date_static_upper">chevron_left</v-icon>
            <span class="d-inline-flexs pt-05" v-if="field.date_static_upper">{{field.date_static_upper}}</span>
          </template>
          <template v-if="field.date_dynamic_value_type">
            <v-icon class="pr-2">settings</v-icon>
            <span class="d-inline-flexs pt-05 pr-2" v-if="field.date_dynamic_lower">Диапазон дат:</span>
            <span class="d-inline-flexs pt-05" v-if="field.date_dynamic_lower">{{field.date_dynamic_lower}}</span>
            <v-icon class="pr-2">remove</v-icon>
            <span class="d-inline-flexs pt-05">Текущая дата</span>
            <v-icon class="pr-2" v-if="field.date_dynamic_upper">add</v-icon>
            <span class="d-inline-flexs pt-05" v-if="field.date_dynamic_upper">{{field.date_dynamic_upper}}</span>
          </template>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-1" v-if="field.file">
        <v-col class="d-flex" cols="12" md="4">
          <v-icon class="pr-2">attach_file</v-icon>
          <span class="d-inline-flexs pt-05">Прикрепленный файл</span>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                  v-on="on"
                  icon
                  @click="$emit('download', field.file)"
              >
                <v-icon>file_download</v-icon>
              </v-btn>
            </template>
            <span>Загрузить документ</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "FieldDetailTemplate",
  props: {
    field: Object
  },
  computed: {
    ...mapGetters({
      types: 'selectors/getFieldTypeSelector',
      coherent_list: 'selectors/getCoherentSelector',
    }),
    type_name(){
      let type = this.types.find(el => el.value === this.field.type)
      return type ? type.text : this.field.type
    },
    coherent_name(){
      let coherent = this.coherent_list.find(el => el.value === this.field.coherent_type)
      return coherent ? coherent.text : this.field.coherent_type
    },
  },
  methods: {
    ...mapActions({
      loadFieldTypeSelector: 'selectors/loadFieldTypeSelector',
      loadCoherentSelector: 'selectors/loadCoherentSelector'
    }),

  },
  created() {
    this.loadFieldTypeSelector();
    this.loadCoherentSelector();
  }
}
</script>

<style scoped>
  .pt-05 {
    padding-top: 2px!important;
  }
</style>
