<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px; width: 100%"
      v-if="$route.name === names.ATTEMPTS.LIST"
  >
    <template>
      <v-toolbar
          flat
          elevation="0"
          color="style-color-main-gray"
          class="rounded-t-lg"
      >
        <v-text-field
            single-line
            outlined
            dense
            clearable
            :messages="$vuetify.breakpoint.smAndUp ? 'Для поиска нажмите Enter или кнопку поиск' : 'Для поиска нажмите Enter'"
            :class="$vuetify.breakpoint.smAndUp ? 'grow' : ''"
            v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
        </v-text-field>
        <v-select
          v-if="$vuetify.breakpoint.mdAndUp"
          class="pt-7 pl-2"
          outlined
          dense
          style="padding-bottom: 2px"
          single-line
          flat
          clearable
          :items="selectors.is_ended"
          v-model="pagination.filters.is_ended"
          label="Фильтр по завершённости"
        ></v-select>
        <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown" >
          <v-row no-gutters color="style-color-main-gray">
            <v-col cols="6" v-if="$vuetify.breakpoint.smAndDown">
              <v-select
                  outlined
                  style="padding-bottom: 2px"
                  single-line
                  flat
                  clearable
                  hide-details
                  dense
                  :items="selectors.is_ended"
                  v-model="pagination.filters.is_ended"
                  label="Фильтр по завершённости"
              ></v-select>
            </v-col>
          </v-row>
        </template>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          :loading="pagination.loading"
          :footer-props="pagination.footer_page"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.size"
          :server-items-length="pagination.count"
      >
        <template v-slot:item.user.email="{ item }">
          <span v-if="item.user.email">{{item.user.email}}</span>
          <span v-else >Внешний</span>
        </template>
        <template v-slot:item.survey.name="{ item }">
          <a
              style="text-decoration: none"
              @click="openDetail(item)">
            {{item.survey.name}}
          </a>
        </template>
        <template v-slot:item.is_started="{ item }">
          <v-icon color="green" v-if="item.is_started === true">done</v-icon>
          <v-icon color="red" v-else>close</v-icon>
        </template>
        <template v-slot:item.is_ended="{ item }">
          <v-icon color="green" v-if="item.is_ended === true">done</v-icon>
          <v-icon color="red" v-else>close</v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                  v-on="on"
                  @click="openDeleteAttemptDialog(item)"
              >
                delete
              </v-icon>
            </template>
            <span>Удалить опрос</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
    <ApplyDialog
        v-if="attempt_delete"
        :opened="attempt_delete"
        :get-text="() => `Вы уверены, что хотите удалить данную попытку ?`"
        @close="deleteAttemptAction"
    ></ApplyDialog>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
import TitledPageMixin from "../../../mixins/TitledPageMixin";
import names from "../routers/names";
import AttemptDetail from "./Detail";
import ApplyDialog from "../../core/components/ApplyDialog";
import router from "../../../router";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import helper from "@/urls/helper";
import {generateListUidToName} from "@/helper/uidToName";
import {mapActions} from "vuex";
import {loadData} from "@/helper/requests";

export default {
  name: "AttemptList",
  mixins: [TitledPageMixin,PaginatedDataMapperMixin],
  components: {ApplyDialog},
  data() {
    return {
      page_title: "Список попыток",
      search: {
        parameter: 'search',
      },
      pagination: {
        filters: {
          is_started: null,
          is_ended: null,
        }
      },
      reveal: false,
      loading: true,
      is_started: null,
      is_ended: null,
      selectors: {
        is_started: [
          {text:'Начатые',value: 'true'},
          {text:'Не начатые',value: 'false'},
        ],
        is_ended: [
          {text:'Завершённые',value: 'true'},
          {text:'Не завершённые',value: 'false'},
        ],
      },
      headers: [
        {
          text: 'Название опроса',
          align: 'start',
          sortable: false,
          value: 'survey.name',
        },
        { text: 'Завершён', value: 'is_ended', sortable: false},
        { text: 'Почта', value: 'user.email', sortable: false},
        { text: 'Действия', value: 'actions', sortable: false , align: 'end', },
      ],
      items: [],
      attempt_delete: false,
      select_attempt: undefined,
    }
  },
  computed: {
    names: () => names,
  },
  watch: {},
  methods: {
    ...mapActions({
      loadAttemptList: 'admin/loadAttemptList',
      deleteAttempt: 'admin/deleteAttempt',
    }),
    initialize() {
      this.setLoading()
      this.loadData(undefined, true)
    },
    loadData(url=undefined, first=false){
      this.setLoading();
      this.loadAttemptList({
        url: url,
        finalizer: (val, url) => {
          this.setNotLoading();
          this.mapPagination(val)
          if(first)
            this.setCurrentLoadUrl(url);
          this.mapResults(val, 'items')
        }
      })
    },
    getItems (url=undefined) {
      loadData(url);
    },
    openDeleteAttemptDialog(attempt){
      this.attempt_delete = true
      this.select_attempt = attempt
    },
    closeDeleteAttemptDialog(){
      this.attempt_delete = false
      this.select_attempt = undefined
    },
    deleteAttemptAction(event){
      if (event){
        this.deleteAttempt({
          id: this.select_attempt.id,
          finalizer: () => {
            this.loadData(this.getCurrentLoadUrl());
            this.closeDeleteAttemptDialog();
          },
          catcher: (err) => {

          }
        }

        );
      } else {
        this.closeDeleteAttemptDialog();
      }
    },
    openDetail(item){
      router.push({
        name: names.ATTEMPTS.DETAIL,
        params: {idAttempt: item.id}
      })
    },
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>

</style>
