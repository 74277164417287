<template>
  <div class="page-content" style="margin: 0px; width: 100%">
      <Main
          v-if="$route.name === names.MAIN"
          :name_list='left_menu_list'
      ></Main>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import names from "./routers/names";
import Main from "./Main";
import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
import {mapGetters} from "vuex";


export default {
  name: "WatcherBase",
  components: {Main},
  data(){
    return {
      left_menu_list: [
        {title: 'Опросы', icon: 'format_list_numbered', included: true, router: {name: names.SURVEYS.LIST, params:{}}},
        {title: 'Попытки', icon: 'playlist_add_check', included: true, router: {name:names.ATTEMPTS.LIST, params:{}}},
        {title: 'Выгрузки', icon: 'bar_chart', included: true, router: {name:  names.FILE.LIST, params:{}}},
      ]
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated'
    })
  },
  methods: {
    loadLeftmenu() {
      this.$store.commit('mainpage/changeTitle', 'Наблюдатель');
      if (this.isAuthenticated) {
        this.$store.commit('leftmenu/replaceInstances', getInstMenu());
        this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.MAIN));
        this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
      } else {
        this.$store.commit('leftmenu/dissable');
      }
    },
    getLeftMenu() {
      let menu = []
      for (let el in this.left_menu_list){
        menu.push(this.left_menu_list[el])
      }
      return menu
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.loadLeftmenu()
    next()
  },
  created() {
    if (this.isAuthenticated) {
      this.$store.commit('leftmenu/enableLeftMenu');
    }
    this.loadLeftmenu()
  }
}
</script>

<style scoped>

</style>
