

import {makeGetRequest} from "@/helper/requests";
import choices from "@/urls/choices";
import selectors from "@/urls/selectors";
import {sendGetRequest} from "@/helper/requests";

function loadSelector(url, {commit, getters}, selector, finalizer=()=>{}, force=false) {
    let getFunction = `get${selector}Selector`;
    let setFunction = `set${selector}Selector`;
    let isFunction = `isLoading${selector}Selector`;
    let setLoading = `setIsLoading${selector}Selector`;
    let setNotLoading = `setIsNotLoading${selector}Selector`;
    if (force || (getters[getFunction].length === 0 && !getters[isFunction])) {
        commit(setLoading);
        makeGetRequest(
            url
        ).then(resp => {
            return resp.json()
        }).then(json => {
            commit(setFunction, json);
            commit(setNotLoading);
            finalizer(json);
        })
    } else {
        finalizer(getters[getFunction]);
    }
}



const state = {
    selectors: {
        kind: [],
        anonymity: [],
        field_type: [],
        date_value: [],
        coherent: [],
        fields: [],
        admin_fields: [],
        admin_choices: [],
        creator_fields: [],
        creator_choices: [],
        document: [],
    },
    loading: {
        kind: false,
        document: false,
        anonymity: false,
        field_type: false,
        date_value: false,
        coherent: false,
        fields: false,
        admin_fields: false,
        admin_choices: false,
        creator_fields: false,
        creator_choices: false,
    },
    kind_types: [],
    anonymity_types: [],
    field_types: [],
    date_value_types: [],
    coherent_types: [],

}

const getters = {
    isLoadingKindSelector: state => state.loading.kind,
    isLoadingDocumentSelector: state => state.loading.document,
    isLoadingAnonymitySelector: state => state.loading.anonymity,
    isLoadingFieldTypeSelector: state => state.loading.field_type,
    isLoadingDateValueSelector: state => state.loading.date_value,
    isLoadingCoherentSelector: state => state.loading.coherent,
    isLoadingAdminFieldsSelector: state => state.loading.admin_fields,
    isLoadingAdminChoicesSelector: state => state.loading.admin_choices,
    isLoadingCreatorFieldsSelector: state => state.loading.creator_fields,
    isLoadingCreatorChoicesSelector: state => state.loading.creator_choices,
    getKindSelector: state => state.selectors.kind,
    getDocumentSelector: state => state.selectors.document,
    getAnonymitySelector: state => state.selectors.anonymity,
    getFieldTypeSelector: state => state.selectors.field_type,
    getDateValueSelector: state => state.selectors.date_value,
    getCoherentSelector: state => state.selectors.coherent,
    getAdminFieldsSelector: state => state.selectors.admin_fields,
    getAdminChoicesSelector: state => state.selectors.admin_choices,
    getCreatorFieldsSelector: state => state.selectors.creator_fields,
    getCreatorChoicesSelector: state => state.selectors.creator_choices,
}

const mutations = {
    setKindSelector: (state, data) => {
        state.selectors.kind = data
    },
    setDocumentSelector: (state, data) => {
        state.selectors.document = data
    },
    setAnonymitySelector: (state, data) => {
        state.selectors.anonymity = data
    },
    setFieldTypeSelector: (state, data) => {
        state.selectors.field_type = data
    },
    setDateValueSelector: (state, data) => {
        state.selectors.date_value = data
    },
    setCoherentSelector: (state, data) => {
        state.selectors.coherent = data
    },
    setAdminFieldsSelector: (state, data) => {
        state.selectors.admin_fields = data
    },
    setAdminChoicesSelector: (state, data) => {
        state.selectors.admin_choices = data
    },
    setCreatorFieldsSelector: (state, data) => {
        state.selectors.creator_fields = data
    },
    setCreatorChoicesSelector: (state, data) => {
        state.selectors.creator_choices = data
    },
    setIsLoadingDocumentSelector: state => state.loading.document = true,
    setIsLoadingKindSelector: state => state.loading.kind = true,
    setIsLoadingAnonymitySelector: state => state.loading.anonymity = true,
    setIsLoadingFieldTypeSelector: state => state.loading.field_type = true,
    setIsLoadingDateValueSelector: state => state.loading.date_value = true,
    setIsLoadingCoherentSelector: state => state.loading.coherent = true,
    setIsLoadingAdminFieldsSelector: state => state.loading.admin_fields = true,
    setIsLoadingAdminChoicesSelector: state => state.loading.admin_choices = true,
    setIsLoadingCreatorFieldsSelector: state => state.loading.creator_fields = true,
    setIsLoadingCreatorChoicesSelector: state => state.loading.creator_choices = true,
    setIsNotLoadingKindSelector: state => state.loading.kind = false,
    setIsNotLoadingAnonymitySelector: state => state.loading.anonymity = false,
    setIsNotLoadingDocumentSelector: state => state.loading.document = false,
    setIsNotLoadingFieldTypeSelector: state => state.loading.field_type = false,
    setIsNotLoadingDateValueSelector: state => state.loading.date_value = false,
    setIsNotLoadingCoherentSelector: state => state.loading.coherent = false,
    setIsNotLoadingAdminFieldsSelector: state => state.loading.admin_fields = false,
    setIsNotLoadingAdminChoicesSelector: state => state.loading.admin_choices = false,
    setIsNotLoadingCreatorFieldsSelector: state => state.loading.creator_fields = false,
    setIsNotLoadingCreatorChoicesSelector: state => state.loading.creator_choices = false,
}

const actions = {
    loadKindSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            choices.CHOICES_SELECTOR.KIND_TYPE(),
            {commit, getters},
            'Kind',
            finalizer,
            force
        )
    },
    loadDocumentTypeSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            choices.CHOICES_SELECTOR.DOCUMENT_TYPE(),
            {commit, getters},
            'Document',
            finalizer,
            force
        )
    },
    loadAnonymitySelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            choices.CHOICES_SELECTOR.ANONYMITY_TYPE(),
            {commit, getters},
            'Anonymity',
            finalizer,
            force
        )
    },
    loadFieldTypeSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            choices.CHOICES_SELECTOR.FIELD_TYPE(),
            {commit, getters},
            'FieldType',
            finalizer,
            force
        )
    },

    loadDateTypesSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            choices.CHOICES_SELECTOR.DATE_DYNAMIC_TYPE(),
            {commit, getters},
            'DateValue',
            finalizer,
            force
        )
    },
    loadCoherentSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            choices.CHOICES_SELECTOR.COHERENT_TYPE(),
            {commit, getters},
            'Coherent',
            finalizer,
            force
        )
    },
    loadFieldsAdminSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            selectors.SELECTORS.ADMIN.FIELDS(),
            {commit, getters},
            'AdminFields',
            finalizer,
            force
        )
    },
    loadChoicesAdminSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            selectors.SELECTORS.ADMIN.CHOICES(),
            {commit, getters},
            'AdminChoices',
            finalizer,
            force
        )
    },
    loadFieldsCreatorSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            selectors.SELECTORS.CREATOR.FIELDS(),
            {commit, getters},
            'CreatorFields',
            finalizer,
            force
        )
    },
    loadChoicesCreatorSelector: ({commit, getters}, {finalizer, force}={finalizer: ()=>{}, force: false}) => {
        loadSelector(
            selectors.SELECTORS.CREATOR.CHOICES(),
            {commit, getters},
            'CreatorChoices',
            finalizer,
            force
        )
    },

    loadStageFieldFile: ({commit}, {id, key = null, finalizer, catcher}) => {
        let get = {}
        if (key) get['key'] = key
        sendGetRequest(
            selectors.SELECTORS.FILE.DOWNLOAD(id, get),
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
