<template>
  <choices-list-template
    v-if="$route.name === names.CHOICES.LIST"
    :load-choices="loadChoices"
    :delete-choice="deleteChoice"
  >
    <template v-slot:choices-form="slotProps">
      <ChoiceCreate
        :opened="slotProps.opened"
        :choice-data="slotProps.choiceData"
        :read-only="slotProps.readOnly"
        :clone-id="slotProps.cloneId"
        @close="slotProps.closeFunction"
      ></ChoiceCreate>
    </template>
  </choices-list-template>
</template>

<script>
import names from "../routers/names";
import {mapActions} from "vuex";
import ChoicesListTemplate from "@/modules/templates/choices/ChoicesListTemplate";
import ChoiceCreate from "@/modules/administrative/choice/components/ChoiceCreate";
import TitledPageMixin from "@/mixins/TitledPageMixin";

export default {
  name: "ChoiceList",
  mixins: [TitledPageMixin],
  components: {ChoicesListTemplate, ChoiceCreate},
  data() {
    return {
      page_title: "Списки выбора",
    }
  },
  computed: {
    names: () => names
  },
  watch: {},
  methods: {
    ...mapActions({
      loadChoices: 'admin/loadChoiceList',
      deleteChoice: 'admin/deleteChoice',
    }),
  },
  created() {
  }
}
</script>

<style scoped>
</style>