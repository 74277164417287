<template>
  <v-container class="page-content px-sm-5" style="width: 100%">
    <v-toolbar
        class="removeBtnPaddingRight rounded-t-lg"
        color="#DADADA"
        flat
    >
      <v-text-field
          single-line
          outlined
          dense
          clearable
          @click:clear="clearSearch()"
          :messages="$vuetify.breakpoint.smAndUp ? 'Для поиска нажмите Enter или кнопку поиск' : 'Для поиска нажмите Enter'"
          :class="$vuetify.breakpoint.smAndUp ? 'grow' : ''"
          v-model="search.value" label="Поиск" hide-details @keydown.enter="searchData">
      >
      </v-text-field>
      <v-spacer/>
      <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          icon
          color="black"
          class="mr-0"
          @click="newUser"
      >
        <v-icon>add</v-icon>
      </v-btn>
      <v-toolbar-items  v-if="$vuetify.breakpoint.mdAndUp">

        <v-btn
            class="dark-primary rounded-tr-lg"
            text
            @click="newUser"
        >
          Назначить пользователя
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
        :headers="headers"
        :items="items"
        :loading="pagination.loading"
        :footer-props="pagination.footer_page"
        :page.sync="pagination.page"
        :items-per-page.sync="pagination.size"
        :server-items-length="pagination.count"
        class="elevation-1"
    >
      <template v-slot:item.is_admin="{ item }">
        <v-icon
            :color="checkFlag(item.is_admin).color"
        >
          {{checkFlag(item.is_admin).icon}}
        </v-icon>
      </template>
      <template v-slot:item.is_creator="{ item }">
        <v-icon
            :color="checkFlag(item.is_creator).color"
        >
          {{checkFlag(item.is_creator).icon}}
        </v-icon>
      </template>
      <template v-slot:item.is_watch="{ item }">
        <v-icon
            :color="checkFlag(item.is_watch).color"
        >
          {{checkFlag(item.is_watch).icon}}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                class="mr-2"
                @click="editUser(item)"
            >
              edit
            </v-icon>
          </template>
          <span>Редактировать пользователя</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
                v-on="on"
                @click="deleteUser(item)"
            >
              delete
            </v-icon>
          </template>
          <span>Удалить пользователя</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="loadData()"
        >
          Обновить
        </v-btn>
      </template>
    </v-data-table>
    <EditDialog
        v-if="dialog_add_edit"
        :opened="dialog_add_edit"
        :edit="edit_status"
        :user-data="user_data"
        @close="closeEditDialog"
    ></EditDialog>
    <ApplyDialog
        v-if="dialog_delete"
        :opened="dialog_delete"
        :get-text="() => `Вы уверены, что хотите удалить данного пользователя ?`"
        @close="closeDeleteDialog"
    ></ApplyDialog>
  </v-container>
</template>

<script>

import {mapActions} from "vuex";
import {loadData} from "@/helper/requests.js";
import {generateListUidToName} from "@/helper/uidToName";
import PaginatedDataMapperMixin from "@/mixins/PaginatedDataMapperMixin";
import TitledPageMixin from "@/mixins/TitledPageMixin";
import EditDialog from "./components/EditDialog";
import ApplyDialog from "@/modules/core/components/ApplyDialog";



export default {
  name: "UserList",
  components: {EditDialog, ApplyDialog},
  mixins: [TitledPageMixin,PaginatedDataMapperMixin],
  data() {
    return {
      items:[],
      pagination: {
      },
      search: {
        parameter: 'search',
      },
      page_title: "Список пользователей",
      select_type: "all",
      user_type: [
        {type: "all", name: "Все"},
        {type: "is_admin", name: "Администраторы"},
        {type: "is_creator", name: "Создатели"},
        {type: "is_watch", name: "Наблюдатели"},
      ],
      dialog_add_edit: false,
      dialog_delete: false,
      headers: [
        {text: 'ФИО', value: 'fio', sortable: false, align: 'start', width: '15%'},
        { text: 'Подразделение', value: 'department', width: '15%', sortable: false},
        { text: 'Администратор', value: 'is_admin', width: '10%', sortable: false},
        { text: 'Создатель', value: 'is_creator', width: '10%', sortable: false},
        { text: 'Наблюдатель', value: 'is_watch', width: '10%', sortable: false},
        { text: 'Действия', value: 'actions', sortable: false, width: '10%'},
      ],
      user_data: undefined,
      edit_status: undefined,
      user_index: -1,
    }
  },

  watch: {
  },
  methods: {
    ...mapActions({
      loadUserRoleList: 'admin/loadUserRoleList',
      deleteUserRole: 'admin/deleteUserRole'
    }),
    clearSearch(){
      this.search.value = null
      this.loadData(undefined, true)
    },
    loadData(url=undefined, first=false){
      this.setLoading();
      this.loadUserRoleList({
        url: url,
        finalizer: (val, url) => {
          this.mapPagination(val)
          if(first)
            this.setCurrentLoadUrl(url);
          this.mapResults(val, 'items')
          this.setNotLoading()
        }
      })
    },
    getItems (url=undefined) {
      loadData(url);
    },

    newUser() {
      this.dialog_add_edit = true
      this.edit_status = false
      this.user_data = undefined
    },
    editUser(item) {
      this.dialog_add_edit = true
      this.edit_status = true
      this.user_data = item
    },
    deleteUser(item) {
      this.dialog_delete = true
      this.user_data = item
    },
    closeEditDialog(event) {
      this.loadData()
      this.dialog_add_edit = false
      this.user_data = undefined
    },
    closeDeleteDialog(event) {
      if(event){
        this.deleteUserRole({
          id: this.user_data.id,
          finalizer: (val) => {
            this.loadData(this.getCurrentLoadUrl())
          },
          catcher:(val) => {
            this.mapErrors(val)
          },
        })
      }
      this.dialog_delete = false
      this.user_data = undefined
    },
    checkFlag(flag) {
      if (flag) return {icon: "check_circle", color: "green"}
      else return {icon: "not_interested", color: "gray"}
    },
    initialize() {
      this.setLoading()
      this.loadData(undefined, true)
    },
  },
  created () {
    this.initialize()
  },
}
</script>

<style scoped>
.removeBtnPaddingRight>>>.v-toolbar__content{
  padding-right: 0px;
}
</style>
