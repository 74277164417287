
import surveying from "@/urls/roles/surveying";

import {
    sendGetRequest,
    sendPostRequest,
    sendPatchRequest,
    sendPostRequestFile
} from "@/helper/requests";

const state = {

}

const getters = {

}

const mutations = {
    set_survey: (state, data) => {
        state.surveys = data
    },
    set_survey_data:  (state, data) => {
        state.survey_detail = data
    },
}

const actions = {
    createAttempt: ({commit}, {data, key = null, finalizer, catcher}) => {
        let get = {}
        if (key) get['special_link_key'] = key
        sendPostRequest(
          surveying.SURVEYING.ATTEMPT.CREATE(key, get),
          data,
          finalizer ? finalizer : () => {},
          catcher ? catcher : () => {},
        )
    },
    CheckAttempt: ({commit}, {survey_id, email=undefined, key = null, finalizer, catcher}) => {
        let get = {}
        if (email) get['email'] = email
        if (key) get['special_link_key'] = key
        sendGetRequest(
            surveying.SURVEYING.ATTEMPT.CHECK(survey_id, get),
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },
    endAttempt: ({commit}, {id, data, finalizer, catcher}) => {
        sendPatchRequest(
            surveying.SURVEYING.ATTEMPT.END(id),
            data,
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },
    LoadSurveys: ({commit}, {url,finalizer, catcher}) => {
        if (!url){
            url = surveying.SURVEYING.SURVEY.LIST();
        }
        sendGetRequest(
            url,
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },
    LoadSurveyDetail: ({commit}, {survey_id, key = null, finalizer, catcher}) => {
        let get = {}
        if (key) get['special_link_key'] = key
        sendGetRequest(
            surveying.SURVEYING.SURVEY.DETAIL(survey_id, get),
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },
    StageList: ({commit}, {attempt_id, attempt_hash, finalizer, catcher}) => {
        let get = {'size': 100}
        if (attempt_hash) get['attempt_hash'] = attempt_hash
        sendGetRequest(
            surveying.SURVEYING.ATTEMPT.STAGE_LIST(attempt_id, get),
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },
    StageFieldList: ({commit}, {attempt_id, attempt_hash, stage_id, finalizer, catcher}) => {
        let get = {'size': 100}
        if (attempt_hash) get['attempt_hash'] = attempt_hash
        sendGetRequest(
            surveying.SURVEYING.ATTEMPT.FIELD_LIST(attempt_id, stage_id, get),
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },
    SurveyingSave: ({commit}, {attempt_id, stage_id, data, finalizer, catcher}) => {
        sendPostRequestFile(
            surveying.SURVEYING.ATTEMPT.STAGE_SAVE(attempt_id, stage_id),
            data,
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },
    SaveAnswerList: ({commit}, {attempt_id, stage_id, finalizer, catcher}) => {
        sendGetRequest(
            surveying.SURVEYING.ATTEMPT.ANSWER_LIST(attempt_id, stage_id),
            finalizer ? finalizer : () => {},
            catcher ? catcher : () => {},
        )
    },

}


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
