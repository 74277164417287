import router from "@/router";
import surveys from "@/urls/roles/admin";

import {
  sendPostRequest, sendGetRequest, sendPatchRequest, sendDeleteRequest, makeGetRequest, finalizeRequest
} from "@/helper/requests";
import names from "@/modules/administrative/routers/names";

const state = {
}

const getters = {

}

const mutations = {

}

const actions = {
  //ADMIN
  loadSurveyList: ({commit}, {url, finalizer, catcher}) => {
    if (!url)
      url = surveys.ADMIN.SURVEY.LIST()
    sendGetRequest(
      url,
      (data) => finalizer(data, url),
      catcher
    )
  },
  createSurvey: ({commit}, {data, finalizer, catcher}) => {
    let url = surveys.ADMIN.SURVEY.LIST()
    sendPostRequest(
      url, data, finalizer, catcher,
    )
  },
  duplicateSurvey: ({commit}, {id, finalizer, catcher}) => {
    let url = surveys.ADMIN.SURVEY.DUPLICATE(id)
    sendPostRequest(url, null, finalizer, catcher)
  },
  updateSurvey: ({commit}, {data, id, finalizer, catcher}) => {
    let url = surveys.ADMIN.SURVEY.EDIT(id)
    sendPatchRequest(
      url, data, finalizer, catcher
    )
  },
  loadSurveyDetail: ({commit, getters}, {id, finalizer, catcher}) => {
    let url = surveys.ADMIN.SURVEY.DETAIL(id)
    sendGetRequest(
      url,
      finalizer,
      catcher
    )
  },
  deleteSurvey: ({commit}, {item, finalizer, catcher}) => {
    let url = surveys.ADMIN.SURVEY.DELETE(item.id)
    sendDeleteRequest(url, finalizer, catcher)
  },
  loadStatisticFile: ({commit}, {id,get, finalizer, catcher}) => {
    let url = surveys.ADMIN.FILE.UNLODING(id, get)
    sendGetRequest(
        url,
        finalizer,
        catcher
    )
  },
  surveyGetKindParam: ({commit}, {survey_id, search, finalizer, catcher}) => {
    let get = {}
    if (search) get['search'] = search
    let url = surveys.ADMIN.KIND_PARAM.GET_KIND_PARAM(survey_id, get)
    sendGetRequest(
        url,
        finalizer ? finalizer : () => {},
        catcher ? catcher : () => {},
    )
  },
  surveyListKindParam: ({commit}, {survey_id, finalizer, catcher}) => {
    let url = surveys.ADMIN.KIND_PARAM.LIST(survey_id)
    sendGetRequest(
        url,
        finalizer,
        catcher
    )
  },
  surveyCreateKindParam: ({commit}, {survey_id, data, finalizer, catcher}) => {
    let url = surveys.ADMIN.KIND_PARAM.CREATE(survey_id)
    sendPostRequest(
        url,
        data,
        finalizer,
        catcher
    )
  },
  surveyDeleteKindParam: ({commit}, {survey_id, id, finalizer, catcher}) => {
    let url = surveys.ADMIN.KIND_PARAM.DELETE(survey_id, id)
    sendDeleteRequest(
        url,
        finalizer,
        catcher
    )
  },
  surveyDeleteAllKindParam: ({commit}, {survey_id, finalizer, catcher}) => {
    let url = surveys.ADMIN.KIND_PARAM.DELETEALL(survey_id)
    sendDeleteRequest(
        url,
        finalizer,
        catcher
    )
  },
  personalLinkList: ({commit}, {survey_id, finalizer, catcher}) => {
    let url = surveys.ADMIN.INVITE.LIST(survey_id)
    sendGetRequest(
        url,
        finalizer,
        catcher
    )
  },
  personalLinkCreate: ({commit}, {survey_id, data, finalizer, catcher}) => {
    let url = surveys.ADMIN.INVITE.CREATE(survey_id)
    sendPostRequest(
        url,
        data,
        finalizer,
        catcher
    )
  },
  personalLinkDetail: ({commit}, {survey_id, id, finalizer, catcher}) => {
    let url = surveys.ADMIN.INVITE.DETAIL(survey_id, id)
    sendGetRequest(
        url,
        finalizer,
        catcher
    )
  },
  personalLinkDelete: ({commit}, {survey_id, id, finalizer, catcher}) => {
    let url = surveys.ADMIN.INVITE.DELETE(survey_id, id)
    sendDeleteRequest(
        url,
        finalizer,
        catcher
    )
  },
  surveySendInvite: ({commit}, {data, survey_id, finalizer, catcher}) => {
    let url = surveys.ADMIN.INVITE.SEND(survey_id)
    sendPostRequest(
        url,
        data,
        finalizer,
        catcher
    )
  },

}


export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
