<template>
  <v-container>
    <v-row
      v-for="(field, index) in fields"
      :key="`field_${index}_${field.id}`"
    >
      <v-col>
        <edit-survey-stage-field-template
          :field="field"
          @delete="openDeleteFieldDialog(field)"
          @moveUp="moveUp(index)"
          @moveDown="moveDown(index)"
          @save="saveFields(field.id)"
        >
          <template v-slot:edit-field>
            <edit-field
              :ref="`field_form_${field.id}`"
              :errors="errors.hasOwnProperty(field.id) ? errors[field.id]: undefined"
              v-model="fields[index]"
            ></edit-field>
          </template>
        </edit-survey-stage-field-template>
      </v-col>
    </v-row>
    <ApplyDialog
      v-if="field_delete"
      :opened="field_delete"
      :get-text="() => `Вы уверены, что хотите удалить данное поле ?`"
      @close="deleteField"
    ></ApplyDialog>

  </v-container>
</template>

<script>
import names from "@/modules/administrative/routers/names";
import {mapActions} from "vuex";
import EditSurveyStageFieldTemplate
  from "@/modules/templates/survey/edit/EditSurveyStageFieldTemplate";
import EditField from "@/modules/administrative/survey/components/edit/EditField";
import ApplyDialog from "@/modules/core/components/ApplyDialog";
import {finalizeRequest, makePostRequestFile} from "@/helper/requests";
import surveys from "@/urls/roles/admin";

export default {
  name: "StageFields",
  components: {
    EditSurveyStageFieldTemplate,
    ApplyDialog,
    EditField
  },
  props: {
    stageId: {
      required: true,
    }
  },
  data(){
    return {
      fields: [],
      errors: {},
      field_delete: false,
      select_field: undefined,
      // error: {
      //   show: false,
      //   text: '',
      // },
    }
  },
  watch: {
    test_data (val) {
      this.updateStageFields(val)
    },
  },
  computed: {
    names: () => names,
    survey_detail_link(){
      return {
        name: names.SURVEYS.DETAIL,
        params: {idSurvey: this.$route.params.idSurvey}
      }
    },
  },
  methods: {
    ...mapActions({
      updateStageField: 'admin/updateStageField',
      deleteStageField: 'admin/deleteStageField',
      listStageField: 'admin/listStageField',
    }),
    loadFields(){
      this.listStageField({
        survey_id: this.$route.params.idSurvey,
        stage_id: this.stageId,
        finalizer: (val) => {
          this.fields = [];
          this.$nextTick(() => {
            this.fields = val;
          })
        }
      })
    },
    saveFields(saved_field_id=undefined){
      this.errors = {}
      let save_promises = [];
      this.fields.forEach((field) => {
        if (saved_field_id === undefined || saved_field_id === field.id){
          if (this.$refs[`field_form_${field.id}`][0].validate()){
            save_promises.push(
              new Promise((resolve) => {
                this.updateStageField({
                  data: field,
                  survey_id: this.$route.params.idSurvey,
                  stage_id: this.stageId,
                  finalizer: () => {
                    resolve();
                  },
                  catcher: (errors) => {
                    this.errors[field.id] = errors;
                    resolve();
                  }
                })
              })
            )
          }
        }
      })
      Promise.all(save_promises).then(() => {
        this.loadFields();
      })
    },
    openDeleteFieldDialog(field){
      this.select_field = field
      this.field_delete = true
    },
    closeDeleteFieldDialog(){
      this.field_delete = false
      this.select_field = undefined
    },
    deleteField(apply){
      if (apply) {
        this.deleteStageField({
          survey_id: this.$route.params.idSurvey,
          stage_id: this.stageId,
          id: this.select_field.id,
          finalizer: () => {
            this.loadFields()
            this.closeDeleteFieldDialog();
          }
        })
      }
      else{
        this.closeDeleteFieldDialog();
      }
    },

    moveUp(index){
      if (index > 0){
        [this.fields[index-1].number, this.fields[index].number] = [this.fields[index].number, this.fields[index-1].number];
        [this.fields[index-1], this.fields[index]] = [this.fields[index], this.fields[index-1]]
        this.fields.sort((a, b) => {
          return a.number - b.number
        })
        let number = 0
        for (let st of this.fields){
          st.number = number +1
          number = number +1
        }
        this.saveFields()
      }
    },
    moveDown(index){
      if (index < this.fields.length - 1){
        [this.fields[index + 1].number, this.fields[index].number] = [this.fields[index].number, this.fields[index + 1].number];
        [this.fields[index + 1], this.fields[index]] = [this.fields[index], this.fields[index + 1]]
        this.fields.sort((a, b) => {
          return a.number - b.number
        })
        let number = 0
        for (let st of this.fields){
          st.number = number +1
          number = number +1
        }
        this.saveFields()
      }
    },
  },
  created() {
    this.loadFields();
  }
}
</script>

<style scoped>

</style>
