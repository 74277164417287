<template>
  <v-sheet width="100%">
    <template v-for="(stage, index) in stages">
      <stage-expand
        ref="stages"
        :key="`stage_${stage.id}`"
        :value="stages[index]"
        :stage-index="index"
        :survey-id="surveyId"
        :stage-count="stages.length"
        :loading="isLoading"
        @moveUp="moveUp"
        @moveDown="moveDown"
        @deleted="loadStages()"
        @update="loadStages()"
      ></stage-expand>
    </template>
    <add-stage
      v-if="add_dialog"
      :opened="add_dialog"
      @close="closeAddStage"
    ></add-stage>
  </v-sheet>

</template>

<script>
import StageExpand from "@/modules/administrative/survey/components/edit/StageExpand";
import names from "@/modules/administrative/routers/names";
import {mapActions} from "vuex";
import AddStage from "@/modules/administrative/survey/components/dialogs/AddStage";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "StagesComponent",
  mixins: [
    LoadingMixin,
  ],
  components: {
    AddStage,
    StageExpand
  },
  props: {
    surveyId: {
      required: true,
    },
    // loading: {
    //   required: false,
    //   default: false
    // },
  },
  data(){
    return {
      self_loading: false,
      stages: [],
      errors: {},
      add_dialog: false,
    }
  },
  watch: {
    test_data (val) {
      this.updateStageFields(val)
    },
    stage: {
      deep: true,
      handler() {
        this.$emit('input', this.stage)
      }
    }
  },
  computed: {
    names: () => names,
    survey_detail_link(){
      return {
        name: names.SURVEYS.DETAIL,
        params: {idSurvey: this.$route.params.idSurvey}
      }
    },
  },
  methods: {
    ...mapActions({
      loadStageList: 'admin/loadStageList',
      deleteStage: 'admin/deleteStage',
      updateStage: 'admin/updateStage',
    }),
    // setLoading(){
    //   this.$emit('update:loading', true);
    // },
    // setNotLoading(){
    //   this.$emit('update:loading', true);
    // },
    loadStages(){
      this.setLoading();
      this.loadStageList({
        survey_id: this.$route.params.idSurvey,
        finalizer: (val) => {
          this.setNotLoading();
          this.stages = val
        }
      })
    },
    saveStages(){
      this.setLoading()
      let promises = []
      for (let stage of this.stages){
        promises.push(
          new Promise(resolve => {
            this.updateStage({
              data: stage,
              survey_id: this.surveyId,
              finalizer: resolve
            })
          })
        )
        Promise.all(promises).then(()=>{
          this.loadStages()
          this.setNotLoading()
        })
      }
    },
    fullSaveStages(){
      for (let stage of this.$refs.stages) {
        stage.saveStage();
        stage.saveFields();
      }
    },
    openAddStage(){
      this.add_dialog = true;
    },
    closeAddStage(event) {
      if (event) {
        this.loadStages();
      }
      this.add_dialog = false;
    },

    saveFields(){
      for (let stage of this.$refs.stages){
        stage.saveFields()
      }
    },

    moveUp(stage_id){
      let stages = [...this.stages]
      let index = stages.findIndex(el => stage_id === el.id)
      if (index > 0){
        [stages[index-1].number, stages[index].number] = [stages[index].number, stages[index-1].number];
        [stages[index-1], stages[index]] = [stages[index], stages[index-1]]
        stages.sort((a, b) => {
          return a.number - b.number
        })
        this.stages = stages;
        this.saveStages(stages);
      }
    },
    moveDown(stage_id){
      let stages = [...this.stages]
      let index = stages.findIndex(el => stage_id === el.id)
      if (index < stages.length-1){
        [stages[index+1].number, stages[index].number] = [stages[index].number, stages[index+1].number];
        [stages[index+1], stages[index]] = [stages[index], stages[index+1]]
        stages.sort((a, b) => {
          return a.number - b.number
        })
        this.stages = stages;
        this.saveStages(stages);
      }
    },
  },
  created() {
    this.loadStages();
  }
}
</script>

<style scoped>

</style>
