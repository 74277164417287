<template>
  <div>
    <v-select
      :label="bound_type_label"
      :items="BOUND_CHOICES"
      v-model="bound_type"
      :hint="bound_type === CONSTANTS.NONE_BOUND ? '\n'+
'Отсутствуют\n'+
'Если не указаны ограничения, то поле может принимать любое значение даты' : ''"
      :persistent-hint="bound_type === CONSTANTS.NONE_BOUND"
    ></v-select>
    <v-expand-transition>
      <transition-group>
        <v-select
          key="dynamic_bound_type"
          v-show="bound_type === CONSTANTS.DYNAMIC_BOUND"
          v-model="values.date_dynamic_value_type"
          :label="dynamic_type_label"
          :items="date_value_types"
        ></v-select>
        <v-row no-gutters key="dynamic_upper_bound-dynamic_lower_bound">
          <v-col md="6" sm="12" class="pr-2">
            <v-text-field
              key="dynamic_upper_bound"
              v-show="bound_type === CONSTANTS.DYNAMIC_BOUND"
              v-model="values.date_dynamic_upper"
              label="Верхнее ограничение"
              :error-messages="getError('date_dynamic_upper')"
              :error="hasError('date_dynamic_upper')"
              :hint="dynamic_upper_label"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col md="6" sm="12" class="pl-2">
            <v-text-field
              key="dynamic_lower_bound"
              v-show="bound_type === CONSTANTS.DYNAMIC_BOUND"
              v-model="values.date_dynamic_lower"
              label="Нижнее ограничение"
              :error-messages="getError('date_dynamic_lower')"
              :error="hasError('date_dynamic_lower')"
              :hint="dynamic_lower_label"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters key="static_upper_bound-static_lower_bound">
          <v-col md="6" sm="12" class="pr-2">
            <date-picker
              key="static_upper_bound"
              v-if="bound_type === CONSTANTS.STATIC_BOUND"
              v-model="values.date_static_upper"
              label="Верхнее ограничение"
              :error-messages="getError('date_static_upper')"
              :error="hasError('date_static_upper')"
              :description="static_upper_label"
            ></date-picker>
          </v-col>
          <v-col md="6" sm="12" class="pl-2">
            <date-picker
              key="static_lower_bound"
              v-if="bound_type === CONSTANTS.STATIC_BOUND"
              v-model="values.date_static_lower"
              :error-messages="getError('date_static_lower')"
              :error="hasError('date_static_lower')"
              label="Нижнее ограничение"
              :description="static_lower_label"
            ></date-picker>
          </v-col>
        </v-row>
      </transition-group>
    </v-expand-transition>
  </div>
</template>

<script>
import DatePicker from "@/modules/templates/custom_fields/DatePicker";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import {mapGetters} from "vuex";

export default {
  name: "DateWithBounds",
  components: {DatePicker},
  mixins: [FormErrorsMixin],
  props: {
    labels: {
      required: false,
      type: Object,
      default: undefined,
    },
    value: {
      required: true,
      type: Object,
      default: () => {return {}},
    },
    error: {
      required: false,
      default: false
    },
    allErrorMessages: {
      required: false,
      default: () => {return []}
    }
  },
  data(){
    const CONSTANTS = {
      NONE_BOUND: 1,
      DYNAMIC_BOUND: 2,
      STATIC_BOUND: 3,
    }
    return {
      CONSTANTS,
      BOUND_CHOICES: [
        {value: CONSTANTS.NONE_BOUND, text: 'Отсутствуют'},
        {value: CONSTANTS.DYNAMIC_BOUND, text: 'Динамические'},
        {value: CONSTANTS.STATIC_BOUND, text: 'Статические'},
      ],
      bound_type: CONSTANTS.NONE_BOUND,
      values: {
        date_dynamic_value_type: this.value.date_dynamic_value_type,
        date_dynamic_upper: this.value.date_dynamic_upper,
        date_dynamic_lower: this.value.date_dynamic_lower,
        date_static_upper: this.value.date_static_upper,
        date_static_lower: this.value.date_static_lower,
      }
    }
  },
  watch: {
    values: {
      deep: true,
      handler() {
        this.$emit('input', this.values);
      }
    },
    allErrorMessages: {
      deep: true,
      handler() {
        console.log(this.allErrorMessages)
        this.errors = this.allErrorMessages
      }
    }
  },
  computed: {
    ...mapGetters({
      date_value_types: 'selectors/getDateValueSelector',
    }),
    bound_type_label(){
      return this.getFromLabels('bound_type', 'Тип граничных условий');
    },
    dynamic_type_label(){
      return this.getFromLabels('dynamic_type', 'Тип значения отступа динамической границы');
    },
    dynamic_upper_label(){
      return this.getFromLabels('dynamic_upper', 'Верхний отступ от текущей даты для динамического ограничения');
    },
    dynamic_lower_label(){
      return this.getFromLabels('dynamic_lower', 'Нижний отступ от текущей даты для динамического ограничения');
    },
    static_upper_label(){
      return this.getFromLabels('static_upper', 'Дата верхнего статического ограничения');
    },
    static_lower_label(){
      return this.getFromLabels('static_lower', 'Дата нижнего статического ограничения');
    },
  },
  methods: {
    validate(){
      return true
    },
    getFromLabels(field, default_value){
      if(this.labels !== undefined && this.labels !== null){
        if(this.labels[field] !== undefined && this.labels[field] !== null){
          return this.labels[field];
        }
      }
      return default_value
    },
    valueHasDynamicBound() {
      return !!this.value.date_dynamic_value_type || !!this.values.date_dynamic_upper || !!this.values.date_dynamic_lower
    },
    valueHasStaticBound () {
      return !!this.values.date_static_upper || !!this.values.date_static_lower
    }
  },
  created() {
    this.$store.dispatch(
      'selectors/loadDateTypesSelector',
    )
    if (this.valueHasStaticBound()) {
      this.bound_type = this.CONSTANTS.STATIC_BOUND;
    }
    if (this.valueHasDynamicBound()) {
      this.bound_type = this.CONSTANTS.DYNAMIC_BOUND;
    }
    // this.errors = this.errorMessage;
  }
}
</script>

<style scoped>

</style>
