<template>
  <v-card
    flat
    min-height="300"
  >
    <v-row class="mb-1 mr-2">
      <v-col cols="10" sm="11" class="pl-0">
        <slot name="edit-field"></slot>
<!--        <edit-field-->
<!--          v-model="field"-->
<!--        ></edit-field>-->
      </v-col>
      <v-col cols="2" sm="1">
        <v-speed-dial
          v-model="field.info"
          open-on-hover
          direction="bottom"
        >
          <template v-slot:activator>
            <v-btn :disabled="draft" depressed fab outlined>
              <v-icon v-if="field.info">close</v-icon>
              <v-icon v-else>more_horiz</v-icon>
            </v-btn>
          </template>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn  v-bind="attrs" :disabled="draft"  v-on="on" small depressed fab outlined @click="$emit('moveUp')">
                <v-icon>arrow_upward</v-icon>
              </v-btn>
            </template>
            <span>Переместить поле вверх</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" :disabled="draft" small depressed fab outlined @click="$emit('moveDown')">
                <v-icon>arrow_downward</v-icon>
              </v-btn>
            </template>
            <span>Переместить поле вниз</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"  v-on="on" :disabled="draft" small depressed fab outlined @click="$emit('delete')">
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <span>Удалить поле</span>
          </v-tooltip>

        </v-speed-dial>
        <v-btn
          v-if="!field.info"
          class="mt-3"
          outlined
          :disabled="draft"
          fab
          color="teal"
          @click="$emit('save')"
        >
          <v-icon>save</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "EditSurveyStageFieldsTemplate",
  props: {
    draft: {
      default: false
    },
    field: {
      required: true
    }
  },
  data(){
    return {
      // field: this.value,
    }
  },
  computed: {},
  watch: {
    // field: {
    //   deep: true,
    //   handler() {
    //     this.$emit('input', this.field)
    //   }
    // }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
