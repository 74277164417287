import surveys from "@/urls/roles/admin";
import {
  sendGetRequest, sendDeleteRequest
} from "@/helper/requests";

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
  //ATTEMPT
  loadAttemptList: ({commit}, {url,finalizer, catcher}) => {
    if (!url) url = surveys.ADMIN.ATTEMPTS.LIST()
    sendGetRequest(
      url,
      (data) => finalizer(data, url),
      catcher
    )
  },
  loadAttemptDetail: ({commit}, {id, finalizer, catcher}) => {
    let url = surveys.ADMIN.ATTEMPTS.DETAIL(id);
    sendGetRequest(
      url,
      finalizer,
      catcher
    )
  },
  loadAttemptStageList: ({commit}, {id, finalizer, catcher}) => {
    let url = surveys.ADMIN.ATTEMPTS.STAGES.LIST(id);
    sendGetRequest(
      url,
      finalizer,
      catcher
    )
  },
  loadAttemptStageFieldList: ({commit}, {id, stage_id, finalizer, catcher}) => {
    let url = surveys.ADMIN.ATTEMPTS.STAGES.FIELDS(id, stage_id);
    sendGetRequest(
      url,
      finalizer,
      catcher
    )
  },
  deleteAttempt: ({commit}, {id, finalizer, catcher}) => {
    let url = surveys.ADMIN.ATTEMPTS.DELETE(id)
    sendDeleteRequest(url, finalizer, catcher)
  },
}


export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
