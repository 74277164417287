import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store/index'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '../public/css/main.css'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import DatetimePicker from 'vuetify-datetime-picker'
import VueCookie from 'vue-cookies';
import VueYandexMetrika from 'vue-yandex-metrika'
import {checkIsProductionServer,checkIsDevelopmentServer} from "../src/helper/index";
import VueMask from 'v-mask';
import * as Sentry from "@sentry/vue";

Vue.use(VueMask);
Vue.use(VueCookie);
Vue.use(DatetimePicker);
Sentry.init({
  Vue,
  dsn: "https://56611490faa3c1770f874bca3b51cbc6@sentry.muctr.ru/13",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
});
if(checkIsProductionServer())
    Vue.use(VueYandexMetrika, {
        id: process.env.VUE_APP_YandexMetrika,
        router: router,
        env: 'production',
        options:{
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
        }
    })

Vue.use(VueBreadcrumbs, {
    mixins: [
        {
            methods: {
                getRoute: function f(crumb) {
                    return {name: crumb.name, params: this.$route.params};
                }
            }
        }
    ],
    template: `
      <nav class="breadcrumb text-left" v-if="$breadcrumbs.length" xmlns="http://www.w3.org/1999/html">
      <template v-for="(crumb, i) in $breadcrumbs">
        <v-chip
            outlined
            class="breadcrumb-item-chips mb-2 my-2"
            color="#032F59"
        >
          <router-link
              class="breadcrumb-item"
              :to="getRoute(crumb)"
              style="text-decoration: none; color: inherit"
          >
            {{ getBreadcrumb(crumb.meta.breadcrumb) }}
          </router-link>
        </v-chip>
        <span v-if="$breadcrumbs.length - 1 !== i"
              class="pa-0 ma-0"
        >
        <v-icon
            class="my-2 dark-primary"
        >
          chevron_right
        </v-icon>
      </span>
      </template>
      </nav>
    `
});

Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');

