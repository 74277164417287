<template>
  <scroll-center-dialog
      v-model="dialog_flag"
      @close="closeDialog"
      title="Опрос"
      :applyable="false"
  >
    <template>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>bookmark</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="text-left text-wrap">
          {{dialog_data.name}}
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>description</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-left" v-html="urlify(dialog_data.description)"></v-list-item-content>
      </v-list-item>
      <div v-if="(!isAuthenticate) && !dialog_data.anonymity==0">
        <div v-if="dialog_data.kind !== '0'" >
            <v-alert outlined type="info"
                     v-if=" (dialog_data.anonymity==0||(dialog_data.anonymity==2 && anonym_status))">
              Важно! Для подтверждения статуса "Работник/Обучающийся" необходимо авторизоваться в системе.
              При этом сбор персональных данных (ФИО) не выполняется, результаты опроса будут полностью анонимны.
            </v-alert>
          </div>
        </div>
      <v-alert outlined type="info" v-if="(dialog_data.anonymity==2 && !anonym_status)">
        При выборе анонимного прохождения опроса сбор персональных данных (ФИО) не выполняется, результаты опроса будут полностью анонимны
      </v-alert>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>visibility</v-icon>
        </v-list-item-icon>
        <v-list-item-content  class=" text-left">
          <v-list-item-title v-if="dialog_data.anonymity==0">Анонимно</v-list-item-title>
          <v-list-item-title v-if="dialog_data.anonymity==1">Не анонимно</v-list-item-title>
          <v-list-item-title v-if="dialog_data.anonymity==2">На выбор пользователя</v-list-item-title>
          <v-list-item-subtitle>Анонимность опроса</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-row class="justify-center text-left">
        <v-col
            cols="12"
             :md="dialog_data.anonymity === '2' ? 4 : 6"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>event</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{displayDate(dialog_data.publication_date)}}</v-list-item-title>
              <v-list-item-subtitle>Дата начала</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col
            cols="12"
            :md="dialog_data.anonymity === '2' ? 4 : 6"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>event_available</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{displayDate(dialog_data.end_date)}}</v-list-item-title>
              <v-list-item-subtitle>Дата окончания</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col
            v-if="dialog_data.anonymity === '2'"
            cols="12"
            md="4"
        >
          <v-list-item>
            <v-checkbox
                v-model="anonym_status"
                label="Анонимный опрос"
            ></v-checkbox>
          </v-list-item>
        </v-col>
      </v-row>
      <v-sheet>
        <v-alert type="error" v-for="(error, index) in getNonFieldErrors()" rounded="0" dismissible @input="deleteNonFieldError(error)" :key="`error_${index}`">
          {{error[0]}}
        </v-alert>
      </v-sheet>
    </template>
    <v-card-text  v-if="!available">
      <v-alert outlined type="info" class="text-h6">
        Данный опрос недоступен
      </v-alert>
    </v-card-text>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn-toggle style="min-width: 300px" v-if="!isAuthenticate && attempt_status === undefined">
        <v-text-field v-model="email" outlined clearable dense hide-details label="Введите почту" :loading="loading"></v-text-field>
        <v-btn text height="40px" @click="checkView" :loading="loading">Продолжить</v-btn>
      </v-btn-toggle>
      <span  v-if="available">
        <v-btn
                  v-if="attempt_status === false"
                  color="success"
                  depressed
                  @click="openSurveying"
                  :loading="loading"
              >
        Начать опрос
      </v-btn>
      <v-btn
          v-if="attempt_status && attempt_ended === false"
          color="success"
          depressed
          @click="continueSurveying"
          :loading="loading"
      >
        Продолжить опрос
      </v-btn>
      </span>

      <span
          v-if="attempt_status && attempt_ended === true"
          style="font-size: 20px; margin-top: -20px; padding-bottom: 10px"
      >
        Опрос пройден
      </span>
      <v-spacer></v-spacer>
    </template>
  </scroll-center-dialog>
</template>

<script>
import names from "../routers/names";
import {mapActions, mapGetters} from "vuex"
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import ScrollCenterDialog from "@/modules/templates/ScrollCenterDialog";
import {displayDate, urlify} from "@/helper";

export default {
  name: "BeforeSurvey",
  mixins: [FormErrorsMixin],
  components: {ScrollCenterDialog},
  props: {
    dialog_flag: Boolean,
    dialog_data: Object,
  },
  data(){
    return {
      email: undefined,
      attempt_status: undefined,
      attempt_check: undefined,
      attempt_ended: undefined,
      loading: false,
      available: true,
      anonym_status: false,
      rules: {
        required: (value) => !value && value !== ''
      }
    }
  },
  watch:{
    email(){
      this.errors.non = [];
    },
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticate: 'auth/isAuthenticated'
    })
  },
  methods: {
    urlify,
    displayDate:displayDate,
    ...mapActions({
      checkAttempt: "surveying/CheckAttempt",
      createAttempt: "surveying/createAttempt"
    }),
    checkView() {
      this.loading = true;
      this.checkAttempt({
        survey_id: this.dialog_data.id,
        email: this.email !== undefined && this.email !== '' ? this.email: undefined,
        finalizer: (val) => {
          this.attempt_status = val.attempt_status
          this.attempt_ended = val.attempt_ended
          this.available = val.available
          this.attempt_check = val
          this.loading = false;
        },
        catcher:(val) => {
          this.loading = false;
          this.errors.non = [];
          this.available = false
          this.mapErrors(val);
        }
      })
    },
    openSurveying() {
      this.loading = true;
      this.createAttempt({
        data: {
          survey: this.dialog_data.id,
          user: this.attempt_check.surveying_person,
          created: true,
          ended: false,
          is_anonym: this.anonym_status,
        },
        finalizer: (val) => {
          this.loading = false;
          this.$emit(
            'close',
            {
              survey: this.dialog_data.id,
              attempt: val.id,
              attempt_hash: val.attempt_hash,
              data: val
            }
          )
        }
      })
    },
    continueSurveying(){
      this.$emit(
          'close',
          {
            survey: this.dialog_data.id,
            attempt: this.attempt_check.attempt,
            attempt_hash: this.attempt_check.attempt_hash,
            data: undefined
          }
      )
    },
    closeDialog() {
      this.$emit('close', false)
    },
  },
  created() {
    this.checkView()
  }
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>
