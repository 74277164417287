<template>
  <EditSurveyTemplate
    v-if="$route.name === names.SURVEYS.EDIT"
    :survey="survey"
    :survey-link="survey_detail_link"
    :error-show.sync="error.show"
    :error-text="error.text"
    :loading="isLoading"
    @publish="updateDraftStatusDialog"
    @addStage="addStage"
    @save="openSaveApply"
    @checkKindParam="checkKindParam"
    @deleteKindParam="deleteKindParam"
    @deleteAllKindParam="deleteAllKindParam"
  >
    <template v-slot:stages>
      <stages
        ref="stages"
        :loading.sync="isLoading"
        :survey-id="surveyId"
      ></stages>
    </template>
    <template v-slot:dialogs>
      <ApplyDialog
        v-if="save_survey"
        :opened="save_survey"
        :disabled="loading"
        :get-text="() => `Сохранить опрос ?`"
        @close="saveSurvey"
      ></ApplyDialog>
    </template>
  </EditSurveyTemplate>
  <router-view v-else></router-view>
</template>

<script>
import names from "../routers/names";
import TitledPageMixin from "../../../mixins/TitledPageMixin";
import ApplyDialog from "../../core/components/ApplyDialog";
import {mapActions} from "vuex";
import Stages from "@/modules/administrative/survey/components/edit/Stages";
import EditSurveyTemplate from "@/modules/templates/survey/edit/EditSurveyTemplate";
import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "EditSurvey",
  components: {
    EditSurveyTemplate,
    Stages,
    ApplyDialog,
  },
  mixins: [TitledPageMixin, LoadingMixin],
  data(){
    return {
      page_title: "Редактирование опроса",
      save_survey: false,
      survey: {
        anonymity: undefined,
        creator: undefined,
        description: undefined,
        draft: undefined,
        end_date: undefined,
        freeze: undefined,
        kind_param: undefined,
        id: undefined,
        is_freeze: undefined,
        kind: undefined,
        name: undefined,
        publication_date: undefined,
        sort: undefined,
        uid: undefined
      },
      error: {
        show: false,
        text: '',
      },
    }
  },
  watch: {

  },
  computed: {
    names: () => names,
    surveyId(){
      return this.$route.params.idSurvey;
    },
    survey_detail_link(){
      return {
        name: names.SURVEYS.DETAIL,
        params: {idSurvey: this.$route.params.idSurvey}
      }
    },
  },
  methods: {
    ...mapActions({
      loadSurveyDetail: 'admin/loadSurveyDetail',
      updateSurvey: 'admin/updateSurvey',
      surveyGetKindParam: 'admin/surveyGetKindParam',
      surveyListKindParam: 'admin/surveyListKindParam',
      surveyCreateKindParam: 'admin/surveyCreateKindParam',
      surveyDeleteKindParam: 'admin/surveyDeleteKindParam',
      surveyDeleteAllKindParam: 'admin/surveyDeleteAllKindParam',
    }),
    checkKindParam(event){
      if (event) {
        this.saveSurvey(true,false)
        this.setLoading();
        this.surveyGetKindParam({
          survey_id: this.$route.params.idSurvey,
          search: event,
          finalizer: (val) => {
            let save_promises = [];
            val.forEach(el => {
                  save_promises.push(
                      new Promise((resolve) => {
                        this.surveyCreateKindParam({
                          survey_id: this.$route.params.idSurvey,
                          data: {
                            title: el.text,
                            value: el.value,
                            survey: Number(this.$route.params.idSurvey),
                            with_childs: false,
                          },
                          finalizer: (val) => {
                            this.setNotLoading();
                            resolve();
                          },
                          catcher: (err) => {
                            this.setNotLoading();
                            if (err.detail)
                              this.showError(err.detail)
                            else
                              this.showError("Не удалось сохранить")
                            resolve();
                          }
                        }
                        )
                      }
                      )
                  )

            });
            Promise.all(save_promises).then(() => {
              this.loadSurvey();
            })
          },
          catcher: (err) => {
            this.setNotLoading();
            if (err.detail)
              this.showError(err.detail)
            else
              this.showError("Не удалось сохранить")
          }
        })
      }
    },
    deleteAllKindParam(event){
      this.setLoading();
      this.surveyDeleteAllKindParam({
        survey_id: this.$route.params.idSurvey,
        finalizer: (val) => {
          this.setNotLoading();
          this.loadSurvey()
        },
        catcher: (err) => {},
      })
    },
    deleteKindParam(event){
      this.setLoading();
      this.surveyDeleteKindParam({
        survey_id: this.$route.params.idSurvey,
        id: event.id,
        finalizer: (val) => {
          this.setNotLoading();
          this.loadSurvey()
        },
        catcher: (err) => {},
      })
    },
    showError(text){
      this.error.show = false;
      setTimeout(() => {
        this.error.show = true;
        this.error.text = text;
      }, 1)
    },
    loadSurvey(){
      this.setLoading();
      this.loadSurveyDetail({
        id: this.$route.params.idSurvey,
        finalizer: (data) => {
          this.page_title = data.name
          this.survey = data
          this.surveyListKindParam({
            survey_id: this.$route.params.idSurvey,
            finalizer: (val) => {
              this.setNotLoading();
              this.survey.kind_param = val
            },
            catcher: (err) => {}
          })
        },
        catcher: (err) => {}
      })
    },
    updateDraftStatusDialog(){
      this.error.show = false;
      if (this.$refs.stages.stages.length === 0){
        this.showError('Опрос без этапов не может быть опубликован')
      }
      else
        this.updateDraftStatus()
    },
    updateDraftStatus(){
      this.setLoading();
      this.updateSurvey({
        data: {draft: !this.survey.draft},
        id: this.$route.params.idSurvey,
        finalizer: (data) => {
          this.setNotLoading();
          this.survey.draft = data.draft
        },
        catcher: (error) => {
          this.setNotLoading();
          this.showError(error.survey[0])
        }
      })
    },
    saveSurvey(apply,savestage = true){
      if (apply)
      {
        this.setLoading();
        if(savestage)
          if (this.$refs.stages.$refs.stages) this.$refs.stages.fullSaveStages();
        let data = {...this.survey}
        delete data['creator']
        this.updateSurvey({
          data: data,
          id: this.surveyId,
          finalizer: (data) => {
            this.setNotLoading();
            this.closeSaveApply();
          },
          catcher: (error) => {
            this.setNotLoading();
            let text =''
            for (var key of Object.keys(error)) {
              text = text + " " + error[key]
            }
            this.showError(text)
          }
        })
      }
      this.closeSaveApply();
    },
    openSaveApply(){
      this.error.show = false;
      this.save_survey = true;
    },
    closeSaveApply(){
      this.save_survey = false;
    },
    addStage(){
      this.$refs.stages.openAddStage();
    },
  },
  created() {
    this.loadSurvey();
  },
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>
