import {ACCOUNTS_INSTANCE, SURVEY_INSTANCE} from "@/helper/consts";
import Base from "@/modules/survey/Breadcrumbs"

import SurveysList from "../surveys/Base";
import Surveying from "../surveys/Surveying";
import LinkDetail from "../surveys/LinkDetail";

import names from '@/modules/survey/routers/names'

import administrative from '@/modules/administrative/routers/routers'
import creator from '@/modules/creator/routers/routers'
import watcher from '@/modules/watcher/routers/routers'


export default ([
    {
        path: '',
        name: names.BASE,
        component: Base,
        meta: {
            breadcrumb: 'Система проведения опросов',
            requiredAuth: false,
            instances: [
                SURVEY_INSTANCE,
                ACCOUNTS_INSTANCE
            ]
        },
        children: [
            {
                path: 'survey',
                name: names.SURVEYS.LIST,
                component: SurveysList,
                meta: {
                    breadcrumb: 'Опросы',
                    requiredAuth: false,
                    instances: [
                        SURVEY_INSTANCE,
                        ACCOUNTS_INSTANCE
                    ]
                },
                children: [
                    {
                        path: ':idSurvey/link',
                        name: names.SURVEYS.LINK_DETAIL,
                        component: LinkDetail,
                        meta: {
                            breadcrumb: 'Опрос по ссылке',
                            requiredAuth: false,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    },
                    {
                        path: ':idSurvey/attempt/:idAttempt',
                        name: names.SURVEYS.SURVEYING,
                        component: Surveying,
                        meta: {
                            breadcrumb: 'Прохождение опроса',
                            requiredAuth: false,
                            instances: [
                                SURVEY_INSTANCE,
                                ACCOUNTS_INSTANCE
                            ]
                        },
                    }
                ]
            },
            ...administrative,
            ...creator,
            ...watcher,
        ]
    },
])


