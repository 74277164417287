import attempts from "@/store/modules/admin/attempts";
import choices from "@/store/modules/admin/choices";
import fields from "@/store/modules/admin/fields";
import surveys from "@/store/modules/admin/surveys";
import stages from "@/store/modules/admin/stages";
import users from "@/store/modules/admin/users";
import file from "@/store/modules/admin/file";

export default {
  namespaced: true,
  modules: {
    users,
    surveys,
    stages,
    attempts,
    fields,
    choices,
    file
  }
}
