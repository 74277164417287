<template>
  <LoadFileDetail
      @saveFile="saveFile"
      :detail="detail"
      :completed="completed"
  ></LoadFileDetail>
</template>

<script>
import TitledPageMixin from "@/mixins/TitledPageMixin";
import {mapActions} from "vuex";
import {displayDate} from "@/helper";
import names from "@/modules/creator/routers/names";
import LoadFileDetail from "@/modules/templates/LoadFileDetail.vue";


export default {
  name: "CreatorFileDetail",
  mixins: [TitledPageMixin,],
  components: {
    LoadFileDetail
  },
  data() {
    return {
      page_title: "Список формирующихся файлов",
      detail: null,
      file: null,
      completed: false
    }
  },
  computed: {
    names: () => names,
  },
  methods: {
    ...mapActions({
      compiledLoadFile: 'admin/compiledLoadFile',
      saveLoadFile: 'admin/saveLoadFile',
    }),

    displayDate:displayDate,
    loadData() {
      const id =  this.$route.params.idFile
      let timerId = setInterval(() => {
        this.compiledLoadFile({
          id: id,
          data: null,
          finalizer: (data, url) => {
            this.completed = data.completed
            this.file = data.file
            if(this.$route.name !== names.FILE.DETAIL)
              clearInterval(timerId)
            if(data.completed){
              clearInterval(timerId)
            }
          }
        })
      }, 2000);
    },
    saveFile(){
      const id =  this.$route.params.idFile
      this.saveLoadFile({
        id: id,
        url: this.file
      })
    }
  },
  created() {
    this.loadData()
  }

}
</script>

<style scoped>

</style>
