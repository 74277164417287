<template>
  <v-form ref="form">
    <v-autocomplete
      v-if="!field.id"
      v-model="field.field"
      ref="field"
      :items="fields"
      item-text="name"
      item-value="id"
      label="Поле"
      hint="Выберите поле из списка полей доступных в системе для добавления в опрос"
      persistent-hint
      outlined
      class="my-4"
      :loading="loading"
      :readonly="loading"
      :rules="[rules.required]"
      :error="hasError('field',errorsObject)"
      :error-messages="getError('field',errorsObject)"
      @input="deleteError('field')"
    >
    </v-autocomplete>
    <v-expand-transition>
      <div v-if="field.field">
        <v-text-field
          ref="title"
          v-model="field.title"
          label="Отображаемое наименование"
          hint="Этот текст отображается пользователю во время прохождения опроса"
          persistent-hint
          class="my-4"
          outlined
          :loading="loading"
          :readonly="loading"
          :rules="[rules.required]"
          :error="hasError('title',errorsObject)"
          :error-messages="getError('title',errorsObject)"
          @input="deleteError('title')"
        ></v-text-field>


        <v-text-field
          ref="description"
          v-model="field.description"
          label="Подсказка к полю"
          hint="Этот текст может содержать подсказку или пояснение по заполнению поля во время опроса и будет отображаться под полем"
          persistent-hint
          class="my-4"
          :loading="loading"
          outlined
          :readonly="loading"
          :error="hasError('description',errorsObject)"
          :error-messages="getError('description',errorsObject)"
          @input="deleteError('description')"
        ></v-text-field>
        <v-row class="mb-6">
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              ref="read_only"
              v-model="field.read_only"
              label="Только чтение"
              color="primary"
              :hide-details="!hasError('read_only',errorsObject)"
              :loading="loading"
              :readonly="loading"
              :disabled="read_only_disable()"
              :error="hasError('read_only',errorsObject)"
              :error-messages="getError('read_only',errorsObject)"
              @change="deleteError('read_only')"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              ref="hidden"
              v-model="field.hidden"
              label="Скрытое"
              color="primary"
              :hide-details="!hasError('hidden')"
              :loading="loading"
              :readonly="loading"
              :disabled="hidden_disable()"
              :error="hasError('hidden',errorsObject)"
              :error-messages="getError('hidden',errorsObject)"
              @change="deleteError('hidden')"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              ref="required"
              v-model="field.required"
              label="Обязательное"
              color="primary"
              :hide-details="!hasError('required')"
              :loading="loading"
              :disabled="required_disable()"
              :readonly="loading"
              :error="hasError('required',errorsObject)"
              :error-messages="getError('required',errorsObject)"
              @change="deleteError('required')"
            ></v-checkbox>
          </v-col>
          <v-col
              cols="12"
              md="1"
              v-show="( read_only_disable() || required_disable() || hidden_disable())"
          >
            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>Действуют ограничения на свойства поля</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-autocomplete
          ref="choices"
          v-if="field.choices && !field.static_choices"
          v-model="field.choices"
          label="Список выбора"
          hint="Из этого списка значений будет выбираться ответ при прохождении теста."
          persistent-hint
          :items="choicesList"
          item-text="name"
          item-value="id"
          :error="hasError('choices',errorsObject)"
          :error-messages="getError('choices',errorsObject)"
          @input="deleteError('choices')"
        >
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <div class="text-left" style="-moz-hyphens: auto; -webkit-hyphens: auto; -ms-hyphens: auto;">
                {{ data.item.name }}
              </div>
            </template>
          </template>
        </v-autocomplete>
        <v-row v-if="coherent_type">
          <v-col v-if="coherent_name" class="text-left mb-2">
            Выбранный когерентный тип: {{ coherent_name }}
          </v-col>
        </v-row>
        <v-row v-if="errorsObject && errorsObject.type && Array.isArray(errorsObject.type) && errorsObject.type[0]">
          <v-col cols="12" class="pb-0 pt-6">
            <v-alert
              type="error"
            >
              {{ errorsObject.type[0] }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="errorsObject && errorsObject.fields && errorsObject.fields.type && Array.isArray(errorsObject.fields.type) && errorsObject.fields.type[0]">
          <v-col cols="12" class="pb-0 pt-6">
            <v-alert
              type="error"
            >
              {{ errorsObject.fields.type[0] }}
            </v-alert>
          </v-col>
        </v-row>
        <default-field
          v-show="disable_default()"
          ref="default_value"
          v-if="!coherent_type || coherent_is_list"
          v-model="field.default_value"
          :type="field_type"
          label="Значение по умолчанию"
          hint="Это значение будет вводиться в поле автоматически при попытке пройти опрос"
          persistent-hint
          :items="choicesValueList"
          :date_dynamic_lower="date_dynamic_lower"
          :date_dynamic_upper="date_dynamic_upper"
          :date_dynamic_value_type="date_dynamic_value_type"
          :date_static_lower="date_static_lower"
          :date_static_upper="date_static_upper"
          :coherent-type="coherent_type"
          :loading="loading"
          :readonly="loading"
          :error="hasError('default_value',errorsObject)"
          :error-messages="getError('default_value',errorsObject)"
          @input="deleteError('default_value')"
        ></default-field>
        <v-file-input
            v-model="field.file"
            v-show="disable_file()"
            :error="hasError('file',errorsObject)"
            :error-messages="getError('file',errorsObject)"
            accept="application/pdf"
            prepend-icon=""
            label="Документ"
            outlined
            class="pt-2"
            hint="Это значение содержит вложенные документы"
            persistent-hint
            clearable
            @click:clear="field.file = null"
        >
          <template v-slot:selection v-if="field.file !== undefined && field.file !== null && field.file.action !== undefined">
            Файл выбран
          </template>
          <template v-slot:append-outer v-if="field.file !== undefined && field.file !== null && field.file.action !== undefined">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="$emit('download', field.file)"
                >
                  <v-icon>file_download</v-icon>
                </v-btn>
              </template>
              <span>Загрузить документ</span>
            </v-tooltip>
          </template>
        </v-file-input>
      </div>
    </v-expand-transition>
  </v-form>
</template>

<script>
import DefaultField from "@/modules/templates/custom_fields/DefaultField";
import {mapActions, mapGetters} from "vuex";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";


export default {
  name: "EditSurveyStageFieldFormTemplate",
  mixins: [FormErrorsMixin],
  components: {DefaultField},
  props: {
    value: {
      required: true
    },
    disable_permission:{
      type: Boolean,
      default: false,
    },
    date_dynamic_lower: Number,
    date_dynamic_upper: Number,
    date_dynamic_value_type: String,
    date_static_lower: String,
    date_static_upper: String,
    field_type: String,
    coherent_type: Number,
    // coherent_name: String,
    choicesList: Array,
    choicesValueList: Array,
    loading: Boolean,
    readonly: Boolean,
    fields: Array,
    errorsObject: Object,
  },
  data() {
    return {
      errors: {fields: {}, non: []},
      field: this.value,
      selected_field: this.select_field,
      // choices_values: [],
      rules: {
        required: v => (!!v) || 'Поле обязательно для заполнения'
      }
    }
  },
  computed: {
    ...mapGetters({
      coherent_list: 'selectors/getCoherentSelector',
    }),
    coherent_name(){
      let coherent = this.coherent_list.find(el => el.value === this.coherent_type)
      return coherent ? coherent.text : this.coherent_type
    },
    coherent_is_list(){
      let coherent = this.coherent_list.find(el => el.value === this.coherent_type)
      return coherent ? coherent.list : false
    },
  },
  watch: {
    'field.choices'(val,old){
      if(old)
        if(!val){
          this.field.choices = 1
        }
    },
    'value.coherent_type'(){
      this.loadCoherentSelector()
    },
    errorsObject: {
      deep: true,
      handler() {
        // if (this.errorsObject){
        //   this.errors = {fields: {}, non: []}
        // }else {
          this.errors = {fields: this.errorsObject, non: []}
        // }

      }
    },
    field: {
      deep: true,
      handler() {
        let group = ["hidden","read_only","required"]
        let countTrue = 0
        for (let key of group){
          if(this.field[key]){
            countTrue = countTrue +1
          }
        }
        if(countTrue > 1)
          for (let key of group){
            this.$nextTick(()=>{
              this.field[key] = false
            })
          }
          this.$emit('input', this.field)
      }
    }
  },
  methods: {
    ...mapActions({
      loadCoherentSelector: 'selectors/loadCoherentSelector',
    }),
    disable_default() {
      if (['15','6'].includes(this.field_type))
        return false
      if (this.field_type === "14")
        if ([10,7,6,5,3,2,1,0].includes(Number(this.coherent_type)))
          return false
      if (this.disable_permission)
        return true
      return true
    },
    disable_file() {
      if (this.disable_permission)
        return true
      // if (this.field_type === "14")
      //   if ([10,7,6,5,3,2,1,0].includes(this.coherent_type))
      //     return false
      return true
    },
    read_only_disable(){
      if (this.disable_permission)
        return false
      if (['15','5','8','11','10','12','13','6'].includes(this.field_type))
        return true
      if (this.field_type === "14")
        if ([36,35,32,29,27,23,17,15,12,11,9,8,4].includes(Number(this.coherent_type)))
          return true
      return false
    },
    hidden_disable(){
      if (this.disable_permission)
        return false
      if (['15','5','8','11','10','12','13','6','4','0','9','7','3'].includes(this.field_type))
        return true
      if (this.field_type === "14")
        if ([36,35,32,29,27,23,17,15,12,11,9,8,4].includes(Number(this.coherent_type)))
          return true
      return false
    },
    required_disable(){
      if (this.disable_permission)
        return false
      if (this.field_type === "14")
        if ([10,7,6,5,3,2,1,0].includes(this.coherent_type))
          return true
      return false
    },
    validate() {
      return this.$refs.form.validate(true)
    }
  },
  created() {
    if (this.field.id) {
      if(this.field.coherent_type)
        this.loadCoherentSelector()
    }
  }
}
</script>

<style scoped>

</style>
