import helper from "./helper";
// import user from "../store/modules/user";

let getSurveyUrl = helper.getSurveyUrlWithParameters

export default {
    getSurveyUrl,
    getByRecievedUrlData: (url, gets = null) => getSurveyUrl(
        url.basename,
        url.action,
        url.params,
        gets
    ),
    CHOICES_SELECTOR: {
        FIELD_TYPE:
            (gets = null) => getSurveyUrl(
                'surveys|choices|field_type_choices',
                "get",
                gets
            ),
        DOCUMENT_TYPE:
            (survey_id, gets = null) => getSurveyUrl(
                'surveys|choices|content_type_choices',
                "get",
                gets
            ),
        KIND_TYPE:
            (gets = null) => getSurveyUrl(
                'surveys|choices|kind_type_choices',
                "get",
                gets
            ),
        ANONYMITY_TYPE:
            (gets = null) => getSurveyUrl(
                'surveys|choices|anonymity_type_choices',
                "get",
                gets
            ),
        DATE_DYNAMIC_TYPE:
            (gets = null) => getSurveyUrl(
                'surveys|choices|date_dynamic_value_type_choices',
                "get",
                gets
            ),
        COHERENT_TYPE:
            (gets = null) => getSurveyUrl(
                'surveys|choices|coherent_type_choices',
                "get",
                gets
            ),
    },

}
