import { render, staticRenderFns } from "./ChoicesFormTemplate.vue?vue&type=template&id=2c91dcda&scoped=true"
import script from "./ChoicesFormTemplate.vue?vue&type=script&lang=js"
export * from "./ChoicesFormTemplate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c91dcda",
  null
  
)

export default component.exports