<template>
  <v-container class="subprimary">
    <v-row
      v-if="mainpage.title"
      align-content="start"
      align="start"
    >
      <v-col cols="auto">
        <h1 class="super-dark-primary">{{ mainpage.title }}</h1>
      </v-col>
    </v-row>
    <v-divider
      v-if="mainpage.title"
      class=" py-2  "
    />
    <v-row
      align-content="start"
      align="start"
    >
      <v-col
        cols="auto"
        class="pt-0"
      >
        <breadcrumbs style="padding-top: 6px" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: "PageHeader",
    computed: mapState({
      mainpage: state => state.mainpage,
    }),
  }
</script>

<style scoped>

</style>
