<template>
  <v-container>
    <v-row class="justify-center" >
      <v-col cols="md-8">
        <v-card
            v-if="survey_data"
            outlined
            class="mx-auto text-left rounded-t-lg"
        >
          <v-toolbar flat tile rounded="0" width="100%" color="super-grey" class="mb-0 pb-0">
            <slot name="title">
          <span class="text-h5">
            Опрос
          </span>
            </slot>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    fab
                    icon
                    small
                    @click="closeDetail"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
              <span>Назад к списку</span>
            </v-tooltip>
          </v-toolbar>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>bookmark</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-left">
                {{ survey_data.name }}
              </v-list-item-title>
            </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>description</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-left" v-html="urlify(survey_data.description)"></v-list-item-content>
          </v-list-item>
          <div v-show="!isAuthenticate">
            <div v-if="survey_data.kind !== '0'" >
              <v-alert outlined type="info"  v-if="(survey_data.anonymity==0||(survey_data.anonymity==2 && anonym_status))">
                Важно! Для подтверждения статуса "Работник/Обучающийся" необходимо авторизоваться в системе.
                При этом сбор персональных данных (ФИО) не выполняется, результаты опроса будут полностью анонимны.
              </v-alert>
            </div>
          </div>
          <v-alert outlined type="info" v-if="(survey_data.anonymity==2 && !anonym_status)">
            При выборе анонимного прохождения опроса сбор персональных данных (ФИО) не выполняется, результаты опроса будут полностью анонимны
          </v-alert>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>visibility</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="survey_data.anonymity==0">Анонимно</v-list-item-title>
              <v-list-item-title v-if="survey_data.anonymity==1">Не анонимно</v-list-item-title>
              <v-list-item-title v-if="survey_data.anonymity==2">На выбор пользователя</v-list-item-title>
              <v-list-item-subtitle>Анонимность опроса</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-row class=" text-left">
            <v-col
                :cols="survey_data.anonymity === '2' ? 4 : 6"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>event</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ displayDate(survey_data.publication_date) }}</v-list-item-title>
                  <v-list-item-subtitle>Дата начала</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
                :cols="survey_data.anonymity === '2' ? 4 : 6"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>event_available</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{displayDate( survey_data.end_date) }}</v-list-item-title>
                  <v-list-item-subtitle>Дата окончания</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col
                v-if="survey_data.anonymity === '2'"
                cols="4"
            >
                  <v-checkbox
                      v-model="anonym_status"
                      label="Анонимный опрос"
                  ></v-checkbox>
            </v-col>
          </v-row>
          <v-sheet>
            <v-alert type="error" outlined v-if="hasError('user')">
              {{getError('user')}}
            </v-alert>
          </v-sheet>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn-toggle
                v-if="!isAuthenticate && attempt_status === undefined"
                style="min-width: 300px"
            >
              <v-text-field
                  v-if="(survey_data.anonymity === '1' && (survey_data.kind !== '4' || survey_data.kind !== '5'))"
                  v-model="email"
                  outlined clearable dense hide-details
                  label="Введите почту"
                  :loading="loading"
              ></v-text-field>
              <v-btn
                  text height="40px"
                  @click="checkView"
                  :loading="loading"
              >
                Продолжить
              </v-btn>
            </v-btn-toggle>
            <v-btn
                v-if="attempt_status === false"
                color="success"
                depressed
                @click="openSurveying"
                :loading="loading"
            >
              Начать опрос
            </v-btn>
            <v-btn
                v-if="attempt_status && attempt_ended === false"
                color="success"
                depressed
                @click="continueSurveying"
                :loading="loading"
            >
              Продолжить опрос
            </v-btn>
            <span
                v-if="attempt_status && attempt_ended === true"
                style="font-size: 20px; margin-top: -8px; padding-bottom: 10px"
            >
              Опрос пройден
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
        <v-card
            v-if="!survey_data "
            outlined
        >
          <v-toolbar flat tile rounded="0" width="100%" color="super-grey" class="mb-0 pb-0">
            <slot name="title">
              <span class="text-h5">
                Отказано в доступе!
              </span>
            </slot>
          </v-toolbar>
          <v-card-text>
            <p class="text-h6">
              Пожалуйста, авторизуйтесь в системе.<br>
              Данный опрос может быть недоступен в связи с отсутствием индивидуального
              приглашения или завершением срока прохождения опроса.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                depressed
                @click="closeDetail"
            >
              Вернуться к списку
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import names from "../routers/names";
import surveying from "@/urls/roles/surveying";
import {displayDate, urlify} from "@/helper";
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
export default {
  name: "LinkDetail",
  mixins:[
    FormErrorsMixin
  ],
  data(){
    return {
      email: undefined,
      survey_data: undefined,
      attempt_status: undefined,
      attempt_check: undefined,
      attempt_ended: undefined,
      available: false,
      loading: false,
      anonym_status: false,
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      isAuthenticate: 'auth/isAuthenticated'
    })
  },
  methods: {
    urlify,
    displayDate:displayDate,
    ...mapActions({
      LoadSurveyDetail: 'surveying/LoadSurveyDetail',
      checkAttempt: "surveying/CheckAttempt",
      createAttempt: "surveying/createAttempt",
    }),
    initialize() {
      if (this.$route.query.email)
        this.email = this.$route.query.email
      this.LoadSurveyDetail({
        survey_id: this.$route.params.idSurvey,
        key: this.$route.query.key ? this.$route.query.key : null,
        finalizer:(val)=>{
          this.survey_data = val
        },
        catcher: (err) => {
          this.survey_data = null
        }
          }
      )
    },
    checkView() {
      this.loading = true;
      this.checkAttempt({
        survey_id: this.$route.params.idSurvey,
        email: this.email !== undefined && this.email !== '' ? this.email: undefined,
        key: this.$route.query.key,
        finalizer: (val) => {
          this.attempt_status = val.attempt_status
          this.attempt_ended = val.attempt_ended
          this.available = val.available
          this.attempt_check = val
          this.loading = false;
        },
        catcher:(val) => {
          this.loading = false;
          this.errors.non = [];
          this.mapErrors(val);
        }
      })
    },
    openSurveying() {
      this.loading = true;
      this.createAttempt({
        data: {
          survey: this.$route.params.idSurvey,
          user: this.attempt_check.surveying_person ? this.attempt_check.surveying_person : undefined,
          created: true,
          ended: false,
          is_anonym: this.anonym_status,
        },
        key: this.$route.query.key ? this.$route.query.key : null,
        finalizer: (val) => {
          this.loading = false;
          let queryParam = {}
          if (val.attempt_hash){
            queryParam = {
              attempt_hash:val.attempt_hash
            }
          }
          this.$router.push({
            name: names.SURVEYS.SURVEYING,
            params: {
              idSurvey: this.$route.params.idSurvey,
              idAttempt: val.id,
              attempt: val,
              key: this.$route.query.key ? this.$route.query.key : null,
            },
            query:queryParam
          })
        },
        catcher:(val) => {
          this.loading = false;
          this.errors.non = [];
          this.mapErrors(val);
        }
      })
    },
    continueSurveying(){
      this.$router.push({
        name: names.SURVEYS.SURVEYING,
        params: {
          idSurvey: this.$route.params.idSurvey,
          idAttempt: this.attempt_check.attempt,
          attempt: undefined
        },
        query:{
          attempt_hash: this.attempt_check.attempt_hash
        }
      })
    },
    closeDetail() {
      this.$router.push({
        name: names.SURVEYS.LIST,
      })
    },
  },
  created() {
    this.initialize()
    if (this.isAuthenticate) {
      this.checkView()
    }
  }
}
</script>

<style scoped>

</style>
