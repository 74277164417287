<template>
  <div class="page-content" style="margin: 0px; width: 100%">
    <PageHeader v-show="statusBreadcrumbs"></PageHeader>
    <Main
        v-if="$route.name === names.BASE"
        :name_list="left_menu_list"
    ></Main>
    <router-view v-else></router-view>
  </div>
</template>

<script>

    import names from '@/modules/survey/routers/names'
    import {getInstMenu, getInstNameByRouterName} from "@/helper/instances";
    import PageHeader from "../menus/PageHeader";

    import Main from "./Main";
    import {mapGetters} from "vuex";

    export default {
      name: "SurveyBase",
      components: {Main, PageHeader},
      data() {
          return {
            left_menu_list: [
              {title: 'Опросы', icon: 'format_list_numbered', included: true, router: {name:names.SURVEYS.LIST, params:{}}},
            ]
          }
      },
      computed: {
        names: () => names,
        ...mapGetters({
          isAuthenticated: 'auth/isAuthenticated',
          statusBreadcrumbs: 'mainpage/getStatusBreadcrumbs'
        })
      },
        methods: {
          loadLeftmenu() {
              this.$store.commit('mainpage/changeTitle', 'Опрашиваемый');
              this.$store.commit('leftmenu/replaceInstances', getInstMenu());
              this.$store.commit('leftmenu/selectInstance', getInstNameByRouterName(names.BASE));
              this.$store.commit('leftmenu/replaceItems', this.getLeftMenu());
              // this.$store.commit('leftmenu/hide');
              if (!this.isAuthenticated) {
                this.$store.commit('leftmenu/dissable');
              }
          },
          getLeftMenu() {
              let with_category_uid = (name) => {
                  return {name: name, params: {category: this.$route.params.category}}
              };
              let menu = []
              for (let el in this.left_menu_list){
                menu.push(this.left_menu_list[el])
              }
              return menu
          }
        },
        beforeRouteUpdate(to, from, next) {
            this.loadLeftmenu()
            next()
        },
        created() {
            this.$store.commit('leftmenu/enableLeftMenu');
            this.loadLeftmenu()
        }
    }
</script>

<style scoped>
    .border-right {
        border-right: 2px solid var(--steel-gray);
    }

</style>
