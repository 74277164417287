<template>
  <v-form ref="form">
    <v-textarea
        rows="1"
        v-model="survey.name"
        label="Название опроса"
        outlined
        hint="Текст, который отображается пользователю в списке опросов доступных для прохождения"
        persistent-hint
        :rules="[rules.required]"
        :error="hasError('name')"
        :error-messages="getError('name')"
        @input="deleteError('name')"
    >
      <template v-slot:label>
        Название опроса<span class="red--text">*</span>:
      </template>
    </v-textarea>
    <v-textarea
        rows="1"
        v-model="survey.description"
        label="Краткое описание"
        outlined
        hint="Текст, который отображается опрашиваемому в начале опроса"
        persistent-hint
        :rules="[rules.required]"
        :error="hasError('description')"
        :error-messages="getError('description')"
        @input="deleteError('description')"
    >
      <template v-slot:label>
        Краткое описание<span class="red--text">*</span>:
      </template>
    </v-textarea>
    <v-container>
      <v-row dense>
        <v-col cols="12" md="6">
          <date-picker
              v-model="survey.publication_date"
              label="Дата начала"
              :outlined="true"
              hint="Дата, с которой опрос будет доступен к прохождению"
              icon="event"
              close-on-content-click
              :allowed-dates="(val)=>new Date(val) >= new Date().setHours(0,0,0,0)"
              prepend-outer-icon
              open-on-focus
              :error="hasError('publication_date')"
              :error-messages="getError('publication_date')===null ? getError('publication_date'):[String(getError('publication_date')).replace('YYYY-MM-DD','DD.MM.YYYY')]"
              @input="deleteError('publication_date')"
          >
            <template v-slot:label>
              Дата начала<span class="red--text">*</span>:
            </template>
          </date-picker>
        </v-col>
        <v-col cols="12" md="6">
          <date-picker
              v-model="survey.end_date"
              label="Дата окончания"
              :outlined="true"
              hint="Дата, после которой опрос будет недоступен к прохождению"
              icon="event_available"
              :allowed-dates="(val)=>new Date(val) >= new Date().setHours(0,0,0,0)"
              close-on-content-click
              prepend-outer-icon
              open-on-focus
              :error="hasError('end_date')"
              :error-messages="getError('end_date')===null ? getError('end_date'):[String(getError('end_date')).replace('YYYY-MM-DD','DD.MM.YYYY')]"
              @input="deleteError('end_date')"
          >
            <template v-slot:label>
              Дата окончания<span class="red--text">*</span>:
            </template>
          </date-picker>
        </v-col>
      </v-row>
      <v-card flat class="grey lighten-3" rounded style="border: solid 1px  #909090!important;">
        <v-row dense class="pt-3 mx-2">
          <v-col cols="12" md="6">
            <v-select
                v-model="survey.kind"
                :items="kind_selector"
                label="Доступ:"
                outlined
                hint="Параметр, который определяет категорию пользователей, имеющих доступ к прохождению опроса"
                persistent-hint
                :disabled = disableKind
                prepend-icon="list"
                :error="hasError('kind')"
                :error-messages="getError('kind')"
                @input="deleteError('kind')"
            >
              <template v-slot:label>
                Доступ<span class="red--text">*</span>:
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                v-model="survey.anonymity"
                :items="anonymity_selector"
                label="Анонимность:"
                hint="Параметр, который определяет, будет ли сохранена анонимность опрашиваемого"
                persistent-hint
                outlined
                prepend-icon="visibility"
                :error="hasError('anonymity')"
                :error-messages="getError('anonymity')"
                @input="deleteError('anonymity')"
            >
              <template v-slot:label>
                Анонимность<span class="red--text">*</span>:
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!--      TODO: исправить v-if -->
        <v-row
            v-if="['6','7','8','9'].indexOf(survey.kind) !== -1"
            dense
            class="pt-3 mx-2"
        >
          <v-col cols="12" md="6">
            <v-text-field
                v-model="create_kind_param"
                label="Новый параметр доступа:"
                hint="Параметр, который определяет, какой доступ разрешен для данной категории пользователей"
                persistent-hint
                outlined
                prepend-icon="sort"
                :error="hasError('kind')"
                :error-messages="getError('kind')"
                @input="deleteError('kind')"
            ></v-text-field>
            <v-col cols="12">
              <v-row justify="end">
                <v-btn
                    class="my-3"
                    outlined
                    block
                    color="primary"
                    @click="checkKindParam"
                >
                  Создать
                </v-btn>
              </v-row>

            </v-col>
          </v-col>
          <v-col cols="12" md="6">
            <v-expansion-panels v-model="opened">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-card-text class="body-1 py-0 my-0">Параметры доступа: {{survey.kind_param.length}}</v-card-text>
                  <v-col cols="2" v-if="opened === 0 && survey.kind_param.length > 0">
                    <v-row  justify="end">
                      <v-tooltip bottom >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs"  v-on="on" icon @click.stop="$emit('deleteAllKindParam')" >
                            <v-icon>delete_forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Удалить все параметры доступа</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item
                      v-for="(kind, kindex) in survey.kind_param"
                      :key="`kind_${kindex}`"
                  >
                    <v-row >
                      <v-col cols="12" md="8" class="text-left">
                        {{ kind.title }}
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="2" >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                :disabled="loading_data"
                                icon
                                @click="deleteKindParam(kind)"
                            >
                              <v-icon>delete</v-icon>
                            </v-btn>
                          </template>
                          <span>Удалить параметр доступа</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card>

    </v-container>
    <slot name="actions"></slot>
  </v-form>
</template>

<script>
import FormErrorsMixin from "@/mixins/FormErrorsMixin";
import DatePicker from "@/modules/templates/custom_fields/DatePicker";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SurveyFormTemplate",
  mixins: [FormErrorsMixin],
  components: {
    DatePicker
  },
  props: {
    value: {
      required: true,
    },
    loading_data: {
      type: Boolean,
      default: false,
      required: false,
    },
    form_errors: {
      type: Object,
      default: undefined,
      required: false,
    }
  },
  data() {
    return {
      survey: this.value,
      create_kind_param: undefined,
      opened:null,
      rules: {
        required: v => (v || '').length >= 1 || `Это обязательное поле`,
      },
    }
  },
  watch: {
    form_errors: {
      deep: true,
      handler() {
        this.mapErrors(this.form_errors)
      }
    },
    value: {
      deep: true,
      handler() {
        this.survey = this.value;
      }
    },
    survey: {
      deep: true,
      handler() {
        this.$emit('input', this.survey)
      }
    }
  },
  computed: {
    ...mapGetters({
      anonymity_selector: 'selectors/getAnonymitySelector',
      kind_selector: 'selectors/getKindSelector',
    }),
    disableKind(){
      if(this.survey.kind_param)
        if(this.survey.kind_param.length !== 0)
          return true
      return false
    }
  },
  methods: {
    ...mapActions({
      loadAnonymitySelector: 'selectors/loadAnonymitySelector',
      loadKindSelector: 'selectors/loadKindSelector',
    }),
    validate() {
      return this.$refs.form.validate()
    },
    checkKindParam() {
      this.$emit('checkKindParam', this.create_kind_param)
      this.create_kind_param = undefined
    },
    deleteKindParam(val) {
      this.$emit('deleteKindParam', val)
    },
    deleteAllKindParam(val) {
      this.$emit('deleteAllKindParam', val)
    },
  },
  created() {
    this.loadKindSelector();
    this.loadAnonymitySelector();
  }
}
</script>

<style scoped>

</style>
