<template>
  <v-container v-if="$route.name === names.SURVEYS.PREVIEW" >
    <v-row class="justify-center">
      <v-col md="10" lg="8" sm="12" xl="8">
        <v-stepper v-model="select_stage" flat v-if="stages.length > 0">
          <v-card
              outlined
              style="word-break: inherit !important;"
          >
            <v-stepper-header>
              <v-stepper-step
                  v-for="(stage, sindex) in stages"
                  :key="`stage-${sindex}`"
                  :complete="select_stage > sindex+1"
                  :step="`${sindex+1}`"
              >
              </v-stepper-step>
            </v-stepper-header>
            <v-progress-linear v-show="loading" absolute indeterminate></v-progress-linear>
            <v-stepper-items>
              <v-stepper-content
                  v-for="(stage, sindex) in stages"
                  :key="`stage-${sindex}`"
                  :step="`${sindex+1}`"
              >
                <v-card
                    color="#f0f0f0"
                    flat
                    style="word-break: inherit !important;"
                >
                  <v-card-title class="text-pre-wrap" style="word-break: inherit !important;">
                    {{stage.title}}
                  </v-card-title>
                  <v-card-subtitle align="left" v-if="stage.description" class="text-pre-wrap">
                    {{stage.description}}
                  </v-card-subtitle>
                </v-card>

                <template
                    v-for="field in fields"
                >
                  <FieldView
                      v-if="field.answer"
                      v-model="field.answer.value"
                      :ref="`stage_${stage.id}_field_${field.id}`"
                      :key="`stage_${stage.id}_field_${field.id}_key`"
                      :field="field"
                      :survey="true"
                      :errors="getErrors(field)"
                      @focus="removeErrors(field)"
                      @download="downloadFieldFile"
                      :loading="loading"
                  ></FieldView>
                </template>
              </v-stepper-content>
            </v-stepper-items>
            <v-card-actions>
              <v-alert type="error" v-if="getError('attempt')" >
                {{ getError('attempt') }}
              </v-alert>
              <v-btn
                  v-if="!isFirstStage"
                  outlined
                  :color="!!errors.length? 'error' : ''"
                  @click="prevStep()"
              >
                Назад
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  outlined
                  :color="!!errors.length? 'error' : ''"
                  @click="nextStep()"
                  v-if="!isLastStage"
              >
                Вперёд
              </v-btn>
              <v-btn
                  v-else
                  :color="!!errors.length? 'error' : 'success'"
                  @click="exitAttempt()"
              >
                Завершить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
import names from "../routers/names";
import TitledPageMixin from "../../../mixins/TitledPageMixin";
import {mapActions} from "vuex";
import FieldView from "../../templates/FieldView";
import selectors from "@/urls/selectors";
import helper from "@/urls/helper";

export default {
  mixins: [TitledPageMixin],
  name: "SurveyingPreview",
  components: {
    FieldView
  },
  data(){
    return {
      timerId:0,
      page_title: "Прохождение опроса",
      select_stage: 1,
      close_attempt_flag: false,
      close_info_flag: false,
      fields: [],
      errors: [],
      stages: [],
      answers : [],
      attempt: this.$route.params.attempt,
      loading: false,
      has_error: true,
    }
  },
  computed: {
    names: () => names,
    select_stage_object() {
      return this.stages[this.select_stage - 1]
    },
    stage_fields() {
      return this.stages.length > 0 ? this.fields.filter(el => el.stage === this.select_stage_object) : []
    },
    isLastStage() {
      return this.select_stage === this.stages.length
    },
    isFirstStage() {
      return this.select_stage === 1
    }
  },
  watch: {
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    },
  },
  methods: {
    ...mapActions({
      loadStageList: 'creator/loadStageList',
      listStageField: 'creator/listStageField',
      sendEndAttempt: 'surveying/endAttempt',
      saveAnswers: 'surveying/SurveyingSave'
    }),
    getError: function (field) {
      for (let val in this.errors){
        let ss =  (this.errors[val])
        if(ss[field]){
          return ss[field]
        }
      }
      return null;
    },
    downloadFieldFile(field){
      let get = {}
      if (field.get_params.key)
        get['key'] = field.get_params.key
      let url = helper.getSurveyUrlWithParameters(field.name,field.action,{id: field.params.id}, get)
      if (url) window.open(url,'_blank');
    },
    getErrors(field){
      let error = this.errors.find(el => el.field === field.id)
      return error ? error.error : undefined
    },
    removeErrors(field){
      let error = this.errors.findIndex(el => el.field === field.id)
      if (error)
        this.errors.splice(error, 1)
    },
    wrappedLoadStageFieldList(){
      this.setLoading();

      this.listStageField({
        survey_id: this.$route.params.idSurvey,
        stage_id: this.select_stage_object.id,
        finalizer: (value) => {
          this.fields = value
          this.fields.forEach(el => {
            el.choices = el.choices_list
            el.answer = {}
            if (!el.answer.value){
              el.answer.value = el.default_value ? el.default_value : el.answer.value
            }
          })
          this.setNotLoading();
        }
      })
    },
    validate() {
      let valid = true;
      // for(let field of this.fields){
      //   valid = this.$refs[`stage_${field.stage}_field_${field.id}`][0].validate(true) && valid;
      // }
      return valid
    },
    setLoading(){
      this.loading = true;
    },
    setNotLoading(){
      this.loading = false;
    },
    initialize() {
      this.setLoading();
      this.loadStageList({
        survey_id: this.$route.params.idSurvey,
        finalizer: (val) => {
          this.stages = val
          this.wrappedLoadStageFieldList();
        }
      })
    },
    prevStep () {
      if (!this.isFirstStage) {
          this.select_stage -= 1
          this.wrappedLoadStageFieldList()
      }
    },
    nextStep () {
      if (!this.isLastStage) {
          this.select_stage += 1
          this.wrappedLoadStageFieldList();
      }
    },
    exitAttempt() {
      this.$router.push({name: names.SURVEYS.DETAIL, params: {idSurvey: this.$route.params.idSurvey}})
    },
    closeInfo(event) {
      this.close_info_flag = false;
      clearTimeout(this.timerId);
      this.$router.push({name: names.SURVEYS.DETAIL, params: {idSurvey: this.$route.params.idSurvey}})
    },
    closeAttempt(event) {
      this.$router.push({name: names.SURVEYS.DETAIL, params: {idSurvey: this.$route.params.idSurvey}})
    },
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>

</style>
