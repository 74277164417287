<template>
  <v-snackbar
    :value="errorShow"
    bottom
    app
    color="warning"
    :width="$vuetify.breakpoint.smAndDown ? '500px' : '90%'"
    :timeout="5000"
    multi-line
    style="z-index: 2;"
  >
    {{ errorText }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="pink"
        text
        v-bind="attrs"
        icon
        @click="$emit('close', false)"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "EditSurveyErrorSnackBar",
  props:{
    errorText: String,
    errorShow: {
        type:Boolean,
        default : false
    },
  },
}
</script>

<style scoped>

</style>