<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px; width: 100%"
      v-if="$route.name === names.SURVEYS.STAGE.LIST"
  >
    <template>
    </template>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
import names from "../routers/names";
import TitledPageMixin from "../../../mixins/TitledPageMixin";

export default {
  name: "StageList",
  mixins: [TitledPageMixin],
  data() {
    return {
      page_title: "Список этапов",
    }
  },
  computed: {
    names: () => names
  },
  created() {

  }
}
</script>

<style scoped>

</style>