export const TYPES = {
  NUMBER: '0',
  STRING: '1',
  TEXT: '2',
  DATE: '3',
  BOOL: '4',
  TIME: '5',
  EMAIL: '6',
  PHONE: '7',
  MIXER: '8',
  LINK: '9',
  ONE_OF: '10',
  MANY_OF: '11',
  LARGE_ONE_OF: '12',
  LARGE_MANY_OF: '13',
  COHERENT: '14',
  FILE: '15',
};
export default TYPES;
