<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px;
      width: 100%"
      v-if="$route.name === names.SURVEYS.DETAIL"
  >
    <template>
      <v-row justify="center">
        <v-col cols="10">
          <v-expansion-panels class="mystyle" accordion focusable v-model="default_panel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row>
                  <v-col cols="2" md="1">
                    <v-btn
                        icon
                        :to="{
                        name: names.SURVEYS.LIST,
                      }"
                    >
                      <v-icon>arrow_back</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="5" class="pl-4 pt-5">
                    Информация об опросе
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat class="mx-auto text-left">
                  <v-container>
                    <v-row dense>
                      <v-col cols="12">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>description</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title class="text-wrap" v-html="urlify(survey_detail.description)"> </v-list-item-title>
                            <v-list-item-subtitle>Описание</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card outlined
                           >
                          <v-list-item  v-if="anonymity">
                            <v-list-item-icon>
                              <v-icon>visibility</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{getTextSelector(survey_detail.anonymity,anonymity)}}</v-list-item-title>
                              <v-list-item-subtitle>Анонимность</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="kind">
                            <v-list-item-icon>
                              <v-icon>list</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title   >
                                {{getTextSelector(survey_detail.kind,kind)}}</v-list-item-title>
                              <v-list-item-subtitle>Доступ</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="pb-4">

                          </v-list-item>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card
                            class="mx-auto text-left"
                            outlined
                        >
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>event</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{survey_detail.publication_date}}</v-list-item-title>
                              <v-list-item-subtitle>Начало</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>event_available</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{survey_detail.end_date}}</v-list-item-title>
                              <v-list-item-subtitle>Окончание</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{survey_detail.draft === true ? 'Черновик' : 'Опубликовано'}}
                              </v-list-item-title>
                              <v-list-item-subtitle>Статус</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
                v-for="(stage, sindex) in stages"
                :key="`stage_${sindex}`"
                @click="loadFields(stage)"
            >
              <v-expansion-panel-header>{{stage.title}}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <v-card
                        class="mx-auto text-left"
                        flat
                    >
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>bookmark</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span>{{ stage.title }}</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>description</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <span v-if="stage.description">{{ stage.description }}</span>
                          <span v-else>Не указано</span>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                          v-for="(field, findex) in fields"
                          :key="`stage_${findex}`"
                      >
                        <v-list-item-content>
                          <field-detail-template
                              :field="field"
                          >
                          </field-detail-template>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </template>
  </v-container>
<!--  <router-view v-else></router-view>-->
</template>

<script>
import names from "../routers/names";
import {mapActions} from "vuex"
import TitledPageMixin from "@/mixins/TitledPageMixin";
import FieldDetailTemplate from "@/modules/templates/survey/FieldDetailTemplate";
import {generateUidToName} from "@/helper/uidToName";
import {urlify} from "@/helper";


export default {
  name: "SurveyDetail",
  components: {FieldDetailTemplate},
  mixins: [TitledPageMixin],
  data(){
    return {
      default_panel: 0,
      page_title: "Опрос",
      survey_delete: false,
      select_survey: undefined,
      anonymity: undefined,
      kind: undefined,
      survey_detail: {
        anonymity: null,
        creator: "",
        description: " ",
        draft: " ",
        end_date: " ",
        freeze: " ",
        id: " ",
        is_freeze: " ",
        kind: null,
        name: " ",
        publication_date: " ",
        sort: " ",
        uid: " "
      },
      stages: [],
      fields: []
    }
  },
  computed: {
    names: () => names,
  },
  watch: {
    'survey_detail.creator_name' () {
      generateUidToName(this.survey_detail, 'creator', 'creator_name');
    }
  },
  methods: {
    urlify,
    ...mapActions({
      loadSurveyDetail: 'watcher/loadSurveyDetail',
      loadStageList: 'watcher/loadStageList',
      listStageField: 'watcher/listStageField',
      loadAnonymitySelector: 'selectors/loadAnonymitySelector',
      loadKindSelector: 'selectors/loadKindSelector',
    }),
    getTextSelector(val,selector){
      let str = ''
      if ((val !== null) )
        if (selector[val])
          str = selector[val].text
      return str
    },
    initialize() {
      this.loadKindSelector({
        finalizer: (val) => {
          this.kind = val
        }
      })
      this.loadAnonymitySelector({
        finalizer: (val) => {
          this.anonymity = val
        }
      })
      this.loadSurveyDetail({
            id: this.$route.params.idSurvey,
            finalizer: (val) => {
              if (val) {
                this.page_title = val.name
              }
              this.survey_detail = val
              this.$set(this.survey_detail, 'creator_name', '');
            }
          }
      )
      this.loadStageList({
            survey_id: this.$route.params.idSurvey,
            finalizer: (val) => {
              this.stages = val
            }
          }
      )
    },
    loadFields(stage){
      this.listStageField({
            survey_id: this.$route.params.idSurvey,
            stage_id: stage.id,
            finalizer: (val) => {
              this.fields = val
            }
          }
      )
    },
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>
