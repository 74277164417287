import {
  sendGetRequest
} from "@/helper/requests";
import coherent_urls from "@/urls/coherent";


const state = {
  coherent_values: {},
}

const getters = {
  getCoherentValues: (state) => (coherent) => {
    return state.coherent_values
  },
  isCoherentLoaded: state => (coherent) => {
    return state.hasOwnProperty(coherent)
  }
}

const mutations = {
  setCoherentValues: (state, {coherent, data}) => {
    state.coherent_values[coherent] = data
  },
}

const actions = {
  // Main choice
  loadCoherentValues: ({commit, getters}, {coherent, finalizer, catcher, force}={finalizer: () => {}, catcher: () => {}, force: false}) => {
    if (force || !getters.isCoherentLoaded(coherent)) {
      sendGetRequest(
        coherent_urls.COHERENT(coherent),
        (data) => {
          commit('setCoherentValues', {coherent, data})
          finalizer(data)
        },
        catcher
      )
    }

  },

}


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}


