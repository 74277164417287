import {
    ORGANIZATION_HOST,
    ORGANIZATION_INSTANCE,
    ACCOUNT_HOST,
    ACCOUNTS_INSTANCE,
    SURVEY_HOST,
    SURVEY_INSTANCE,
} from "@/helper/consts";
import {checkUrlAllowedWithParameters, getUrlAllowed, getUrlWithParameters, makeMediaHostUrl} from "@/helper/urls";


export default {
    getAccountsUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            ACCOUNT_HOST, ACCOUNTS_INSTANCE, name, method, parameters, gets
        );
    },
    getSurveyUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            SURVEY_HOST, SURVEY_INSTANCE, name, method, parameters, gets
        );
    },
    getWorkerUrlWithParameters: function (name, method, parameters, gets) {
        return getUrlWithParameters(
            ORGANIZATION_HOST, ORGANIZATION_INSTANCE, name, method, parameters, gets
        );
    },
    allowedPayUrl(name, method) {
        return getUrlAllowed(SURVEY_HOST, name, method)
    },
    allowedPayUrlByParameters(name, method, parameters) {
        return checkUrlAllowedWithParameters(SURVEY_HOST, SURVEY_INSTANCE, name, method, parameters)
    },
    makeMessagingMediaUrl: function (append) {
        return makeMediaHostUrl(SURVEY_HOST, append)
    },
}
