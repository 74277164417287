import {
  sendPostRequest, sendPatchRequest, sendDeleteRequest, sendGetRequest
} from "@/helper/requests";
import surveys from "@/urls/roles/admin";

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  loadUserRoleList: ({commit}, {url, finalizer, catcher}) => {
    if (!url) url = surveys.ADMIN.USER.LIST()
    sendGetRequest(
      url, finalizer, catcher
    )
  },
  createUserRole: ({commit}, {data, finalizer,catcher}) => {
    let url = surveys.ADMIN.USER.SAVE()
    sendPostRequest(url,data,finalizer,catcher)
  },
  changeUserRole: ({commit}, {data, finalizer,catcher}) => {
    let url = surveys.ADMIN.USER.EDIT(data.id)
    sendPatchRequest(url,data,finalizer,catcher)
  },
  deleteUserRole: ({dispatch, commit}, {id, finalizer, catcher}) => {
    let url = surveys.ADMIN.USER.DELETE(id)
    sendDeleteRequest(url, finalizer, catcher)
  },
}

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
