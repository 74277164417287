<template>
  <v-container
      class="page-content px-sm-5"
      style="margin: 0px;
      width: 100%"
      v-if="$route.name === names.FIELDS.DETAIL"
  >
    <template v-if="field_detail">
      {{field_detail}}
    </template>
    <template v-else>
      Опрос не найден
    </template>
  </v-container>
  <router-view v-else></router-view>
</template>

<script>
// TODO: Нужно подумать над этим компонентом. Не используется
import names from "../routers/names";
import {mapGetters, mapState} from "vuex"
import TitledPageMixin from "../../../mixins/TitledPageMixin";
import ApplyDialog from "../../core/components/ApplyDialog";


export default {
  name: "SurveyDetail",
  mixins: [TitledPageMixin],
  data(){
    return {
      default_panel: 0,
      page_title: "Поле",
      survey_delete: false,
      select_survey: undefined,
      anonymity: ['Анонимно','Не анонимно','На выбор пользователя'],
      kind: ['Все','Только пользователи','Только обучающиеся','Только работники'],
    }
  },
  computed: {
    names: () => names,
    ...mapGetters({
      getFieldDetail: 'admin/getFieldDetail',
    }),
    field_detail() {
      return this.getFieldDetail(this.$route.params.idField)
    },
  },
  methods: {
  },
  created() {
    this.$store.dispatch(
        "admin/loadFieldDetail",
        {
          id: this.$route.params.idField,
          finalizer: (val) => {

          }
        })
  }
}
</script>

<style scoped>
.mystyle>.v-expansion-panel>.v-expansion-panel-content>>>.v-expansion-panel-content__wrap{
  padding-bottom: 0!important;
}
</style>