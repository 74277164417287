<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <EditSurveyStageHeaderTemplate
        :title="stageTitle"
        :stage-index="stageIndex"
        :stage-count="stageCount"
        :draft="draft"
        :loading="isLoading"
        @save="$emit('save', stageId)"
        @delete="$emit('delete', stageId)"
        @copy="$emit('copy', stageId)"
        @moveUp="$emit('moveUp', stageId)"
        @moveDown="$emit('moveDown', stageId)"
      ></EditSurveyStageHeaderTemplate>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-bottom: 0px" class="pt-3">
      <v-card flat>
        <v-expand-transition>
          <slot name="form"></slot>
        </v-expand-transition>
        <slot name="fields"></slot>
        <v-divider></v-divider>
        <v-toolbar flat dense>
          <slot name="actions">
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <slot name="actions-buttons">
              </slot>
            </v-toolbar-items>
          </slot>
        </v-toolbar>
      </v-card>
    </v-expansion-panel-content>
    <slot name="dialogs"></slot>
    <edit-survey-error-snack-bar
        :error-text="errorText"
        :error-show="errorShow"
        @close="$emit('update:errorShow', false)"
    ></edit-survey-error-snack-bar>
  </v-expansion-panel>
</template>

<script>

import LoadingMixin from "@/mixins/LoadingMixin";
import EditSurveyStageHeaderTemplate
  from "@/modules/templates/survey/edit/EditSurveyStageHeaderTemplate";
import EditSurveyErrorSnackBar from "@/modules/templates/survey/edit/EditSurveyErrorSnackBar";

export default {
  name: "EditSurveyStageExpandTemplate",
  mixins: [LoadingMixin],
  components: {
    EditSurveyStageHeaderTemplate,
    EditSurveyErrorSnackBar
  },
  props: {
    stageTitle: {
      required: true,
    },
    draft: {
      required: false,
      default: false
    },
    stageIndex: {
      required: true,
    },
    stageCount: {
      required: true,
    },
    stageId: {
      required: true,
    },
    errorText: String,
    errorShow: Boolean,
  }
}
</script>

<style scoped>

</style>
