import helper from "@/urls/helper";

import {
  sendPostRequest,
  sendPatchRequest,
  sendDeleteRequest,
  sendGetRequest,
  finalizeRequest,
  makePostRequestFile
} from "@/helper/requests";
import surveys from "@/urls/roles/admin";
import creator from "@/urls/roles/creator";

const state = {
}

const getters = {

}

const mutations = {

}

const actions = {
  // ADMIN
  loadStageList: ({commit}, {survey_id, finalizer, catcher}) => {
    sendGetRequest(
      surveys.ADMIN.STAGE.LIST(survey_id,{'size': 100}), finalizer, catcher
    )
  },
  createStage: ({commit}, {data, survey_id, finalizer, catcher}) => {
    sendPostRequest(
      surveys.ADMIN.STAGE.SAVE(survey_id), data, finalizer, catcher
    )

  },
  updateStage: ({commit}, {data, survey_id, finalizer, catcher}) => {
    sendPatchRequest(
      surveys.ADMIN.STAGE.EDIT(survey_id, data.id), data, finalizer, catcher
    )

  },
  copyStage: ({commit}, {survey_id, stage_id, finalizer, catcher}) => {
    let url = surveys.ADMIN.STAGE.COPY(survey_id, stage_id,)
    sendGetRequest(url,
        (data) => {
          if (finalizer) finalizer(data)
        },
        (error_data) => {
          if (catcher) catcher(error_data)
        }
    )
  },
  deleteStage: ({dispatch, commit}, {survey_id, stage_id, finalizer, catcher}) => {
    sendDeleteRequest(
      surveys.ADMIN.STAGE.DELETE(survey_id, stage_id), finalizer, catcher
    )

  },
  listStageField: ({commit}, {survey_id, stage_id, finalizer, catcher}) => {
    sendGetRequest(
      surveys.ADMIN.STAGE_FIELDS.LIST(survey_id, stage_id, {'size': 100}), finalizer, catcher
    )
  },
  addStageField: ({commit}, {survey_id, stage_id, data, finalizer, catcher}) => {
    sendPostRequest(
      surveys.ADMIN.STAGE_FIELDS.CREATE(survey_id, stage_id), data, finalizer, catcher
    )
  },
  updateStageField: ({state, commit}, {data, survey_id, stage_id, finalizer, catcher}) => {
    if(data.file !== undefined && data.file !== null && data.file.get_params === undefined) {
      let file = data.file
      let formData = new FormData()
      for (let el in data){
        if (data[el] === null) break
        else formData.append(`${el}`, data[el])
      }
      if (typeof file === 'object' && file !== null)
        if (String(file.name).length < 130 )
          formData.append('file', file)

      finalizeRequest(
        makePostRequestFile(
          surveys.ADMIN.STAGE_FIELDS.EDIT(survey_id, stage_id, data.id),
          formData,
          'PATCH'
        ),
        finalizer,
        catcher
      )
    } else {
      let send = {...data}
      if(data.file !== undefined && data.file !== null){
        delete send.file;
      }
      sendPatchRequest(
        surveys.ADMIN.STAGE_FIELDS.EDIT(survey_id, stage_id, data.id), send, finalizer, catcher
      )
    }
  },
  deleteStageField: ({dispatch, commit}, {survey_id, stage_id, id, finalizer, catcher}) => {
    sendDeleteRequest(
      surveys.ADMIN.STAGE_FIELDS.DELETE(survey_id, stage_id, id), finalizer, catcher
    )
  },
  updateStageFields: ({dispatch, commit}, {fields, survey_id, stage_id}) => {
    fields.forEach((el) => {
      dispatch(
        'updateStageField',
        {
          data: el,
          survey_id: survey_id,
          stage_id: stage_id,
          id: el.id
        }
      )
    })
  }
}


export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters
}
