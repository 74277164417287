<template>
  <v-row>
    <v-col cols="10" class="pt-5">
      <v-card-text class="text-h6 py-0 ">{{ stageIndex + 1 }} этап {{ title }}</v-card-text>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="2">
      <v-row class="pt-2 mr-2" justify="end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" :disabled="draft" v-on="on" icon @click.stop="$emit('save')" :loading="isLoading">
              <v-icon>save</v-icon>
            </v-btn>
          </template>
          <span>Сохранить этап</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" :disabled="draft" v-on="on" icon @click.stop="$emit('copy')" :loading="isLoading">
              <v-icon>content_copy</v-icon>
            </v-btn>
          </template>
          <span>Копировать этап</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" :disabled="draft" v-on="on" icon @click.stop="$emit('delete')" :loading="isLoading">
              <v-icon>close</v-icon>
            </v-btn>
          </template>
          <span>Удалить этап</span>
        </v-tooltip>
        <v-tooltip bottom v-if="stageCount > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="draft" v-bind="attrs" v-on="on" icon @click.stop="$emit('moveUp')" :loading="isLoading">
              <v-icon>arrow_upward</v-icon>
            </v-btn>
          </template>
          <span>Переместить этап вверх</span>
        </v-tooltip>
        <v-tooltip bottom v-if="stageCount > 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" :disabled="draft" v-on="on" icon @click.stop="$emit('moveDown')" :loading="isLoading">
              <v-icon>arrow_downward</v-icon>
            </v-btn>
          </template>
          <span>Переместить этап вниз</span>
        </v-tooltip>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import LoadingMixin from "@/mixins/LoadingMixin";

export default {
  name: "EditSurveyStageHeaderTemplate",
  mixins: [LoadingMixin],
  props: {
    draft: {
      required: true,
      default: false
    },
    title: {
      required: true,
    },
    stageCount: {
      required: true,
    },
    stageIndex: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
