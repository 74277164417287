const CREATOR = {
    BASE: 'Creator',
    MAIN: undefined,
    SURVEYS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
        PREVIEW: undefined,
        CREATE: undefined,
        EDIT: undefined,
        STAGE: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
        },
        FIELD: {
            _BASE: undefined,
            LIST: undefined,
            DETAIL: undefined,
            CREATE: undefined,
            EDIT: undefined,
        },
    },
    CHOICES: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    ATTEMPTS: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
    FILE: {
        _BASE: undefined,
        LIST: undefined,
        DETAIL: undefined,
    },
};

CREATOR.MAIN = `${CREATOR.BASE}|Main`

CREATOR.SURVEYS._BASE = `${CREATOR.BASE}|Survey`
CREATOR.SURVEYS.LIST = `${CREATOR.SURVEYS._BASE}|List`
CREATOR.SURVEYS.DETAIL = `${CREATOR.SURVEYS._BASE}|Detail`
CREATOR.SURVEYS.PREVIEW = `${CREATOR.SURVEYS._BASE}|Preview`
CREATOR.SURVEYS.CREATE = `${CREATOR.SURVEYS._BASE}|Create`
CREATOR.SURVEYS.EDIT = `${CREATOR.SURVEYS._BASE}|Edit`
CREATOR.SURVEYS.STAGE._BASE = `${CREATOR.SURVEYS._BASE}|Stage`
CREATOR.SURVEYS.STAGE.LIST = `${CREATOR.SURVEYS.STAGE._BASE}|List`
CREATOR.SURVEYS.STAGE.DETAIL = `${CREATOR.SURVEYS.STAGE._BASE}|Detail`
CREATOR.SURVEYS.FIELD._BASE = `${CREATOR.SURVEYS._BASE}|Field`
CREATOR.SURVEYS.FIELD.LIST = `${CREATOR.SURVEYS.FIELD._BASE}|List`
CREATOR.SURVEYS.FIELD.DETAIL = `${CREATOR.SURVEYS.FIELD._BASE}|Detail`

CREATOR.CHOICES._BASE = `${CREATOR.BASE}|Choice`
CREATOR.CHOICES.LIST = `${CREATOR.CHOICES._BASE}|List`
CREATOR.CHOICES.DETAIL = `${CREATOR.CHOICES._BASE}|Detail`

CREATOR.ATTEMPTS._BASE = `${CREATOR.BASE}|Attempt`
CREATOR.ATTEMPTS.LIST = `${CREATOR.ATTEMPTS._BASE}|List`
CREATOR.ATTEMPTS.DETAIL = `${CREATOR.ATTEMPTS._BASE}|Detail`

CREATOR.FILE._BASE = `${CREATOR.BASE}|File`
CREATOR.FILE.LIST = `${CREATOR.FILE._BASE}|List`
CREATOR.FILE.DETAIL = `${CREATOR.FILE._BASE}|Detail`

export default {
    ...CREATOR
}
